export const watch = {
  toggle() {
    if (this.toggle == false) {
      if (this.isDebug == true) console.debug('...in toggle()')
      this.toggle = false
      if (this.Modal && this.Modal != undefined) {
        this.Modal.$emit('update:toggle', (this.toggle = false))
      }
      this.reload()
    }
  },
  editToggle() {
    if (this.editToggle == false) {
      if (this.isDebug == true) console.debug('...in editToggle()')
      this.editToggle = false
      if (this.Modal && this.Modal != undefined) {
        this.Modal.$emit('update:toggle', (this.toggle = false))
      }
      this.reload()
    }
  },
  memberToggle() {
    if (this.memberToggle == false) {
      this.memberToggle = false

      if (this.Modal && this.Modal != undefined) {
        this.Modal.$emit('update:toggle', (this.toggle = false))
      }

      this.reloadCommitteeMembers()
    }
  },
  memberUpdateToggle() {
    if (this.memberUpdateToggle == false) {
      if (this.isDebug == true) console.debug('...in memberUpdateToggle()')
      this.memberUpdateToggle = false
      if (this.Modal && this.Modal != undefined) {
        this.Modal.$emit('update:toggle', (this.toggle = false))
      }
      this.reloadCommitteeMembers()
    }
  },
  hoaId() {
    this.reload()
  }
}
