export const watch = {
  hoaAccountManagingUserID(current) {
    const list = this.hoaAccountManagingUserList
    for (let a = 0; a < list.length; a++) {
      const user = list[a]

      if (user.hoaAccountManagingUserID === current) {
        let name = ''

        const userName = user.name
        if (typeof userName === 'string' && userName.length > 0) {
          name += userName
        }

        this.$set(this.formData, 'name', name)
        this.$set(this.formData, 'email', user.email)
      }
    }
  }
}
