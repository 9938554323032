import _get from 'lodash/get'
//
import { List as ListGroups } from '@/services/AccountSettings/Grouping/List'
import { List as ListRoles } from '@/services/AccountSettings/Role/List'
//

export const methods = {
  async init() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const { list: groupsList } = await ListGroups({
      hoaID,
      accountManagingUserID: this.accountManagingUserID
    })

    const checkboxInviteGroup = []
    if (Array.isArray(groupsList)) {
      for (let a = 0; a < groupsList.length; a++) {
        const { isAssigned, groupingID } = groupsList[a]

        if (isAssigned && typeof groupingID === 'number') {
          checkboxInviteGroup.push(groupingID)
        }
      }
    }

    this.checkboxInviteGroup = checkboxInviteGroup
    this.groupList = groupsList

    const { list: rolesList } = await ListRoles({
      hoaID,
      accountManagingUserID: this.accountManagingUserID
    })

    /*
      checks the checkboxes of
      the roles that the user
      already has
    */
    const checkboxInviteRole = []
    if (Array.isArray(rolesList)) {
      for (let a = 0; a < rolesList.length; a++) {
        const { isAssigned, roleID } = rolesList[a]

        if (isAssigned && typeof roleID === 'number') {
          checkboxInviteRole.push(roleID)
        }
      }
    }

    this.checkboxInviteRole = checkboxInviteRole
    this.rolesList = rolesList

    this.loading = false
  },
  getData() {
    return {
      inviteKind: this.inviteKind,
      inviteRoleKind: this.inviteRoleKind,
      checkboxInviteGroup: this.checkboxInviteGroup,
      checkboxInviteRole: this.checkboxInviteRole
    }
  }
}
