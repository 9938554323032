/*
  import { AssignGroupsToUser } from '@/services/AccountSettings/Grouping/AssignGroupsToUser'

  await AssignGroupsToUser ({
    json: {
      accountManagingUserID: 0,
      groupIds: []
    }
  });
*/

import kms from '@/services/kms'
//
import { notifyProblem } from '@/services/notify'
//
export async function AssignGroupsToUser({ json }) {
  try {
    await kms.post('/AccountSettings/Grouping/AssignGroupsToUser', json)

    return {
      successful: true
    }
  } catch (exception) {
    console.error(exception)
  }

  notifyProblem('The user could not be added to groups successfully')

  return {
    successful: false
  }
}
