import _cloneDeep from 'lodash/cloneDeep'

export function data() {
  const modalPresets = {
    show: false,
    loading: false,
    actual: {
      name: null,
      id: null
    },
    selected: {
      name: null,
      id: null
    },
    inputValue: '',
    buttonPressable: false
  }

  return {
    //
    //
    // primary architectural committee data
    //
    archCommittees: [],
    actualArchCommitteeID: null,
    archModalPresets: _cloneDeep(modalPresets),
    selectedArchCommitteeID: null, // the id of the select box
    selectedArchCommitteePlaceholder: '',
    primaryArchCommitteeUpdateModal: _cloneDeep(modalPresets),

    //
    //
    // primary board committee data
    //
    boardCommittees: [],
    actualBoardCommitteeID: null,
    boardModalPresets: _cloneDeep(modalPresets),
    selectedBoardCommitteeID: null,
    selectedBoardCommitteePlaceholder: '',
    primaryBoardCommitteeUpdateModal: _cloneDeep(modalPresets),

    //
    committeeSettingID: null,
    loading: true
  }
}
