<template>
  <div class="section">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
          <fieldset>
            <div class="subtitle">Select Billing Details</div>
            <div v-if="checkingBankAccounts">
              <valid-select
                v-model="formData.bankAccountID"
                label="Bank Account"
                placeholder="Bank Accounts"
                vid="bankAccountID"
                title="Bank Accounts"
              >
                <option
                  v-for="option in checkingBankAccounts"
                  :value="option.value"
                  :key="option.value"
                  >{{ option.label }}</option
                >
              </valid-select>
            </div>
          </fieldset>
          <fieldset>
            <div v-if="glExpenseAccounts">
              <valid-select
                label="G/L Expense Account"
                placeholder="G/L Expense Accounts"
                vid="accountId"
                v-model="formData.accountID"
                title="G/L Expense Account"
              >
                <option
                  v-for="option in glExpenseAccounts"
                  :value="option.value"
                  :key="option.value"
                  >{{ option.label }}</option
                >
              </valid-select>
            </div>
          </fieldset>
          <fieldset>
            <div class="pt-5 float-right">
              <button
                type="button"
                @click.prevent="onFormSubmit"
                class="button is-primary"
                :disabled="refreshing"
              >
                Save
              </button>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
    <b-loading :is-full-page="false" v-model="refreshing" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState } from 'vuex'

import { data } from './keys/data'
import { methods } from './keys/methods'

import ValidSelect from '@/components/inputs/ValidSelect'

export default {
  name: 'BillingModal',

  components: {
    ValidSelect,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    apsetting: Object
  },

  data,

  mounted() {
    this.refresh()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
    // ...mapGetters('bankaccount', ['checkingBankAccounts']),
    // ...mapGetters('subscription', ['glExpenseAccounts'])
  },

  watch: {
    hoaId() {
      this.refresh()
    }
  },

  methods
}
</script>

<style lang="scss">
.modal-h-full {
  left: 50%;
  transform: translate(-50%);
}

#request-image-upload {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}

.doc-viewer {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 130%;
  min-height: 40vh;
  max-height: calc(100vh - 320px);
  border: 1px solid #dbdbdb;

  &.zoom-active {
    cursor: grab;
  }
}

.doc-controls {
  margin-top: -1em;
}
</style>
