<template>
  <PageContentLayoutOnly role="region" :aria-label="$t('committees.title')">
    <!-- <div class="subtitle" v-if="subscription && subscription != undefined && showMembers == false">
      {{ subscription.name }}
    </div> -->

    <PrimaryCommittees />

    <div class="box is-4">
      <div v-if="showMembers == false" :style="{ textAlign: 'right' }">
        <b-button class="is-primary" label="Add Committee" @click.prevent="addCommitteeClicked()" />
      </div>
      <div v-else-if="showMembers == true" :style="{ textAlign: 'right' }">
        <div
          v-if="subscription && subscription != undefined"
          :style="{
            display: 'inline-block',
            float: 'left',
            height: '40px',
            lineHeight: '40px',
            padding: '0 24px',
            fontSize: '24px'
          }"
        >
          {{ selectedCommitteeTitle }}
        </div>
        <b-button class="is-primary" label="Add Member" @click.prevent="addMemberClicked()" />
        <span class="p-2"></span>
        <b-button
          label="Show All Committees"
          @click.prevent="cancelClicked()"
          :style="{ float: 'left' }"
        />
      </div>

      <b-tabs
        position="is-left"
        type="is-toggle"
        class="block whitecolor committee-management-tabs"
        v-if="showMembers === false"
        ref="committeesTabs"
      >
        <b-tab-item label="Active">
          <ModernTable
            active-committees-table
            :loading="activeTable.loading"
            :rows="activeTable.rows"
            :columns="activeTable.columns"
          />
        </b-tab-item>
        <b-tab-item label="Inactive">
          <ModernTable
            inactive-committees-table
            :loading="inactiveTable.loading"
            :rows="inactiveTable.rows"
            :columns="inactiveTable.columns"
          />
        </b-tab-item>
      </b-tabs>

      <b-tabs
        v-else-if="showMembers === true"
        position="is-left"
        type="is-toggle"
        class="block whitecolor committee-management-tabs"
      >
        <b-tab-item aria-selected="true" label="Active">
          <ModernTable
            :loading="activeMembers.loading"
            :rows="activeMembers.rows"
            :columns="activeMembers.columns"
          />
        </b-tab-item>
        <b-tab-item aria-selected="true" label="Inactive">
          <ModernTable
            :loading="inactiveMembers.loading"
            :rows="inactiveMembers.rows"
            :columns="inactiveMembers.columns"
          />
        </b-tab-item>
      </b-tabs>
    </div>

    <div>
      <Modal :toggle.sync="toggle" ariaLabel="add committee modal">
        <template v-slot:header>Add Committee</template>

        <AddCommittee :toggle="toggle" :committeeID="selectedCommitteeID" />
      </Modal>
      <Modal :toggle.sync="editToggle" ariaLabel="edit committee modal">
        <template v-slot:header>Edit Committee</template>

        <EditCommittee :committeeID="selectedCommitteeID" />
      </Modal>

      <Modal :toggle.sync="memberToggle" ariaLabel="add committee member modal">
        <template v-slot:header>Add Committee Member</template>

        <!-- <template v-slot:footer>
          <b-button @click="toggle = false">Close</b-button>
        </template> -->

        <AddCommitteeMember :committeeID="selectedCommitteeID" :committee="selectedCommittee" />
      </Modal>

      <AddCommitteeMemberModal
        ref="AddCommitteeMemberModal"
        :committeeID="selectedCommitteeID"
        :committee="selectedCommittee"
      />

      <Modal :toggle.sync="memberUpdateToggle" ariaLabel="edit committee member modal">
        <EditCommitteeMember
          :selectedCommitteeMember="selectedCommitteeMember"
          :committeeMemberID="selectedCommitteeMemberID"
          :committee="selectedCommittee"
        />

        <template v-slot:header>Edit Committee Member</template>
      </Modal>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
//
import ModernTable from '@/components/tables/Modern/Table.vue'
import Modal from '@/components/Modal'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
//
import AddCommittee from './components/Committee/Add'
import EditCommittee from './components/Committee/Edit'
//
import AddCommitteeMember from './components/CommitteeMember/Add'
import AddCommitteeMemberModal from './components/CommitteeMember/AddModal'
import EditCommitteeMember from './components/CommitteeMember/Edit'
//
import PrimaryCommittees from './components/PrimaryCommittees'

//
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'

export default {
  components: {
    ModernTable,
    Modal,

    PageContentLayoutOnly,

    AddCommittee,
    EditCommittee,

    AddCommitteeMember,
    AddCommitteeMemberModal,
    EditCommitteeMember,

    PrimaryCommittees
  },

  data,
  methods,
  watch,
  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  mounted,
  beforeDestroy() {
    this.unsubscribe()
    this.unsubscribeCommitteeSettings()
  },

  i18n: {
    messages: {
      en: { committees: { title: 'Committees' } }
    }
  }
}
</script>

<style lang="scss">
.committee-management-tabs {
  nav {
    padding: 2rem 2rem 0rem;
  }
}
</style>
