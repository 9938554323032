<template>
  <div>
    <div class="level">
      <div class="level-left level-item title">{{ $t('manageSubscription.title') }}</div>
      <div class="level-right">
        <div class="level-item">
          <svg
            width="30"
            height="30"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
        </div>
        <div class="level-item">
          <b-button label="Cancel" @click.prevent="returnToParent()" />
        </div>
      </div>
    </div>
    <div class="p-5">
      <div>
        <div class="columns">
          <div class="column is-12">
            <vsa-list>
              <!-- Here you can use v-for to loop through items  -->
              <vsa-item key="InvoiceCharges">
                <vsa-heading>1. Invoice Charges</vsa-heading>
                <vsa-content>
                  <div
                    class="font-normal p-4 bg-white rounded"
                    :style="styles.subscriptionPanel"
                    v-for="(item, subscriptionID) in subscriptionList.results"
                    :key="subscriptionID"
                  >
                    <b-radio
                      v-model="radio"
                      name="selectedSubscription"
                      :native-value="item.subscriptionID"
                      @click.prevent="processRadio(item)"
                      class="subscription-selection-button"
                      style="width: 100%; padding-left: 10px"
                    >
                      <span
                        class="column is-size-5 font-bold"
                        style="display: table-cell; vertical-align: middle; width: 70%;"
                      >
                        {{ item.name }} {{ radio }}
                      </span>

                      <span class="column" style="display: table-cell; vertical-align: middle;">
                        <div class="small-text" v-if="item.name == 'Basic'">
                          STARTS AT
                        </div>
                        <div class="small-text" v-else>PRICE</div>
                        <div class="font-bold pr-5">{{ item.monthlyCost | currency }}/mo</div>
                      </span>
                    </b-radio>
                    <div class="content is-size-5 pl-1">
                      {{ item.description }}
                      <span v-if="item.name == 'Premier'">Basic features plus++</span>
                    </div>

                    <div v-for="(subFeature, index) in item.subscriptionFeatures" :key="index">
                      <span v-if="subFeature && subFeature != undefined && index < 5">
                        <li>{{ subFeature.feature.name }}</li>
                      </span>
                      <span v-else-if="index >= 5">
                        <b-button
                          v-if="index == 5"
                          class="p-2 font-bold is-small is-outline is-rounded"
                          @click.prevent="showMoreFeatures(item.subscriptionID)"
                          >. . .</b-button
                        >
                        <div
                          :id="subFeature.subscriptionFeatureID"
                          class="invisible smaller-height small-text"
                        >
                          {{ subFeature.feature.name }}
                        </div>
                      </span>
                    </div>
                  </div>

                  <div class="rounded has-background-info-light block-border p-2">
                    <div class="level-right">
                      <div class="level-item">
                        <b-button label="Continue" @click.prevent="continueStep()" />
                      </div>
                    </div>
                  </div>
                </vsa-content>
              </vsa-item>
              <vsa-item v-show="false" key="Payment" :force-active="forceActivePayment">
                <vsa-heading class="has-background-info-light block-border p-2"
                  >2. Payment Method</vsa-heading
                >
                <vsa-content>
                  <div class="has-background-info-light block-border p-2">
                    <div class="level-right">
                      <div class="level-item">
                        <b-button label="Continue" @click.prevent="continuePaymentStep()" />
                      </div>
                    </div>
                  </div>
                </vsa-content>
              </vsa-item>
              <vsa-item key="Contract" :force-active="forceActiveContract">
                <vsa-heading class="has-background-info-light block-border p-2"
                  >2. Contract Term</vsa-heading
                >
                <vsa-content>
                  <div class="has-background-info-light block-border p-2">
                    <div class="level-right">
                      <div class="level-item">
                        <b-button label="Continue" @click.prevent="continueContractStep()" />
                      </div>
                    </div>
                  </div>
                </vsa-content>
              </vsa-item>
              <vsa-item key="Review" :force-active="forceActiveReview">
                <vsa-heading class="has-background-info-light block-border"
                  >3. Review & Confirm</vsa-heading
                >
                <vsa-content>
                  <Review :isChildManageSubscription.sync="isChildManageSubscription" />
                </vsa-content>
              </vsa-item>
            </vsa-list>
          </div>
          <div class="p-2"></div>
          <div class="text-white column is-one-third p-2">
            <div
              v-if="selectedSubscription && selectedSubscription != undefined"
              class="content is-size-5 p-2 pl-5"
            >
              <div>{{ selectedSubscription.name }} Plan</div>
              <div class="content is-size-4" v-if="billingType == 'Annual'">
                {{ selectedSubscription.yearlyCost | currency }}/year
              </div>
              <div class="content is-size-4" v-else>
                {{ selectedSubscription.monthlyCost | currency }}/mo
              </div>
            </div>
          </div>
          <div :style="styles.choice" class="p-2">
            <div
              v-if="selectedSubscription && selectedSubscription != undefined"
              class="content is-size-5 has-background-info-light block-border p-2 pl-5 rounded"
            >
              <div>{{ selectedSubscription.name }} Plan</div>
              <div class="content is-size-4" v-if="billingType == 'Annual'">
                {{ selectedSubscription.yearlyCost | currency }}/year
              </div>
              <div class="content is-size-4" v-else>
                {{ selectedSubscription.monthlyCost | currency }}/mo
              </div>
              <div class="invisible">
                <b-switch
                  v-show="false"
                  size="is-small"
                  v-model="billingType"
                  true-value="Annual"
                  false-value="Monthly"
                  passive-type="is-dark"
                >
                  {{ billingType }}
                </b-switch>
              </div>
            </div>
            <div>
              <a class="href-overflow-wrap" href="#" @click.prevent="processSupport()"
                >Have a question? Ask Support</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import PageContent from '@/containers/PageContent'
import { mapState, mapGetters } from 'vuex'
import Review from './manage/ManageReview'
import { VsaList, VsaItem, VsaHeading, VsaContent } from 'vue-simple-accordion'
import 'vue-simple-accordion/dist/vue-simple-accordion.css'
import $ from 'jquery'

import _get from 'lodash/get'

export default {
  name: 'SubscriptionManage',

  props: {
    isManageSubscription: Boolean,
    subscription: Object
  },

  components: {
    //PageContent,
    Review,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent
  },

  created() {
    if (this.subscription && this.subscription != undefined) {
      this.radio = this.subscription.subscriptionID
      this.refresh()
    }
  },

  mounted() {
    console.log('sub=' + JSON.stringify(this.subscription))
    setTimeout(() => this.refresh(), 1000)
  },

  computed: {
    ...mapGetters('subscription', ['subscriptionList', 'DTO']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  data: function() {
    var regularShadow = _get(this, '$store.state.theme.shadow.regular', null)
    // var regularBorderRadius = _get (this, '$store.state.theme.shadow.regular', null);

    return {
      loading: false,
      isDebug: false,
      isDebug2: true,
      radio: 1,
      billingType: 'Monthly',
      selectedSubscription: null,
      forceActivePlan: true,
      forceActivePayment: false,
      forceActiveContract: false,
      forceActiveReview: false,
      showMoreFeaturesSubscriptionID: 0,
      subFeatures: [{}],
      isChildManageSubscription: false,
      styles: {
        choice: {
          position: 'fixed',
          marginLeft: '50%',

          backgroundColor: 'White',
          float: 'inline-start',
          width: '15%',
          minWidth: '230px'
        },
        subscriptionPanel: {
          marginBottom: '12px',
          boxShadow: regularShadow
        }
      }
    }
  },

  watch: {
    hoaId() {
      this.refresh()
    },
    radio: 'processRadio',
    isChildManageSubscription() {
      if (this.isChildManageSubscription == true) {
        this.$emit('update:isManageSubscription', (this.isManageSubscription = false))
      }
    }
  },

  methods: {
    refresh() {
      this.$store.dispatch('subscription/getSubscriptionList')

      //currently selected radio
      if (this.subscription && this.subscription != undefined) {
        this.radio = this.subscription.subscriptionID
        this.selectedSubscription = this.subscription
        this.billingType = 'Monthly'

        console.log('this.radio=' + this.radio)

        this.loadSubscriptionFeatures()
      }
    },
    returnToParent() {
      this.$emit('update:isManageSubscription', (this.isManageSubscription = false))
    },
    processRadio(subscriptionID) {
      if (this.isDebug == true) console.debug('...in processRadio')
      if (
        subscriptionID &&
        subscriptionID != undefined &&
        this.subscriptionList &&
        this.subscriptionList != undefined &&
        this.subscriptionList.results &&
        this.subscriptionList.results != undefined
      ) {
        if (this.isDebug == true) console.debug('item=' + JSON.stringify(subscriptionID))

        const selected = this.subscriptionList.results.filter(
          f => f.subscriptionID == subscriptionID
        )

        //set the radio selection for monthly / annual
        this.selectedSubscription = selected[0]

        if (this.isDebug == true)
          console.debug('this.selectedSubscription...' + JSON.stringify(this.selectedSubscription))
      }
    },
    showMoreFeatures(subscriptionID) {
      this.showMoreFeaturesSubscriptionID = subscriptionID

      console.debug('...in showMoreFeatures=' + this.showMoreFeaturesSubscriptionID)

      if (this.subFeatures && this.subFeatures != undefined) {
        let filtered = this.subFeatures.filter(f => f.subscriptionID == subscriptionID)

        if (this.isDebug == true) console.debug('filtered=' + JSON.stringify(this.subFeatures))

        if (filtered && filtered != undefined) {
          Array.from(filtered).forEach(element => {
            if (element && element != undefined) {
              const myId = '#' + element.subscriptionFeatureID
              if ($(myId).hasClass('invisible')) {
                $(myId)
                  .removeClass('invisible smaller-height small-text')
                  .addClass('visible')
              } else {
                $(myId)
                  .removeClass('visible')
                  .addClass('invisible smaller-height small-text')
              }
            }
          })
        }
      }
    },
    loadSubscriptionFeatures() {
      if (this.isDebug == true) console.debug('...in loadSubscriptionFeatures')

      this.subFeatures = []
      Array.from(this.subscriptionList.results).forEach(element => {
        if (element && element != undefined) {
          if (element.subscriptionFeatures && element.subscriptionFeatures != null) {
            Array.from(element.subscriptionFeatures).forEach(sub => {
              if (sub && sub != undefined) {
                this.subFeatures.push(sub)
              }
            })
          }
        }
      })
    },
    continueStep() {
      if (this.isDebug == true) console.debug('...in continueStep')

      if (this.selectedSubscription && this.selectedSubscription != undefined) {
        this.$store.dispatch('subscription/setDTO', {
          subscriptionID: this.selectedSubscription.subscriptionID,
          subscriptionGroupID: this.selectedSubscription.subscriptionGroupID,
          isHidden: this.selectedSubscription.isHidden,
          isArchived: this.selectedSubscription.isArchived,
          type: this.selectedSubscription.type,
          name: this.selectedSubscription.name,
          monthlyCost: this.selectedSubscription.monthlyCost,
          yearlyCost: this.selectedSubscription.yearlyCost,
          description: this.selectedSubscription.description,
          isMonthlySubscription: this.billingType == 'Annual' ? false : true,
          subscriptionFeatures:
            this.selectedSubscription.subscriptionFeatures &&
            this.selectedSubscription.subscriptionFeatures != undefined
              ? this.selectedSubscription.subscriptionFeatures
              : null
        })
      }

      //this.forceActivePlan = false
      //this.forceActivePayment = true
      //this.forceActiveContract = false
      //this.forceActiveReview = false

      this.continuePaymentStep()
    },
    continuePaymentStep() {
      this.forceActivePlan = false
      this.forceActivePayment = false
      this.forceActiveContract = true
      this.forceActiveReview = false
    },
    continueContractStep() {
      this.forceActivePlan = false
      this.forceActivePayment = false
      this.forceActiveContract = false
      this.forceActiveReview = true
    },
    processSupport() {
      $('.supportTab a span').click()
    }
  },

  i18n: {
    messages: {
      en: { manageSubscription: { title: 'Change Subscription' } }
    }
  }
}
</script>

<style lang="scss">
.subscription-selection-button > span.control-label {
  display: table;
  width: 100%;
  vertical-align: middle;
}
.ghost {
  opacity: 0;
}
</style>
