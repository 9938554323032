/*
  example:
    import { notifyProblem } from '@/services/notify'

    import { listBankAccounts } from '@/services/BankAccounts/BankAccount/List';

    const { bankAccounts, successful, mesage } = await listBankAccounts ({
      params: {
        hoaID: '',
        // hasCheckingOnly: true
      }
    })
*/

import kms from '@/services/kms'
import _get from 'lodash/get'

import { filterAccounts } from './_utilities/filterAccounts'

export async function listBankAccounts({ filterUnlinked = true, params = {} } = {}) {
  let message = `A problem occurred and the bank accounts list could not be retrieved.`

  try {
    const result = await kms.get(`/BankAccounts/BankAccount/List`, {
      params
    })

    const accounts = _get(result, 'results', [])
    if (!Array.isArray(accounts)) {
      console.info(result)
      throw new Error(`Bank accounts wasn't returned as an array.`)
    }

    let bankAccounts = []
    if (filterUnlinked) {
      bankAccounts = filterAccounts({ accounts })
    } else {
      bankAccounts = accounts
    }

    return {
      successful: true,
      message: '',
      result,
      //
      bankAccounts
    }
  } catch (exception) {
    console.error(exception)
  }

  // notifyProblem (message);

  return {
    successful: false,
    message,
    result: null,
    //
    bankAccounts: []
  }
}
