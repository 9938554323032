/*
  import { parkingItem } from '@/services/AccountSettings/ParkingSpaces/ParkingModal/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  // Update Parking
  async updateParking({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/ParkingSpaces/ParkingSpace`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.parkingSpaceID > 0) {
        notifyMessage(`Successfully updated the parking space.`)
        done()
      } else {
        notifyError('There was a problem updating this parking space.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this parking space.`)
      console.error(exception)
    }
  },

  // Add Parking Space
  async addParking({ dispatch }, { hoaID, spaceNumber, isCovered, note, unitID, done }) {
    if (isDebug == true) console.debug('...in addParking')
    try {
      const results = await kms.post(`/ParkingSpaces/ParkingSpace`, {
        hoaID,
        spaceNumber,
        isCovered,
        note,
        unitID
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.parkingSpaceID > 0) {
        notifyMessage(`Successfully added the parking space.`)
        done()
      } else {
        notifyError('There was a problem adding this parking space.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this parking space.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const parkingItem = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
