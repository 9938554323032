import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import _isPlainObject from 'lodash/isPlainObject'
//
import { Post as addCommitteeMember } from '@/services/Committees/CommitteeMember/Post'
import { List as ListCommitteeTitles } from '@/services/Committees/CommitteeTitle/List'
// import { List as listCommitteeMembers } from '@/services/Committees/CommitteeMember/List'
//
import { notifyProblem } from '@/services/notify'
//
export const methods = {
  parseCommitteeTitles({ list }) {
    this.committeeTitleList = list.map(entry => {
      const title = _get(entry, 'title', '')

      return {
        committeeTitleID: _get(entry, 'committeeTitleID', null),
        ...(typeof title === 'string' && title.length ? { title } : {})
      }
    })
  },

  async open() {
    this.isOpen = true

    const committeeTypeID = _get(this, ['committee', 'committeeTypeID'], null)

    if (typeof committeeTypeID === 'number' && committeeTypeID >= 1) {
      const { list } = await ListCommitteeTitles({
        params: {
          committeeTypeID
        }
      })

      this.parseCommitteeTitles({
        list
      })
    }

    const { list } = await this.$store.dispatch('hoa/loadHoaAccountManagingUserList')

    this.hoaAccountManagingUserList = list.map(entry => {
      const name = _get(entry, ['accountManagingUser', 'identityInformation.name'], '')
      const email = _get(entry, ['accountManagingUser', 'aspNetUserEmail'], null)

      return {
        hoaAccountManagingUserID: _get(entry, 'hoaAccountManagingUserID', null),

        name,
        label: [name, typeof email === 'string' && email.length >= 1 ? '(' + email + ')' : ''].join(
          ' '
        ),

        email
      }
    })
  },
  async add() {
    let inactive = false
    if (this.formData.active === false) {
      inactive = true
    }

    const isValid = await this.$refs.observer.validate()
    let problematic = false

    const termStartDate = this.formData.termStartDate
    if (typeof termStartDate !== 'string' || termStartDate.length === 0) {
      problematic = true
      this.$set(this.formErrors, 'termStartDate', 'The term start date is not valid.')
    }

    const termEndDate = this.formData.termEndDate
    if (typeof termEndDate !== 'string' || termEndDate.length === 0) {
      problematic = true
      this.$set(this.formErrors, 'termEndDate', `The term end date is not valid.`)
    }

    if (!isValid || problematic) {
      notifyProblem('One or more fields are invalid.')
      return
    }

    const { results } = await addCommitteeMember({
      json: {
        hoaID: this.hoaId,
        committeeID: this.committeeID,
        //
        hoaAccountManagingUserID: this.hoaAccountManagingUserID,
        committeeTitleID: this.formData.committeeTitleID,
        //
        termStartDate: this.formData.termStartDate,
        termEndDate: this.formData.termEndDate,
        //
        inactive,
        //
        emailAddress: this.formData.email,
        name: this.formData.name
      }
    })

    if (_isPlainObject(results)) {
      this.formData = _cloneDeep(this.PRESET_FORM_VALUES)
      this.hoaAccountManagingUserID = null

      this.isOpen = false

      this.$store.dispatch('committees/getCommitteeMemberList', {
        committeeID: this.committeeID
      })

      // so as to refresh the member counts in the list
      this.$store.dispatch('committees/getCommitteeList')
    }
  }
}
