<template>
  <PageContentLayoutOnly role="region" :aria-label="$t('accessManagement.title')">
    <div class="box is-8" ref="tabsFoundation">
      <div
        :style="{
          borderBottom: '1px solid ' + palette[2],
          marginBottom: '20px'
        }"
      >
        <router-link
          users-tab
          :to="{
            name: 'accountSettings.accessManagement.users'
          }"
          :style="
            Object.assign({}, styles.tab, {
              ...(showUsers === true
                ? {
                    background: palette[1],
                    borderRight: '1px solid ' + palette[2],
                    borderLeft: '1px solid ' + palette[2],
                    borderTop: '1px solid ' + palette[2]
                  }
                : {})
            })
          "
          >Users</router-link
        >

        <router-link
          groups-tab
          :to="{
            name: 'accountSettings.accessManagement.groups'
          }"
          :style="
            Object.assign({}, styles.tab, {
              ...(showGroups === true
                ? {
                    background: palette[1],
                    borderRight: '1px solid ' + palette[2],
                    borderLeft: '1px solid ' + palette[2],
                    borderTop: '1px solid ' + palette[2]
                  }
                : {})
            })
          "
          >Groups
        </router-link>
      </div>

      <transition name="fade" mode="out-in" appear>
        <div v-if="showUsers">
          <div class="nowrap levels">
            <div class="level-right">
              <b-button
                invite-team-member-button
                v-if="isOwner === true"
                class="is-primary"
                label="Invite Team Member"
                @click.prevent="showMemberInvitationModal"
              />

              <b-tooltip
                v-if="isOwner !== true"
                label="Account Owner permission is necessary to send invites"
                position="is-left"
              >
                <b-button disabled type="is-primary" label="Invite Team Member" />
              </b-tooltip>
            </div>
          </div>

          <div class="column is-12 cursor-pointer">
            <UsersTable :showEditModal="showEditModal" :isOwner="isOwner" />
          </div>
        </div>
      </transition>

      <transition name="fade" mode="out-in" appear>
        <div v-if="showGroups">
          <div class="nowrap levels">
            <div class="level-right">
              <b-button
                v-if="isOwner === true"
                type="is-primary"
                label="Add Group"
                @click.prevent="openAddGroupModal"
                add-group-button
              />

              <b-tooltip
                v-if="isOwner !== true"
                label="Account Owner permission is necessary to add groups"
                position="is-left"
              >
                <b-button disabled type="is-primary" label="Add Group" />
              </b-tooltip>
            </div>
          </div>

          <div class="column is-12 cursor-pointer">
            <GroupsTable :showGroupEditModal="showGroupEditModal" />
          </div>
        </div>
      </transition>
    </div>

    <MemberInvitationModal ref="memberInvitationModal" :inviteWasSent="memberInviteWasSent" />

    <MemberEditorModal
      ref="MemberEditorModal"
      :formData="memberEditorFormData"
      :isOwner="isOwner"
    />

    <GroupAdderModal ref="groupAdderModal" />

    <GroupEditorModal ref="groupEditorModal" :isOwner="isOwner" />
  </PageContentLayoutOnly>
</template>

<script>
//
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
//
// components
import UsersTable from './components/UsersTable'
import GroupsTable from './components/GroupsTable'

import MemberInvitationModal from './components/MemberInvitationModal'
import MemberEditorModal from './components/MemberEditorModal'
import GroupAdderModal from './components/GroupAdderModal'
import GroupEditorModal from './components/GroupEditorModal'
//
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'
//
//
//
export default {
  components: {
    MemberInvitationModal,
    MemberEditorModal,

    GroupAdderModal,
    GroupEditorModal,

    GroupsTable,
    PageContentLayoutOnly,
    UsersTable
  },

  computed,
  data,
  watch,
  methods,
  mounted,

  i18n: {
    messages: {
      en: {
        accessManagement: {
          title: 'Access Management'
        }
      }
    }
  }
}
</script>
