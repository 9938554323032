import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export const methods = {
  updateBillingModal() {
    this.toggle = true
  },
  async reload() {
    console.debug('in reloadReceipts...')
    this.loading = true

    try {
      const params = {
        hoaID: this.hoaId
      }

      const path = `/AccountsPayable/APSetting/GetByHOAID?hoaID=${params.hoaID}`
      if (this.isDebug == true) console.debug('getApSetting path=' + path)

      const result = await kms.get(path)

      if (this.isDebug == true) console.debug('apSetting=' + JSON.stringify(result))

      this.apSetting = result
    } catch (e) {
      notifyError(e)
    }

    this.loading = false
  },

  async downloadPdf(apInvoiceID) {
    if (this.isDebug == true) console.debug('downloadPdf...')

    if (!apInvoiceID || apInvoiceID == undefined) {
      notifyError('The apInvoiceID is invalid for the download.')
    }

    try {
      const params = {
        apInvoiceID: apInvoiceID,
        asBase64: true
      }

      const path = `/AccountsPayable/APInvoice/GetInvoiceFile`
      const returned = await kms.get(path, {
        params
      })

      if (this.isDebug == true)
        console.debug(
          'path base64 path=' +
            path +
            ' - params' +
            JSON.stringify(params) +
            ' - returned=' +
            JSON.stringify(returned)
        )
      if (returned && returned != undefined) {
        if (this.isDebug == true) console.debug('base64pdf........................' + returned)

        const contentType = 'application/pdf'
        const blob = this.base64toBlob(returned, contentType)
        const blobUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = blobUrl
        link.setAttribute('download', apInvoiceID + '.pdf')
        document.body.appendChild(link)
        link.click()
      }

      this.base64pdf = returned
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
    }
  },
  base64toBlob(b64Data, contentType) {
    const sliceSize = 512
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }
}
