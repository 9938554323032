import { reserveStore } from '@/services/ReserveFundingTarget/store'
import { notifyError } from '@/services/notify'
import moment from 'moment'
import $ from 'jquery'

export const methods = {
  async reload() {
    console.debug('in reload()')

    const today = moment()
    var year = moment(today).format('YYYY')
    this.formData.targetYear = parseInt(year)

    if (this.reserveFundingTarget !== undefined && this.reserveFundingTarget) {
      this.reserveFundingID = this.reserveFundingTarget.reserveFundingTargetID
      this.formData.targetYear = this.reserveFundingTarget.fiscalYear
      this.formData.reserveTarget = this.reserveFundingTarget.targetAmount
      this.buttonText = 'Update'
    }
  },

  closeModal() {
    this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async onFormSubmit() {
    if (this.formData !== undefined && this.formData) {
      this.loading = true

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      try {
        if (
          this.reserveFundingTarget !== null &&
          this.reserveFundingTarget !== undefined &&
          this.reserveFundingTarget.reserveFundingTargetID &&
          this.reserveFundingTarget.reserveFundingTargetID > 0
        ) {
          const payload = {
            reserveFundingTargetID: this.reserveFundingTarget.reserveFundingTargetID,
            hoaID: this.currentHoaId,
            fiscalYear: this.formData.targetYear,
            targetAmount: this.formData.reserveTarget
          }

          await reserveStore.dispatch('updateReserveFunding', {
            payload,
            done: async () => {
              this.closeModal()
            }
          })
        } else {
          if (this.existingYears !== undefined && this.existingYears.length > 0) {
            const existingYear = this.existingYears.filter(f => f.year === this.formData.targetYear)

            console.debug('existingYear=' + JSON.stringify(existingYear))

            if (existingYear[0] !== undefined && existingYear[0]) {
              this.loading = false
              loadingComponent.close()
              notifyError(
                'There is already a reserve funding target entry for this year. Please update this year.'
              )
              return
            }
          }

          await reserveStore.dispatch('addReserveFundingTarget', {
            hoaID: this.currentHoaId,
            fiscalYear: this.formData.targetYear,
            targetAmount: this.formData.reserveTarget,
            done: async () => {
              this.closeModal()
            }
          })
        }
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
      loadingComponent.close()
    }
  }
}
