import moment from 'moment'

import _cloneDeep from 'lodash/cloneDeep'

const committeeColumns = [
  {
    field: 'name',
    label: 'Name',
    width: '20%',
    sortable: true,
    searchable: true
  },
  {
    field: 'committeeType',
    label: 'Type',
    width: '15%',
    sortable: true,
    searchable: true
  },
  {
    field: 'membersCount',
    label: 'Members',
    width: '20%',
    sortable: true,
    searchable: true
  },
  {
    field: 'edit',
    label: '',
    width: '5%'
  },
  {
    field: 'members',
    label: '',
    width: '5%'
  },
  {
    field: 'erase',
    label: '',
    width: '5%'
  }
]

const memberColumns = [
  {
    field: 'name',
    label: 'Name',
    width: '20%',
    sortable: true,
    searchable: true
  },
  {
    field: 'title',
    label: 'Title',
    width: '15%',
    sortable: true,
    searchable: true
  },
  {
    field: 'termDates',
    label: 'Term Dates',
    width: '20%',
    sortable: true,
    searchable: true
  },
  {
    field: 'edit',
    label: '',
    width: '5%'
  },
  {
    field: 'erase',
    label: '',
    width: '5%'
  }
]

export const data = function() {
  return {
    loading: true,
    isDebug: true,
    toggle: false,
    editToggle: false,
    memberToggle: false,
    memberUpdateToggle: false,
    showMembers: false,
    selectedCommitteeTitle: '',
    selectedCommittee: null,
    selectedCommitteeID: 0,
    selectedCommitteeMemberID: 0,
    selectedCommitteeMember: null,
    subscription: '',
    activeCommittees: [{}],
    inactiveCommittees: [{}],
    activeMemberCommittees: [{}],
    inactiveMemberCommittees: [{}],
    formatDateFn: function(value) {
      return value && value != undefined
        ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
            .format('MM-DD-YYYY')
            .toString()
        : ''
    },
    processStatus: function(value) {
      return value != null && value == true ? 'Active' : 'Inactive'
    },

    activeTable: {
      loading: true,
      columns: _cloneDeep(committeeColumns),
      rows: []
    },
    inactiveTable: {
      loading: true,
      columns: _cloneDeep(committeeColumns),
      rows: []
    },

    activeMembers: {
      loading: true,
      columns: _cloneDeep(memberColumns),
      rows: []
    },
    inactiveMembers: {
      loading: true,
      columns: _cloneDeep(memberColumns),
      rows: []
    },

    primaryArchitecturalCommitteeID: null,
    showingPrimaryArchDeletionPrevetion: false,

    primaryBoardCommitteeID: null,

    showAddCommitteeMemberModal: false
  }
}
