<template>
  <div>
    <div class="rounded-tile p-5">
      <div class="subtitle" v-show="false">
        <span>Billing Bank Account</span
        ><span class="float-right"
          ><b-button size="is-small" label="Update Billing" @click.prevent="updateBillingModal()"
        /></span>
      </div>
      <div
        v-if="
          apSetting &&
            apSetting != undefined &&
            apSetting.bankAccount &&
            apSetting.bankAccount != undefined
        "
      >
        {{ apSetting.bankAccount.name }}
      </div>
      <div v-else>None Selected</div>
      <div class="p-2"></div>
      <div class="pt-2 subtitle">Billing G/L Expense Account</div>
      <div
        v-if="
          apSetting &&
            apSetting != undefined &&
            apSetting.glExpenseAccount &&
            apSetting.glExpenseAccount != undefined
        "
      >
        {{
          `${apSetting.glExpenseAccount.fundType} ${apSetting.glExpenseAccount.accountType}:  ${apSetting.glExpenseAccount.description}`
        }}
      </div>
      <div v-else>None Selected</div>
    </div>
    <div class="p-2">&nbsp;</div>
    <div class="rounded-tile p-5">
      <div
        v-if="
          apSetting &&
            apSetting != undefined &&
            apSetting.subscriptionBillingAddress &&
            apSetting.subscriptionBillingAddress != undefined
        "
      >
        <div class="subtitle">Billing Details</div>
        <div>{{ apSetting.subscriptionBillingAddress.addressOne || '' }}</div>
        <div>
          {{
            `${apSetting.subscriptionBillingAddress.city || ''}, ${apSetting
              .subscriptionBillingAddress.state || ''}  ${apSetting.subscriptionBillingAddress
              .postalCode || ''}`
          }}
        </div>
      </div>
    </div>
    <Modal :toggle.sync="toggle" :apsetting.sync="apSetting">
      <BillingModal :toggle="toggle" :apsetting="apSetting"> </BillingModal>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import BillingModal from '@/components/Modals/Billing'
//
import moment from 'moment'
//
import { mapState } from 'vuex'

import { methods } from './keys/methods'

export default {
  name: 'SubscriptionBillingDetails',
  components: {
    Modal,
    BillingModal
  },

  props: {
    shouldRefresh: Boolean
  },

  data: () => ({
    loading: false,
    isDebug: true,
    toggle: false,
    selectedRow: null,
    formatDateFn: function(value) {
      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
    },
    apSetting: null
  }),

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    hoaId() {
      this.reload()
    },

    toggle() {
      if (this.toggle == false) {
        if (this.isDebug == true) console.debug('in toggle...')

        setTimeout(() => this.reload(), 1000)

        this.$emit('update:shouldRefresh', (this.shouldRefresh = true))

        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  methods
}
</script>

<style></style>
