import _get from 'lodash/get'
import $ from 'jquery'
import { notifyProblem } from '@/services/notify'

import { committeeSettings } from '@/services/Committees/CommitteeSettings/store'

export const methods = {
  async refresh() {
    this.loading = true

    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    const { list: committeeSettingsList } = await committeeSettings.dispatch('getList', {
      params: {
        hoaID
      }
    })

    const primaryArchID = _get(committeeSettingsList, [0, 'architecturalCommitteeID'], null)
    const boardArchID = _get(committeeSettingsList, [0, 'boardCommitteeID'], null)

    const { committeeDetails } = await this.$store.dispatch('committees/loadCommittee', {
      committeeID: this.committeeID
    })
    await this.$store.dispatch('committees/getCommitteeTypeList')

    /*
      if the committee is a primary committee (arch or board),
      prevent:
        changing the committee type
        deactivating the committee  
    */
    const committeeID = _get(committeeDetails, 'committeeID', null)
    if (committeeID === primaryArchID) {
      this.isPrimaryArch = true
    }
    if (committeeID === boardArchID) {
      this.isPrimaryBoard = true
    }

    this.formData.name = _get(committeeDetails, 'name', null)
    this.formData.isActive = _get(committeeDetails, 'isActive', null)
    this.formData.committeeTypeID = _get(committeeDetails, 'committeeTypeID', null)

    this.loading = false
  },

  async onFormSubmit() {
    if (this.isDebug == true) console.debug(this.formData)

    this.loading = true

    if (this.formData && this.formData != undefined) {
      this.$store.dispatch('committees/updateCommittee', {
        committeeID: this.committeeID,
        committeeTypeID: this.formData.committeeTypeID,
        hoaID: this.hoaId,
        name: this.formData.name,
        isActive: this.formData.isActive,
        done: () => {
          // auto-close modal
          this.$emit('update:editToggle', (this.editToggle = false))
          $('#clickclose').click()
          this.loading = false
        }
      })
    } else {
      notifyProblem('Please check your selections prior to saving.')
      this.loading = false
    }
  }
}
