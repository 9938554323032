export const methods = {
  tabClicked(tabName) {
    if (this.isDebug == true) console.debug('tabClicked=' + tabName)
    this.activeTab = tabName
  },
  refresh() {
    this.getRefresh()
  },
  async getRefresh() {
    this.refreshing = true

    this.$store.dispatch('subscription/getSubscriptionList')
    this.$store.dispatch('subscription/getCurrentMonthlyBillAmount')
    this.$store.dispatch('subscription/getHoaSubscription')
    this.$store.dispatch('subscription/getApSetting')
    this.$store.dispatch('subscription/getSubscriptionHoaFeatures', {
      hoaID: this.hoaId
    })

    this.$store.dispatch('subscription/getSubscriptionAddOnHoaFeatures', {
      hoaID: this.hoaId
    })

    this.refreshing = false
  }
}
