/*
  import { List as ListInvitationUsers } from '@/services/AccountSettings/InvitationUser/List'

  const { list: invitationUsersList } = await ListInvitationUsers ({
    hoaID
  });
*/

import kms from '@/services/kms'
import _get from 'lodash/get'
//
import { notifyProblem } from '@/services/notify'
//
export async function List({ hoaID }) {
  try {
    let result = await kms.get('/AccountSettings/InvitationUser/List', {
      params: {
        hoaID
      }
    })

    return {
      list: _get(result, 'results', []),
      successful: true
    }
  } catch (exception) {
    console.error(exception)
    notifyProblem('A problem occurred and the list of invited users could not retrieved.')
  }

  return {
    list: [],
    successful: false
  }
}
