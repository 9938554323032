<template>
  <div class="section" id="committee-adder">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <valid-input
                  name="Name"
                  label="Name"
                  maxlength="450"
                  type="text"
                  vid="name"
                  placeholder="Name"
                  spellcheck="true"
                  aria-label="Name"
                  rules="required|max:450"
                  v-model="formData.name"
                />
              </div>
              <div class="column is-12" v-if="committeeTypeList && committeeTypeList != undefined">
                <valid-select
                  label="Type"
                  placeholder="Type"
                  title="Type"
                  vid="committeeTypeID"
                  v-model="formData.committeeTypeID"
                  rules="required"
                >
                  <option
                    v-for="option in committeeTypeList"
                    :value="option.value"
                    :key="option.value"
                    >{{ option.label }}</option
                  >
                </valid-select>
              </div>

              <div class="column is-12" :style="styles.activePanel">
                <b-field>
                  <b-checkbox
                    class="has-left-text"
                    v-model="formData.isActive"
                    aria-label="is the committee active?"
                    >Active</b-checkbox
                  >
                </b-field>
              </div>

              <div class="column is-12 float-right">
                <button type="submit" class="button is-primary is-rounded" :disabled="loading">
                  Save
                </button>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ToastProgrammatic as Toast } from 'buefy'
import { mapState, mapGetters } from 'vuex'
// import 'vue-editable-grid/dist/VueEditableGrid.css'
import ValidSelect from '@/components/inputs/ValidSelect'
import $ from 'jquery'

// components
import ValidInput from '@/components/inputs/ValidInput'

import moment from 'moment'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

let DEFAULT_FORM_VALUES = {
  name: '',
  isActive: true,
  committeeTypeID: 0
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'CommitteeModal',

  props: {
    committeeID: Number
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null,

      styles: {
        activePanel: {
          display: 'flex',
          margin: '10px 0 20px'
        },
        activeText: {
          marginRight: '10px'
        }
      }
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('committees', ['committeeTypeList'])
    })
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    ValidSelect
  },

  mounted() {
    this.$store.dispatch('committees/getCommitteeTypeList')
  },

  methods: {
    async onFormSubmit() {
      if (this.isDebug == true) console.debug(this.formData)

      if (this.formData && this.formData != undefined) {
        try {
          await this.$store.dispatch('committees/addCommittee', {
            hoaID: this.hoaId,
            committeeTypeID: this.formData.committeeTypeID,
            name: this.formData.name,
            isActive: this.formData.isActive
          })
        } catch (exception) {
          console.error(exception)
        }

        $('#clickclose').click()
        this.loading = false
      } else {
        notifyError('Please check your selections prior to saving.')
      }
    }
  }
}
</script>

<style lang="scss">
#committee-adder {
  .step-details {
    background-color: #fff !important;
    padding-left: 0.25rem !important;
    padding-right: 0.5rem !important;
  }

  .b-steps.is-small .steps {
    margin-top: 1rem;
  }

  .b-steps .steps + .step-content {
    padding-top: 0 !important;
  }

  .step-navigation {
    display: flex;
    justify-content: flex-end;
  }

  .my-grid-class {
    height: 400px;
  }

  tbody {
    min-height: 100px !important;
    overflow: auto !important;
  }
}
</style>
