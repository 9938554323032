import moment from 'moment'

export const DEFAULT_FORM_VALUES = Object.freeze({
  bankAccountID: 0,
  accountID: 0
})

export function data() {
  return {
    refreshing: true,
    isDebug: true,
    //
    checkingBankAccounts: [],
    glExpenseAccounts: [],
    //
    formComplete: false,
    formData: {
      ...DEFAULT_FORM_VALUES
    },
    formatDateFn: function(value) {
      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
    }
  }
}
