<template>
  <Modal :toggle.sync="isOpen">
    <template v-slot:header>Group Editor</template>

    <template v-slot:footer>
      <div v-if="mode === 'summary' && isOwner === true">
        <b-button
          label="Edit"
          icon-left="pencil"
          @click.prevent="openEditMode(formData.name, formData.groupingID)"
          :style="{ float: 'right' }"
        />

        <b-button
          label="Delete"
          type="is-danger"
          @click.prevent="eraseGroup(formData.name, formData.groupingID)"
          :style="{ float: 'left' }"
        />
      </div>

      <div v-if="mode === 'summary' && isOwner !== true">
        <b-tooltip
          label="Account Owner permission is necessary to edit groups"
          position="is-left"
          :style="{ float: 'right' }"
        >
          <b-button disabled label="Edit" icon-left="pencil" />
        </b-tooltip>

        <b-tooltip
          label="Account Owner permission is necessary to delete groups"
          position="is-right"
          :style="{ float: 'left' }"
        >
          <b-button disabled label="Delete" type="is-danger" />
        </b-tooltip>
      </div>

      <div v-if="mode === 'edit'">
        <b-button label="Back To Summary" @click.prevent="back()" :style="{ float: 'left' }" />

        <b-button
          label="Update"
          type="is-primary"
          @click.prevent="update()"
          :style="{ float: 'right' }"
        />
      </div>
    </template>

    <div class="tile is-parent is-vertical" v-if="mode === 'summary'">
      <article>
        <div class="content is-size-3 text-center">
          {{ `${formData.name}` }}
        </div>
        <div class="text-center" :style="{ marginBottom: '20px' }">{{ formData.description }}</div>
        <div class="text-center underline font-bold">Permissions</div>
        <div class="columns text-center is-12">
          <div class="column is-one-half font-bold">Admin Access</div>
          <div class="column is-one-half font-bold">Read-only Access</div>
        </div>
        <div class="columns text-center is-12">
          <div class="column is-one-half">{{ formData.adminPermissions }}</div>
          <div class="column is-one-half">{{ formData.readPermissions }}</div>
        </div>
      </article>
    </div>

    <div v-if="mode === 'edit'">
      <ValidationObserver ref="editor">
        <transition name="fade" mode="out-in" appear>
          <form class="form">
            <fieldset>
              <b-field>
                <article class="has-background-info-light p-5 block-border">
                  <div class="font-bold">Group Member</div>
                  <div>
                    By default, Group Members can view the workspace without access to any
                    sub-resources. You can customize additional permissions below. Group Members
                    will also adopt any permissions of a Group that they belong to.
                  </div>
                </article>
              </b-field>
              <b-field>
                <article class="has-background-info-light p-5 block-border">
                  <div class="font-bold">Customize Group Member Permissions</div>
                </article>
              </b-field>

              <div v-for="(possibility, index) in possibleRoles" :key="index">
                <b-field>
                  <article
                    class="has-background-info-light p-5 block-border"
                    v-if="possibility.name && possibility.name != undefined"
                  >
                    <b-checkbox v-model="groupPermissions" :native-value="possibility.roleID">
                      <div class="font-bold">{{ possibility.name }}</div>
                      <div>
                        {{ possibility.description }}
                      </div>
                    </b-checkbox>
                  </article>
                </b-field>
              </div>

              <div v-if="groupRoles">
                <div class="is-1" v-for="(c, index) in groupRoles" :key="index">
                  <b-field>
                    <article
                      class="has-background-info-light p-5 block-border"
                      v-if="c.name && c.name != undefined"
                    >
                      <b-checkbox v-model="checkboxGroupPermissionsGroup" :native-value="c.roleID">
                        <div class="font-bold">{{ c.name }}</div>
                        <div>
                          {{ c.description }}
                        </div>
                      </b-checkbox>
                    </article>
                  </b-field>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>

    <b-loading :is-full-page="false" v-model="loading"></b-loading>
  </Modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

//
import { data } from './keys/data'
import { methods } from './keys/methods'
//
import Modal from '@/components/Modal'
//
export default {
  components: {
    Modal,
    //
    ValidationObserver,
    ValidationProvider
  },
  props: {
    isOwner: {
      type: Boolean,
      default: false
    }
  },
  data,
  methods
}
</script>
