/*
  import { List as ListGroupingAccountManagingUsers } from '@/services/AccountSettings/Grouping/AccountManagingUserList'

  const { list } = await ListGroupingAccountManagingUsers ({
    hoaID
  });
*/

import kms from '@/services/kms'
import _get from 'lodash/get'
//
import { notifyProblem } from '@/services/notify'
//
export async function List({ hoaID }) {
  try {
    let result = await kms.get('/AccountSettings/Grouping/AccountManagingUserList', {
      params: {
        hoaID
      }
    })

    return {
      successful: true,
      list: _get(result, 'results', [])
    }
  } catch (exception) {
    console.error(exception)
  }

  notifyProblem('The users list could not be retrieved.')

  return {
    successful: false,
    list: []
  }
}
