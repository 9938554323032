/*
  import { invitationStore } from '@/services/AccountSettings/InvitationUser/store'

  const unsubscribe = invitationStore.subscribe((mutation, state) => {
    console.log(mutation.type)
    console.log(mutation.payload)
  })
*/

import Vue from 'vue'
import Vuex from 'vuex'
//
import { List as ListInvitationUsers } from './List'
//

Vue.use(Vuex)
export const invitationStore = new Vuex.Store({
  /*
    e.g. HOAs: {
      0: {

      }
    }
  */
  state: {
    HOAs: {}
  },
  mutations: {
    invitationList(state, { list, hoaID }) {
      if (state.HOAs[hoaID] === undefined) {
        state.HOAs[hoaID] = {}
      }

      state.HOAs[hoaID].invitationList = list
    }
  },
  getters: {},

  /* eslint-disable no-empty-pattern */
  actions: {
    /*
      const { list } = await invitationStore.dispatch ('listInvitations', {
        hoaID
      });
    */
    async listInvitations({ commit }, { hoaID }) {
      const { list } = await ListInvitationUsers({
        hoaID
      })

      commit('invitationList', { list, hoaID })

      return { list }
    }
  }
  /* eslint-enable no-empty-pattern */
})

//////
