export function data() {
  return {
    selected: null,
    rows: [],
    columns: [
      {
        field: 'groupName',
        label: 'Group Name',
        width: '20%',
        sortable: true
      },
      {
        field: 'roles',
        label: 'Roles',
        sortable: true
        // searchable: true
      }
    ],
    groupsList: []
  }
}
