/*
  import { garageItem } from '@/services/AccountSettings/Garages/GarageModal/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { garage } = await garageItem.dispatch ('getGarageById', {
      garageID
    });
  */
  //Garage by Id
  async getGarageById({}, { garageID }) {
    try {
      console.debug('in getGarageById...')
      const garage = await kms.get(`/ParkingSpaces/Garage/${garageID}`)

      if (isDebug == true) console.debug('getGarageById=' + JSON.stringify(garage))

      return {
        garage: garage
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the garage.`)
      console.error(exception)
    }

    return {
      garage: {}
    }
  },

  // Update Garage
  async updateGarage({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/ParkingSpaces/Garage`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.garageID > 0) {
        notifyMessage(`Successfully updated the garage.`)
        done()
      } else {
        notifyError('There was a problem updating this garage.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this garage.`)
      console.error(exception)
    }
  },

  // Add Garage
  async addGarage(
    { dispatch },
    { hoaID, garageNumber, vehicleCapacity, areaSquareFeet, unitID, done }
  ) {
    if (isDebug == true) console.debug('...in addGarage')
    try {
      const results = await kms.post(`/ParkingSpaces/Garage`, {
        hoaID,
        garageNumber,
        vehicleCapacity,
        areaSquareFeet,
        unitID
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.garageID > 0) {
        notifyMessage(`Successfully added the garage.`)
        done()
      } else {
        notifyError('There was a problem adding this garage.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this garage.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const garageItem = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
