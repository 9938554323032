<template>
  <span class="nowrap">
    <span :style="{ marginRight: '12px' }">
      <button
        v-if="isOwner === true"
        @click.prevent="revokeInvitedUser"
        class="button is-small is-link is-light"
      >
        Revoke
      </button>

      <b-tooltip
        v-if="isOwner !== true"
        label="Account Owner permission is necessary to revoke an invite"
        position="is-left"
      >
        <button disabled class="button is-small is-link is-light">
          Revoke
        </button>
      </b-tooltip>
    </span>

    <button
      v-if="isOwner === true"
      @click.prevent="resendInvitedUser"
      class="button is-small is-link is-light"
    >
      Resend
    </button>

    <b-tooltip
      v-if="isOwner !== true"
      label="Account Owner permission is necessary to resend an invite"
      position="is-left"
    >
      <button disabled class="button is-small is-link is-light">
        Resend
      </button>
    </b-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    isOwner: {
      type: Boolean,
      default: false
    },
    revokeInvitedUser: Function,
    resendInvitedUser: Function
  }
}
</script>
