<template>
  <div class="py-2 sm:py-4 flex flex-wrap">
    <div class="w-full sm:px-4">
      <div class="max-w-2xl border border-gray-400 rounded p-4 mx-auto">
        <dl>
          <div
            class="mt-4 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
          >
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Subscription Name
            </dt>
            <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ DTO.name || 'No subscription name selected' }}
            </dd>
          </div>
          <div
            class="mt-4 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
          >
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Description
            </dt>
            <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ DTO.description || 'No subscription description selected' }}
            </dd>
          </div>
          <div
            v-if="DTO.isMonthlySubscription == true"
            class="mt-4 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
          >
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Monthly Cost
            </dt>
            <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ DTO.monthlyCost | currency }}
            </dd>
          </div>
          <div
            v-else
            class="mt-4 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
          >
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Annual Cost
            </dt>
            <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ DTO.yearlyCost | currency }}
            </dd>
          </div>
          <div
            class="mt-4 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
            v-if="DTO.subscriptionFeatures && DTO.subscriptionFeatures != undefined"
          >
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Features
            </dt>
            <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              <div
                class="font-normal"
                v-for="(item, index) in DTO.subscriptionFeatures"
                :key="index"
              >
                <div v-if="item && item != undefined && item.feature && item.feature != undefined">
                  <li>{{ item.feature.name }}</li>
                </div>
              </div>
            </dd>
          </div>
          <div class="sm:px-6 sm:py-5 flex">
            <b-button
              type="submit is-primary"
              class="rounded mx-auto"
              :disabled="loading"
              @click.prevent="submitSave"
            >
              Save Subscription
            </b-button>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { ToastProgrammatic as Toast } from 'buefy'
import kms from '@/services/kms'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

export default {
  name: 'ReviewSubscription',

  props: {
    isChildManageSubscription: Boolean
  },

  data() {
    return {
      isDebug: true,
      loading: false,
      saved: false
    }
  },

  computed: {
    ...mapGetters('subscription', ['DTO']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  mounted() {},

  methods: {
    async submitSave() {
      try {
        if (this.DTO && this.DTO != undefined) {
          let payload = null
          if (this.DTO && this.DTO != undefined) {
            payload = {
              subscriptionID: this.DTO.subscriptionID,
              hoaID: this.hoaId,
              isMonthlySubscription: true
            }

            if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

            let path = ''
            let results = null

            path = `/Subscriptions/Subscription/SaveHoaSubscription`
            results = await kms.post(path, payload)

            if (results && results != undefined) {
              if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))
              notifyMessage(`Successfully saved your subscription.`)
              this.$emit(
                'update:isChildManageSubscription',
                (this.isChildManageSubscription = true)
              )
            } else {
              notifyError(`There was a problem saving your subscription.`)
            }
          }
        } else {
          notifyError(`There was a problem saving your subscription.`)
        }
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    }
  }
}
</script>

<style></style>
