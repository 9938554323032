/*
  import { AssignRolesToAccountManagingUser } from '@/services/AccountSettings/Role/AssignRolesToAccountManagingUser'

  const { successful } = await AssignRolesToAccountManagingUser ({
    json: {

    }
  });
*/

/*
  Owner json {
    hoaID: 0,
    accountManagingUserID: 0,

    isOwner: true,
    roleIds: []
  }

  Roles json {
    hoaID: 0,
  	accountManagingUserID: 0,

  	isOwner: false,
  	roleIds: [ 1,2,3,4,5,6 ]
  }
*/

import kms from '@/services/kms'

export async function AssignRolesToAccountManagingUser({ json }) {
  try {
    await kms.post('/AccountSettings/Role/AssignRolesToAccountManagingUser', json)

    return {
      successful: true
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false
  }
}
