import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import parseAddress from '@/utilities/address/parse'
import { garagesList } from '@/services/AccountSettings/Garages/List.js'

export const methods = {
  confirmDeleteGarage(id, name) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Garage',
      message: `Are you sure you want to <b>delete</b> the Garage for "${name}"?`,
      confirmText: 'Delete Garage',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteGarage(id)
    })
  },

  determineRows: function(list) {
    var rows = list.map(entry => {
      const garageNumber = _get(entry, 'garageNumber', '')
      const vehicleCapacity = _get(entry, 'vehicleCapacity', 0)
      const areaSquareFeet = _get(entry, 'areaSquareFeet', 0)
      const garageID = _get(entry, 'garageID', 0)

      return {
        garageNumber: garageNumber,
        vehicleCapacity: vehicleCapacity,
        areaSquareFeet: areaSquareFeet,
        unit: parseAddress({
          address: _get(entry, 'unit.address', '')
        }),
        edit: {
          component: Button,
          props: {
            onClick: () => {
              this.processGarageUpdate(entry)
            },
            text: 'Edit'
          }
        },
        erase: {
          component: Button,
          props: {
            onClick: () => {
              this.confirmDeleteGarage(garageID, garageNumber)
            },
            text: 'Delete'
          }
        }
      }
    })

    this.rows = rows
  },
  async reload() {
    console.debug('in reload...')
    this.loading = true

    await garagesList
      .dispatch('getGarageList', {
        hoaId: this.hoaId
      })
      .then(({ list }) => {
        this.determineRows(list)
        this.loading = false
      })
  },

  processGarageUpdate(garage) {
    this.toggle = true
    this.selectedGarage = garage
  },

  createGarageModal() {
    this.toggle = true
    this.selectedGarage = null
  },

  async deleteGarage(id) {
    await garagesList.dispatch('deleteGarage', {
      garageID: id,
      done: () => {
        this.loading = false
        this.reload()
      }
    })
  }
}
