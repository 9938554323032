<template>
  <Modal :toggle.sync="isOpen" group-adder-modal>
    <template v-slot:header>Add Group</template>

    <template v-slot:footer>
      <b-button type="is-primary" @click="addGroup()" :style="{ float: 'right' }">Add</b-button>
    </template>

    <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(addGroup)">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <valid-input
                    v-model="formData.name"
                    label="Name"
                    name="Name"
                    type="text"
                    vid="name"
                    placeholder="Name"
                    rules="required|max:100"
                  />
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <valid-input
                    v-model="formData.description"
                    label="Description"
                    name="Description"
                    maxlength="8000"
                    type="textarea"
                    vid="description"
                    placeholder="Description"
                    spellcheck="true"
                    rules="required|max:8000"
                    :style="{
                      minWidth: 'none !important'
                    }"
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </Modal>
</template>

<script>
/*


*/

import { ValidationObserver, ValidationProvider } from 'vee-validate'

//
import { data } from './keys/data'
import { methods } from './keys/methods'
//
import Modal from '@/components/Modal'
import ValidInput from '@/components/inputs/ValidInput'
//
export default {
  components: {
    Modal,
    ValidInput,
    //
    ValidationObserver,
    ValidationProvider
  },
  data,
  methods
}
</script>
