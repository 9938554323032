export const props = {
  committeeID: {
    type: [Number, String],
    default: null
  },
  committee: {
    type: Object,
    default: () => {
      return {}
    }
  }
}
