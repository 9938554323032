export function data() {
  return {
    inviteKind: 'groups', // 'roles'
    inviteRoleKind: '',

    checkboxInviteGroup: [],
    checkboxInviteRole: [],

    groupList: [],
    rolesList: [],

    loading: true
  }
}
