/*
  import { AddInvitedUser } from '@/services/AccountSettings/InvitationUser/AddInvitedUser'

  const { results } = await AddInvitedUser ({
    json
  });
*/

import kms from '@/services/kms'
import _get from 'lodash/get'
//
export async function AddInvitedUser({ json }) {
  let successMessage = `Successfully processed team member invitation.`
  let errorMessage = 'There was a problem inviting the team member.'

  let message = errorMessage

  try {
    const results = await kms.post(`/AccountSettings/InvitationUser/AddInvitedUser`, json)

    if (results && results == true) {
      return {
        successful: true,
        message: successMessage,
        results
      }
    }
  } catch (exception) {
    console.error(exception)

    message = _get(exception, 'message', errorMessage)
  }

  return {
    successful: false,
    message,
    results: null
  }
}
