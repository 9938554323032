export function data() {
  return {
    isOpen: false,

    emailAddresses: '',

    inviteRadio: 'groups',
    inviteRoleRadio: '',

    checkboxInviteGroup: [],
    checkboxInviteRole: [],

    groupList: [],
    rolesList: [],

    loading: true
  }
}
