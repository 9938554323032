//
import _get from 'lodash/get'
//
import kms from '@/services/kms'
import { notifyMessage, notifyWarning, notifyProblem } from '@/services/notify'
import { parseDate } from '@/utilities/Date/parse'
//
import Download from '../components/Download'

export const methods = {
  determineRows() {
    const receipts = _get(this, ['$store', 'getters', 'subscription/subscriptionReceipts'], [])
    if (!Array.isArray(receipts)) {
      console.error(`getter "subscription/subscriptionReceipts" did not return an array`, {
        receipts
      })
      return
    }

    this.rows = receipts.map(entry => {
      return {
        date: parseDate(_get(entry, 'createdDate', ''), 'LL'),
        amount: _get(entry, ['apInvoice', 'lineItemTotal'], '?'),
        status: _get(entry, ['apInvoice', 'apInvoiceApproval', 'apInvoiceStatus', 'status'], ''),
        receipt: {
          component: Download,
          props: {
            apInvoiceID: _get(entry, ['apInvoice', 'apInvoiceID'], null),
            downloadPdf: id => {
              this.downloadPdf(id)
            }
          }
        }
      }
    })
  },

  async downloadPdf(apInvoiceID) {
    if (this.isDebug == true) console.debug('downloadPdf...')

    if (!apInvoiceID || apInvoiceID == undefined) {
      notifyProblem('The apInvoiceID is invalid for the download.')
    }

    try {
      const params = {
        apInvoiceID: apInvoiceID,
        asBase64: true
      }

      const path = `/AccountsPayable/APInvoice/GetInvoiceFile`
      const returned = await kms.get(path, {
        params
      })

      if (this.isDebug == true)
        console.debug(
          'path base64 path=' +
            path +
            ' - params' +
            JSON.stringify(params) +
            ' - returned=' +
            JSON.stringify(returned)
        )
      if (returned && returned != undefined) {
        if (this.isDebug == true) console.debug('base64pdf........................' + returned)

        const contentType = 'application/pdf'
        const blob = this.base64toBlob(returned, contentType)
        const blobUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = blobUrl
        link.setAttribute('download', apInvoiceID + '.pdf')
        document.body.appendChild(link)
        link.click()

        notifyMessage(`Successfully downloaded the invoice.`)
      }

      this.base64pdf = returned
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading the invoice.')
    }
  },

  base64toBlob(b64Data, contentType) {
    const sliceSize = 512
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }
}
