/*
  import { AssignRolesToGroup } from '@/services/AccountSettings/Role/AssignRolesToGroup'

  const { successful } = await AssignRolesToGroup ({
    json: {
      groupingID: this.formData2.groupingID,
      hoaID: this.hoaId,
      roleIds: this.checkboxGroupPermissionsGroup
    }
  });
*/

import kms from '@/services/kms'
import _get from 'lodash/get'

export async function AssignRolesToGroup({ json }) {
  let errorMessage = 'A problem occurred while attempting to assign roles to the group.'

  try {
    await kms.post('/AccountSettings/Role/AssignRolesToGroup', json)

    return {
      successful: true
    }
  } catch (exception) {
    console.error(exception)

    errorMessage = _get(exception, 'message', errorMessage)
  }

  return {
    successful: false,
    errorMessage
  }
}
