import moment from 'moment'

export const data = function() {
  return {
    loading: true,
    isDebug: true,
    toggle: false,
    selectedReserveFundingTarget: null,
    existingYears: [],
    formatDateFn: function(value) {
      return value && value != undefined
        ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
            .format('MM-DD-YYYY')
            .toString()
        : ''
    },

    filters: {
      show: true
    },

    pagination: {
      perPage: 10
    },

    rows: [],

    columns: [
      {
        field: 'fiscalYear',
        label: 'Target Year',
        aria: 'Target Year',
        width: '40%',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'reserveTarget',
        label: 'Reserve Target',
        width: '60%',
        sortable: true,
        searchable: true
      }
    ]
  }
}
