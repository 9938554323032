<template>
  <div class="section" id="committee-member-editor">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">&nbsp;</div>
              <div class="pl-4 pb-3 columns is-12">
                <b-field vertical label="Term Start Date"
                  ><br />
                  <b-datepicker
                    placeholder="Select date..."
                    icon="calendar-today"
                    v-model="formData.termStartDate"
                    rules="required"
                    required
                  ></b-datepicker>
                </b-field>
              </div>

              <div class="column is-12">&nbsp;</div>

              <div class="pl-4 pb-3 columns is-12">
                <b-field vertical label="Term End Date"
                  ><br />
                  <b-datepicker
                    label="Term End Date"
                    placeholder="Select date..."
                    icon="calendar-today"
                    v-model="formData.termEndDate"
                    rules="required"
                    required
                  ></b-datepicker>
                </b-field>
              </div>

              <div class="column is-12">
                <valid-input
                  name="Name"
                  label="Name"
                  maxlength="450"
                  type="text"
                  vid="name"
                  placeholder="Name"
                  spellcheck="true"
                  aria-label="Name"
                  rules="required|max:450"
                  v-model="formData.name"
                />
              </div>

              <div class="column is-12">
                <valid-input
                  name="Email"
                  label="Email"
                  maxlength="100"
                  type="email"
                  vid="email"
                  placeholder="Email"
                  spellcheck="true"
                  aria-label="Email"
                  rules="required|max:100"
                  v-model="formData.email"
                />
              </div>

              <div
                class="column is-12"
                v-if="committeeTitleList && committeeTitleList != undefined"
              >
                <valid-select
                  placeholder="Title"
                  label="Title"
                  vid="committeeTitleID"
                  v-model="formData.committeeTitleID"
                  title="Title"
                  rules="required"
                >
                  <option
                    v-for="option in committeeTitleList"
                    :value="option.value"
                    :key="option.value"
                    >{{ option.label }}</option
                  >
                </valid-select>
              </div>

              <!--               <div
                class="pt-5 column is-12"
                v-if="hoaAccountManagingUserList && hoaAccountManagingUserList != undefined"
              >
                <valid-select
                  placeholder="Account Users"
                  label="Account Users"
                  vid="hoaAccountManagingUserID"
                  v-model="formData.hoaAccountManagingUserID"
                  title="Account Users"
                  rules="required"
                >
                  <option
                    v-for="option in hoaAccountManagingUserList.dropDown"
                    :value="option.value"
                    :key="option.value"
                    >{{ option.label }}</option
                  >
                </valid-select>
              </div> -->

              <div class="pt-5 pb-5 column is-12">
                <b-field>
                  <b-checkbox class="has-left-text" v-model="formData.isActive">Active</b-checkbox>
                </b-field>

                <!--                 Active:&nbsp;
                <b-checkbox class="has-left-text" v-model="formData.isActive"></b-checkbox> -->
              </div>
              <div>&nbsp;</div>
              <div class="column is-12 float-right">
                <button type="submit" class="button is-primary is-rounded" :disabled="loading">
                  Save
                </button>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
// Components
import { ToastProgrammatic as Toast } from 'buefy'
import $ from 'jquery'
import moment from 'moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters, mapState } from 'vuex'
//
import { ValidationError } from '@/services/errors'
//
// components
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

let DEFAULT_FORM_VALUES = {
  committeeID: 0,
  hoaAccountManagingUserID: 0,
  committeeTitleID: 0,
  inactive: false,
  emailAddress: '',
  termStartDate: null,
  termEndDate: null,
  name: '',
  email: ''
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'EditCommitteeMemberModal',

  props: {
    selectedCommitteeMember: Object,
    committeeMemberID: Number,
    committee: Object
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      formatDateFnPost: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('YYYY-MM-DD') : ''
      },
      isDebug: true,
      selectedRow: null
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('committees', ['committeeTitleList', 'committeeMember']),
      ...mapGetters('hoa', ['hoaAccountManagingUserList'])
    })
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    ValidSelect
  },

  mounted() {
    if (this.isDebug == true) {
      console.debug('committeeMemberID=' + this.committeeMemberID)
      console.debug('selectedCommitteeMember=' + JSON.stringify(this.selectedCommitteeMember))

      //Load current form values
      if (this.selectedCommitteeMember && this.selectedCommitteeMember != undefined) {
        this.formData.termStartDate = new Date(this.selectedCommitteeMember.termStartDate)
        this.formData.termEndDate = new Date(this.selectedCommitteeMember.termEndDate)
        this.formData.name = this.selectedCommitteeMember.name
        this.formData.email = this.selectedCommitteeMember.emailAddress
        this.formData.committeeTitleID = this.selectedCommitteeMember.committeeTitleID
        this.formData.hoaAccountManagingUserID = this.selectedCommitteeMember.hoaAccountManagingUserID
        this.formData.isActive = !this.selectedCommitteeMember.inactive
      }
    }

    this.reload()
  },

  methods: {
    reload() {
      this.$store.dispatch('committees/getCommitteeTitleList', {
        committeeTypeID:
          this.committee && this.committee != undefined ? this.committee.committeeTypeID : 0
      })
      this.$store.dispatch('committees/loadCommitteeMember', {
        committeeMemberID: this.committeeMemberID
      })
      this.$store.dispatch('hoa/loadHoaAccountManagingUserList')
    },
    async onFormSubmit() {
      if (this.isDebug == true) console.debug(this.formData)

      if (
        !this.formData ||
        this.formData == undefined ||
        !this.selectedCommitteeMember ||
        this.selectedCommitteeMember == undefined
      ) {
        notifyError('Please enter valid committee member data.')
        return
      }

      if (this.formData && this.formData != undefined) {
        if (this.formData.termStartDate == null || this.formData.termEndDate == null) {
          notifyError('Please choose a start and end term date.')
          return
        }

        this.loading = true

        this.$store.dispatch('committees/updateCommitteeMember', {
          payload: {
            committeeMemberID: this.committeeMemberID,
            hoaID: this.hoaId,
            name: this.formData.name,
            hoaAccountManagingUserID: this.formData.hoaAccountManagingUserID,
            emailAddress: this.formData.email,
            committeeTitleID: this.formData.committeeTitleID,
            committeeID: this.selectedCommitteeMember.committeeID,
            inactive: !this.formData.isActive,
            termStartDate: this.formatDateFnPost(this.formData.termStartDate),
            termEndDate: this.formatDateFnPost(this.formData.termEndDate)
          },
          done: () => {
            // auto-close modal
            this.$emit('update:memberUpdateToggle', (this.memberUpdateToggle = false))
            $('#clickclose').click()
            this.loading = false
          },
          exception: ({ ex }) => {
            if (ex instanceof ValidationError) {
              this.$refs.form.setErrors(ex.fields)
              notifyError(ex)
            }

            this.loading = false
          }
        })
      } else {
        notifyError('Please check your selections prior to saving.')
      }

      this.loading = false
    }
  }
}
</script>

<style lang="scss">
#committee-member-editor {
  .step-details {
    background-color: #fff !important;
    padding-left: 0.25rem !important;
    padding-right: 0.5rem !important;
  }

  .b-steps.is-small .steps {
    margin-top: 1rem;
  }

  .b-steps .steps + .step-content {
    padding-top: 0 !important;
  }

  .step-navigation {
    display: flex;
    justify-content: flex-end;
  }

  .my-grid-class {
    height: 400px;
  }

  tbody {
    min-height: 100px !important;
    overflow: auto !important;
  }
}
</style>
