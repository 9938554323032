<template>
  <Modal :toggle.sync="isOpen" member-invitation-modal>
    <template v-slot:header>Invite Team Members</template>

    <template v-slot:footer>
      <button
        type="submit"
        class="button is-primary is-medium"
        :disabled="loading"
        :style="{ float: 'right' }"
        @click="inviteUser()"
      >
        Invite
      </button>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(inviteUser)" class="form p-5">
          <fieldset>
            <valid-input
              v-model="emailAddresses"
              label="Enter Email Addresses"
              name="EmailAddresses"
              vid="emailAddresses"
              placeholder="Enter email addresses separated by a comma"
              type="text"
              rules="required|max:8000"
            />
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>

    <div :style="{ padding: '0 1.5rem 1.5rem' }">
      <PermissionsChooser ref="permissionsChooser" />
    </div>

    <b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>
  </Modal>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
//
import Modal from '@/components/Modal'
import ValidInput from '@/components/inputs/ValidInput'
import PermissionsChooser from './../PermissionsChooser'

//
import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    Modal,
    PermissionsChooser,
    ValidationObserver,
    ValidInput
  },
  props: {
    inviteWasSent: Function
  },
  data,
  methods,
  mounted() {
    this.init()
  }
}
</script>
