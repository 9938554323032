/*
  import { List as ListCommitteeTitles } from '@/services/Committees/CommitteeTitle/List'

  const { list: committeeTitlesList } = await ListCommitteeTitles ({
    params: {
      committeeTypeID
    }
  });
*/
//
import _get from 'lodash/get'
import kms from '@/services/kms'
//
export async function List({ params }) {
  try {
    let result = await kms.get(`/Committees/CommitteeTitle/List`, {
      params
    })

    return {
      list: _get(result, 'results', [])
    }
  } catch (exception) {
    console.error(exception)
  }

  return { list: [] }
}
