export function data() {
  return {
    selected: null,
    rows: [],
    columns: [
      {
        field: 'name',
        label: 'Name',
        width: '20%',
        sortable: true
      },
      {
        field: 'email',
        label: 'Email',
        width: '20%',
        sortable: true
      },
      {
        field: 'roles',
        label: 'Roles',
        sortable: true
        // searchable: true
      },
      {
        field: 'groups',
        label: 'Groups'
      },
      {
        field: 'actions',
        label: 'Actions'
      }
    ],
    invitationUsersList: [],
    groupAccountManagingUsersList: []
  }
}
