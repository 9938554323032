<template>
  <PageContentLayoutOnly role="region" :aria-label="$t('generalSettings.title')">
    <div class="box is-4" v-if="true">
      <div class="tile is-ancestor">
        <div class="tile is-vertical is-12">
          <div>
            <article>
              <ValidationObserver ref="form" v-slot="{ handleSubmit }" v-if="authUser">
                <transition name="fade" mode="out-in" appear>
                  <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
                    <fieldset>
                      <div class="form-grid">
                        <valid-input type="hidden" vid="accountID" v-model="formData.accountID" />
                        <valid-input type="hidden" vid="status" v-model="formData.status" />
                        <div class="column is-12" :style="styles.formRow">
                          <span style="white-space: nowrap">
                            <valid-input
                              label="Account Name"
                              name="Name"
                              type="text"
                              vid="name"
                              readonly="true"
                              disabled="true"
                              placeholder="Account Name"
                              spellcheck="true"
                              maxlength="250"
                              rules="required|max:250"
                              v-model="formData.name"
                            />
                          </span>
                        </div>
                        <div class="column is-12" :style="styles.formRow">
                          <valid-input
                            label="Billing Email"
                            name="Email"
                            maxlength="250"
                            type="email"
                            vid="email"
                            placeholder="Billing Email"
                            spellcheck="true"
                            rules="max:250"
                            v-model="formData.email"
                          />
                        </div>
                        <div class="column is-12" :style="styles.formRow">
                          <button
                            type="submit"
                            class="button is-primary is-rounded"
                            :disabled="loading"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset>
                      <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                        <div class="field">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </fieldset>
                  </form>
                </transition>
              </ValidationObserver>
            </article>
          </div>
        </div>
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import kms from '@/services/kms'
import { mapState, mapGetters, mapActions } from 'vuex'
import { ToastProgrammatic as Toast } from 'buefy'
import ValidInput from '@/components/inputs/ValidInput'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import moment from 'moment'
//const today = moment()

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

let DEFAULT_FORM_VALUES = {
  name: '',
  email: '',
  status: ''
}

export default {
  components: {
    PageContentLayoutOnly,
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },
  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: true,
      isDebug: true,
      formatDateFn: function(value) {
        return value && value != undefined
          ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
              .format('MM-DD-YYYY')
              .toString()
          : ''
      },
      styles: {
        formRow: {
          padding: '10px',
          whiteSpace: 'nowrap'
        }
      }
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  mounted() {
    this.loading = true

    if (this.isDebug == true) console.debug('gen settings authUser=' + this.authUser)

    this.reload()

    this.loading = false
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  methods: {
    ...mapActions('user', ['selectHoaId']),

    async reload() {
      this.loading = true

      this.formData = { ...DEFAULT_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }

      this.getAccountById()

      this.loading = false
    },

    async getAccountById() {
      try {
        const getAccount = await kms.get(`/Account/${this.authUser.accountID}`)

        if (this.isDebug == true) console.debug('getAccount=' + JSON.stringify(getAccount))

        if (getAccount && getAccount != undefined) {
          this.formData.accountID = getAccount.accountID
          this.formData.name = getAccount.name
          this.formData.email = getAccount.email
          this.formData.status = getAccount.status

          if (this.isDebug == true)
            console.debug('gen settings formData=' + JSON.stringify(this.formData))
        }
      } catch (e) {
        notifyError(e)
      }
    },

    async onFormSubmit() {
      try {
        if (!this.formData || this.formData == undefined) {
          notifyError('There was a problem saving your general settings.')
          return
        }

        this.loading = true

        const payload = {
          accountID: this.formData.accountID,
          name: this.formData.name,
          email: this.formData.email,
          status: this.formData.status
        }

        if (this.isDebug == true) console.debug('formdata submit' + JSON.stringify(this.formData))

        try {
          const path = `/Account`
          const results = await kms.put(path, payload)

          if (this.isDebug == true) console.debug(JSON.stringify(results))

          if (results && results != undefined) {
            notifyMessage(`Your general settings have been updated`)
            this.reload()
          } else {
            notifyError('There was a problem updating your general settings.')
          }
          this.loading = false
        } catch (e) {
          notifyError(e)
        }
        this.loading = false
      } catch (e) {
        //If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        } else {
          notifyError(e)
        }
      }

      this.loading = false
    }
  },
  i18n: {
    messages: {
      en: { generalSettings: { title: 'General Settings' } }
    }
  }
}
</script>

<style lang="scss">
textarea {
  min-width: 600px !important;
}

button {
  padding: 5px;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 940px;
  }

  .app-properties {
    > div {
      margin: 0 8px 8px 0;
      font-style: normal !important;

      > label {
        display: block;
      }
      > span {
        font-style: normal !important;
      }
      .vue-quick-edit {
        display: inline-block;
        margin-left: 8px;
        font-style: normal !important;
      }
    }
  }
}
</style>
