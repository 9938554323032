import _get from 'lodash/get'
//
import { List as ListGroups } from '@/services/AccountSettings/Grouping/List'
import { List as ListRoles } from '@/services/AccountSettings/Role/List'
import { AddInvitedUser } from '@/services/AccountSettings/InvitationUser/AddInvitedUser'
import { invitationStore } from '@/services/AccountSettings/InvitationUser/store'
//
import { notifyMessage, notifyProblem } from '@/services/notify'
//
export const methods = {
  async init() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const { list: GroupsList } = await ListGroups({
      hoaID
    })

    const { list: RolesList } = await ListRoles({
      hoaID
    })

    this.groupList = GroupsList
    this.rolesList = RolesList

    this.loading = false
  },

  async inviteUser() {
    this.loading = true

    const isValid = await this.$refs.observer.validate()
    if (!isValid) {
      notifyProblem('An email address is required')
      this.loading = false
      return
    }

    const {
      inviteRoleKind,
      checkboxInviteGroup,
      checkboxInviteRole
    } = this.$refs.permissionsChooser.getData()

    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    const emailAddresses = this.emailAddresses

    let isOwner = false
    if (inviteRoleKind === 'owner') {
      isOwner = true
    }

    const { successful, message } = await AddInvitedUser({
      json: {
        emailAddresses,
        hoaID,

        isOwner,
        roleIds: checkboxInviteRole,
        groupIds: checkboxInviteGroup
      }
    })
    if (!successful) {
      notifyProblem(message)
      this.loading = false
      return
    } else {
      notifyMessage(message)
    }

    // refresh the invitations list
    await invitationStore.dispatch('listInvitations', {
      hoaID
    })

    this.inviteWasSent()

    this.isOpen = false
    this.loading = false
  },

  async open() {
    this.emailAddresses = ''
    this.isOpen = true
  }
}
