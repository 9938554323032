import { mapActions } from 'vuex'
import { reserveStore } from '@/services/ReserveFundingTarget/store'
import _get from 'lodash/get'
import { formatCurrency } from '@/filters'
import Button from '@/components/buttons/Button'

export const methods = {
  ...mapActions('user', ['selectHoaId']),

  confirmReserveFundingDelete(entry) {
    if (this.isDebug == true) console.debug('...in confirmReserveFudingDelete')
    this.$buefy.dialog.confirm({
      title: 'Deleting Reserve Funding',
      message: `Are you sure you want to <b>delete</b> these reserve funding values for: '${
        entry.fiscalYear
      }, in the amount of: ${formatCurrency(entry.targetAmount)}'?`,
      confirmText: 'Delete Reserve Funding',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteReserveFundingClicked(entry.reserveFundingTargetID)
    })
  },

  async deleteReserveFundingClicked(id) {
    if (this.isDebug == true) console.debug('...in deleteReserveFundingClicked id=' + id)

    await reserveStore.dispatch('deleteReserveFunding', {
      reserveFundingTargetID: id,
      done: () => {
        this.loading = false
        this.reload()
      }
    })
  },

  async reload() {
    this.loadReserveFundingTarget()
  },

  async onFormSubmit() {
    console.debug('in addReserveFundingTarget...')

    const payload = {
      reserveFundingTargetID: this.reserveFundingID,
      hoaID: this.currentHoaId,
      fiscalYear: this.targetYear,
      targetAmount: this.reserveTarget
    }

    if (this.reserveFundingID !== undefined && this.reserveFundingID > 0) {
      await reserveStore.dispatch('updateReserveFunding', {
        payload,
        done: async () => {
          this.reload()
        }
      })
    } else {
      await reserveStore.dispatch('addReserveFundingTarget', {
        hoaID: this.currentHoaId,
        fiscalYear: this.targetYear,
        targetAmount: this.reserveTarget,
        done: async () => {
          this.reload()
        }
      })
    }
  },

  determineRows: function(list) {
    if (list) {
      this.rows = list.map(entry => {
        const fiscalYear = _get(entry, 'fiscalYear', 0)
        const targetAmount = formatCurrency(_get(entry, 'targetAmount', 0))

        return {
          fiscalYear,
          targetAmount,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processUpdate(entry)
              },
              text: 'Edit'
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmReserveFundingDelete(entry)
              },
              text: 'Delete'
            }
          }
        }
      })

      this.existingYears = this.rows.map(m => ({
        year: m.fiscalYear
      }))
    }
  },

  async loadReserveFundingTarget() {
    await reserveStore
      .dispatch('listReserveFundingTarget', {
        hoaId: this.currentHoaId
      })
      .then(({ list }) => {
        console.debug('listReserveFundingTarget=' + JSON.stringify(list))
        if (list) {
          this.determineRows(list)
        }
      })
  },

  createReserveFundingTargetModal() {
    console.debug('in createReserveFundingTargetModal...')
    this.selectedReserveFundingTarget = null
    this.toggle = true
  },

  processUpdate(entry) {
    console.debug('in processUpdate...')
    this.selectedReserveFundingTarget = entry
    this.toggle = true
  },

  addReserveFundingClicked() {
    this.toggle = true
  }
}
