//
import _get from 'lodash/get'
//
import { notifyMessage } from '@/services/notify'
//
import { List as ListHoaAccountManagingUsers } from '@/services/AccountSettings/HoaAccountManagingUser/List'
import { List as ListInvitationUsers } from '@/services/AccountSettings/InvitationUser/List'
import { List as ListGroupingAccountManagingUsers } from '@/services/AccountSettings/Grouping/AccountManagingUserList'
import { ResendInvitationUser } from '@/services/AccountSettings/InvitationUser/ResendInvitationUser'
//
import { RevokeInvitationUser } from '@/services/AccountSettings/InvitationUser/RevokeInvitationUser'
//
import UserTableActions from './../../UserTableActions'
//
export const methods = {
  determineRows() {
    const { groupAccountManagingUsersList, invitationUsersList } = this

    this.rows = []

    if (Array.isArray(groupAccountManagingUsersList)) {
      groupAccountManagingUsersList
        .map(entry => {
          const email = _get(entry, ['aspNetUserEmail'], '')

          let name = ''
          name += _get(entry, ['name'], '')

          let roles = _get(entry, 'roles', '')
          const isOwner = _get(entry, 'isOwner', null)
          if (isOwner === true) {
            roles = 'Account Owner'
          }

          if (typeof roles !== 'string' || roles.length === 0) {
            roles = 'Account Member'
          }

          const groups = _get(entry, 'groups', '')

          /*
          todo:
            add 'edit' to actions
        */
          return {
            name,
            email,
            roles,
            groups,
            actions: '',
            _meta: {
              isRegisteredUser: true,
              user: entry
            }
          }
        })
        .forEach(row => {
          this.rows.push(row)
        })
    }

    if (Array.isArray(invitationUsersList)) {
      console.log('invitationUsersList=' + JSON.stringify(invitationUsersList))
      invitationUsersList
        .map(entry => {
          const email = _get(entry, ['emailAddress'], '')

          let name = ''
          name += _get(entry, ['name'], '')

          const invitationUserID = _get(entry, 'invitationUserID', null)

          let roles = _get(entry, 'roles', '')
          const isOwner = _get(entry, 'isOwner', null)
          if (isOwner === true) {
            roles = 'Account Owner'
          }

          const authUserIsOwner = this.isOwner

          return {
            name,
            email,
            roles,
            groups: '',
            actions: {
              component: UserTableActions,
              props: {
                isOwner: authUserIsOwner,
                revokeInvitedUser: async event => {
                  event.stopPropagation()

                  const { successful } = await RevokeInvitationUser({
                    invitationUserID
                  })
                  if (successful) {
                    await this.init()
                    notifyMessage(`Successfully revoked the invited team member.`)
                  }
                },
                resendInvitedUser: async event => {
                  event.stopPropagation()

                  await ResendInvitationUser({
                    invitationUserID
                  })
                }
              }
            }
          }
        })
        .forEach(row => {
          this.rows.push(row)
        })
    }
  },
  async init() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    Promise.all([
      await ListHoaAccountManagingUsers({
        hoaID
      }),
      await ListInvitationUsers({
        hoaID
      }),
      await ListGroupingAccountManagingUsers({
        hoaID
      })
    ]).then(lists => {
      this.invitationUsersList = _get(lists, [1, 'list'], [])
      this.groupAccountManagingUsersList = _get(lists, [2, 'list'], [])

      this.determineRows()
    })
  },

  rowOnClick({ row }) {
    const isRegisteredUser = _get(row, ['_meta', 'isRegisteredUser'], false)
    if (isRegisteredUser) {
      this.showEditModal({
        selection: row
      })
    }
  }
}
