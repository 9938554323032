<template>
  <Modal
    :toggle.sync="isOpen"
    ariaLabel="add committee member modal"
    :styles="{
      footer: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'flex-end'
      }
    }"
  >
    <div :style="{ paddingBottom: '300px' }">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form
            @submit.prevent="handleSubmit(onFormSubmit)"
            class="form"
            :style="{ padding: '20px 0' }"
          >
            <div :style="{ marginBottom: '20px' }">
              <valid-select
                committee-member-user-select
                v-model="hoaAccountManagingUserID"
                placeholder="Member"
                label="Member"
                title="Member"
                vid="hoaAccountManagingUserID"
                rules="required"
              >
                <option
                  v-for="option in hoaAccountManagingUserList"
                  :value="option.hoaAccountManagingUserID"
                  :key="option.hoaAccountManagingUserID"
                  >{{ option.label }}</option
                >
              </valid-select>
            </div>

            <div :style="{ marginBottom: '20px' }">
              <valid-select
                committee-member-title-select
                placeholder="Title"
                label="Title"
                title="Title"
                vid="committeeTitleID"
                v-model="formData.committeeTitleID"
                rules="required"
              >
                <option
                  committee-member-title-option
                  v-for="option in committeeTitleList"
                  :key="option.committeeTitleID"
                  :value="option.committeeTitleID"
                  >{{ option.title }}</option
                >
              </valid-select>
            </div>

            <div :style="{ marginBottom: '20px' }">
              <ValidInput
                committee-member-name-input
                placeholder="Name"
                label="Name"
                title="Name"
                v-model="formData.name"
                rules="required"
              />
            </div>

            <div :style="{ marginBottom: '20px' }">
              <ValidInput
                committee-member-email-input
                placeholder="Email"
                label="Email"
                title="Email"
                v-model="formData.email"
                rules="required"
              />
            </div>

            <DatePicker
              committee-member-start-date
              v-model="formData.termStartDate"
              :errorMessage.sync="formErrors.termStartDate"
              ariaLabel="Term Start Date"
              label="Term Start Date"
            />

            <DatePicker
              committee-member-end-date
              v-model="formData.termEndDate"
              :errorMessage.sync="formErrors.termEndDate"
              :style="{ marginLeft: '20px' }"
              ariaLabel="Term End Date"
              label="Term End Date"
            />

            <b-field
              :style="{
                paddingLeft: '10px',
                paddingTop: '20px'
              }"
            >
              <b-checkbox committee-member-active-checkbox v-model="formData.active"
                >Active</b-checkbox
              >
            </b-field>
          </form>
        </transition>
      </ValidationObserver>
    </div>

    <template v-slot:header>Add Committee Member</template>

    <template v-slot:footer>
      <b-button @click="add()" type="is-primary">Add</b-button>
    </template>
  </Modal>
</template>

<script>
/*
  this.$refs.AddCommitteeMemberModal.open ();
*/
//
import { ValidationObserver } from 'vee-validate'
//
// components
import DatePicker from '@/components/inputs/DatePicker'
import Modal from '@/components/Modal'
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
//

//
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { props } from './keys/props'
import { watch } from './keys/watch'
//

export default {
  components: {
    DatePicker,
    Modal,
    ValidationObserver,
    ValidInput,
    ValidSelect
  },

  props,
  computed,
  data,
  methods,
  watch
}
</script>
