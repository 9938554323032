import _get from 'lodash/get'
import { committeeSettings } from '@/services/Committees/CommitteeSettings/store'

export const mounted = function() {
  /*
    sets tabindex on navigation tabs

      the nav doesn't fully render until
      after this component is mounted
  */
  setTimeout(() => {
    try {
      const nav = this.$refs.committeesTabs.$el.querySelector('nav.tabs')
      const items = nav.querySelectorAll('li')

      items.forEach(item => {
        item.setAttribute('tabindex', '0')
      })
    } catch (ex) {
      console.error(ex)
    }
  }, 1000)

  const routes = {
    'committees/committeeList': () => {
      this.generateCommitteeLists()
    },
    'committees/committeeMemberList': () => {
      this.generateMemberLists()
    },
    'subscription/subscription': () => {
      this.subscription = _get(this, ['$store', 'getters', 'subscription/subscription'], '')
    }
  }
  this.unsubscribe = this.$store.subscribe(mutation => {
    const mType = mutation.type

    if (typeof routes[mType] === 'function') {
      routes[mType]()
    }
  })

  this.unsubscribeCommitteeSettings = committeeSettings.subscribe(mutation => {
    const mType = mutation.type

    if (mType === 'updateList') {
      ;(this.primaryArchitecturalCommitteeID = _get(
        committeeSettings,
        ['state', 'HOAs', this.hoaId, 'architecturalCommitteeID'],
        null
      )),
        (this.primaryBoardCommitteeID = _get(
          committeeSettings,
          ['state', 'HOAs', this.hoaId, 'boardCommitteeID'],
          null
        ))
    }
  })

  this.$store.dispatch('committees/getCommitteeList')
  this.$store.dispatch('subscription/getHoaSubscription')
}
