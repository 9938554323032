<template>
  <div class="section" edit-committee id="committee-editor">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form
          @submit.prevent="handleSubmit(onFormSubmit)"
          class="form"
          :style="{
            opacity: firstLoad ? 0 : 1,
            transition: 'opacity 1s'
          }"
        >
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <valid-input
                  name-input
                  v-model="formData.name"
                  name="Name"
                  label="Name"
                  placeholder="Name"
                  aria-label="Name"
                  maxlength="450"
                  type="text"
                  vid="name"
                  spellcheck="true"
                  rules="required|max:450"
                />
              </div>

              <div class="column is-12" v-if="committeeTypeList && committeeTypeList != undefined">
                <b-tooltip
                  :label="typeTooltip"
                  :active="isPrimaryArch || isPrimaryBoard"
                  :style="{ width: '100%' }"
                  :multilined="true"
                >
                  <valid-select
                    :disabled="isPrimaryArch || isPrimaryBoard"
                    label="Type"
                    placeholder="Type"
                    title="Type"
                    vid="committeeTypeID"
                    v-model="formData.committeeTypeID"
                    rules="required"
                  >
                    <option
                      v-for="option in committeeTypeList"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </b-tooltip>
              </div>

              <div class="column is-12" :style="{ margin: '20px 0' }">
                <b-field>
                  <b-tooltip
                    :label="activeTooltip"
                    :active="isPrimaryArch || isPrimaryBoard"
                    :multilined="true"
                    position="is-right"
                  >
                    <b-checkbox
                      class="has-left-text"
                      v-model="formData.isActive"
                      :disabled="isPrimaryArch || isPrimaryBoard"
                      >Active</b-checkbox
                    >
                  </b-tooltip>
                </b-field>
              </div>

              <div>&nbsp;</div>
              <div class="column is-12 float-right">
                <button type="submit" class="button is-primary is-rounded" :disabled="loading">
                  Save
                </button>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>

    <b-loading :is-full-page="false" v-model="loading" />
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState } from 'vuex'
// import 'vue-editable-grid/dist/VueEditableGrid.css'
import { mapGetters } from 'vuex'
import ValidSelect from '@/components/inputs/ValidSelect'

// components
import ValidInput from '@/components/inputs/ValidInput'

import moment from 'moment'
//const today = moment()

let DEFAULT_FORM_VALUES = {
  name: '',
  isActive: false,
  committeeTypeID: 0
}

export const defaultDateFormat = 'MM/dd/yyyy'
//
import { methods } from './keys/methods'
//
export default {
  name: 'EditCommitteeModal',

  props: {
    committeeID: Number
  },

  data: function() {
    return {
      isPrimaryArch: false,
      isPrimaryBoard: false,
      //
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: true,
      firstLoad: true,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null
    }
  },

  async created() {
    await this.refresh()
    this.firstLoad = false
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('committees', ['committee']),
      ...mapGetters('committees', ['committeeTypeList'])
    }),
    typeTooltip() {
      let tip = name => {
        return `The type of the primary ${name} committee cannot be changed.  To change the type of this committee, please first set another committee as the primary ${name} committee.`
      }

      if (this.isPrimaryArch) {
        return tip('architectural')
      }

      if (this.isPrimaryBoard) {
        return tip('board')
      }

      return ''
    },
    activeTooltip() {
      let tip = name => {
        return `The primary ${name} committee cannot be deactivated.  To deactivate this committee, please first set another committee as the primary ${name} committee.`
      }

      if (this.isPrimaryArch) {
        return tip('architectural')
      }

      if (this.isPrimaryBoard) {
        return tip('board')
      }

      return ''
    }
  },

  watch: {
    hoaId() {
      this.refresh()
    }
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    ValidSelect
  },

  methods
}
</script>

<style lang="scss">
#committee-editor {
  .step-details {
    background-color: #fff !important;
    padding-left: 0.25rem !important;
    padding-right: 0.5rem !important;
  }

  .b-steps.is-small .steps {
    margin-top: 1rem;
  }

  .b-steps .steps + .step-content {
    padding-top: 0 !important;
  }

  .step-navigation {
    display: flex;
    justify-content: flex-end;
  }

  .my-grid-class {
    height: 400px;
  }

  tbody {
    min-height: 100px !important;
    overflow: auto !important;
  }
}
</style>
