<template>
  <ModernTable
    groups-table
    :columns="columns"
    :rows="rows"
    :filters="{
      show: false
    }"
    :focusable="true"
    :selected.sync="selected"
    condensed
    height="initial"
    :rowOnClick="rowOnClick"
  />
</template>

<script>
//
import { beforeDestroy } from './keys/beforeDestroy'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { props } from './keys/props'
//
import ModernTable from '@/components/tables/Modern/Table'
//
export default {
  components: {
    ModernTable
  },
  beforeDestroy,
  data,
  methods,
  mounted,
  props
}
</script>
