<template>
  <div class="box is-4">
    <div tabindex="0" aria-label="primary committee selection panel">
      <b-field label="Primary Architectural Requests Commmitee">
        <b-select
          primary-arch-select
          aria-label="Primary Architectural Requests Commmitee"
          :placeholder="selectedArchCommitteePlaceholder"
          v-model="selectedArchCommitteeID"
          @input="selectedArchCommitteeChanged"
        >
          <option
            :value="null"
            disabled
            :style="{ color: '#888' }"
            v-if="!loading && archCommittees && archCommittees.length === 0"
          >
            An architectural committee has not yet been added.
          </option>
          <option
            primary-arch-option
            v-for="option in archCommittees"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <br />

      <b-field label="Primary Board of Directors Commmitee">
        <b-select
          primary-board-select
          aria-label="Primary Board of Directors Commmitee"
          :placeholder="selectedBoardCommitteePlaceholder"
          v-model="selectedBoardCommitteeID"
          @input="selectedBoardCommitteeChanged"
        >
          <option
            :value="null"
            disabled
            :style="{ color: '#888' }"
            v-if="!loading && boardCommittees && boardCommittees.length === 0"
          >
            A board of directors committee has not yet been added.
          </option>
          <option
            primary-board-option
            v-for="option in boardCommittees"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
      <b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>
    </div>

    <Modal
      primary-arch-committee-update-modal
      :toggle.sync="primaryArchCommitteeUpdateModal.show"
      :styles="{
        footer: {
          padding: '20px'
        }
      }"
    >
      <div
        :style="{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          padding: '40px 0'
        }"
      >
        <b-icon type="is-danger" icon="alert-circle" size="is-large"> </b-icon>

        <div :style="{ padding: '0 1rem', fontSize: '20px', color: 'rgb(74, 74, 74)' }">
          <p
            v-if="
              primaryArchCommitteeUpdateModal.actual.name ===
                primaryArchCommitteeUpdateModal.selected.name
            "
          >
            Are you sure you want to update the primary architectural committee from
            <b>{{ primaryArchCommitteeUpdateModal.actual.name }}</b> (id
            {{ primaryArchCommitteeUpdateModal.actual.id }}) to
            <b>{{ primaryArchCommitteeUpdateModal.selected.name }}</b> (id
            {{ primaryArchCommitteeUpdateModal.selected.id }})?
          </p>

          <p v-else>
            Are you sure you want to update the primary architectural committee from
            <b>{{ primaryArchCommitteeUpdateModal.actual.name }}</b> to
            <b>{{ primaryArchCommitteeUpdateModal.selected.name }}</b
            >?
          </p>

          <br />

          <!-- <p>
            This will <b>delete</b> all pending votes, for architectural requests, made by architectural committee <b>{{
              primaryArchCommitteeUpdateModal.actual.name
            }}</b>.
          </p>

          <br /> -->

          <p>
            To update, please enter <b>{{ primaryArchCommitteeUpdateModal.selected.name }}</b> in
            the field below, and press <b>Update</b>.
          </p>

          <b-input
            primary-arch-modal-input
            v-model="primaryArchCommitteeUpdateModal.inputValue"
          ></b-input>
        </div>
      </div>
      <b-loading
        :is-full-page="false"
        v-model="primaryArchCommitteeUpdateModal.loading"
        :can-cancel="false"
      ></b-loading>

      <template v-slot:header>Update of Primary Architectural Committee</template>

      <template v-slot:footer>
        <b-button @click="primaryArchCommitteeUpdateModal.show = false">Close</b-button>

        <b-button
          primary-arch-modal-updater
          :style="{ float: 'right' }"
          type="is-danger"
          :disabled="!primaryArchCommitteeUpdateModal.buttonPressable"
          @click="updateArchButtonPressed"
          >Update</b-button
        >
      </template>
    </Modal>

    <Modal
      primary-board-committee-update-modal
      :toggle.sync="primaryBoardCommitteeUpdateModal.show"
      :styles="{
        footer: {
          padding: '20px'
        }
      }"
    >
      <div
        :style="{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          padding: '40px 0'
        }"
      >
        <b-icon type="is-danger" icon="alert-circle" size="is-large"> </b-icon>

        <div :style="{ padding: '0 1rem', fontSize: '20px', color: 'rgb(74, 74, 74)' }">
          <p
            v-if="
              primaryBoardCommitteeUpdateModal.actual.name ===
                primaryBoardCommitteeUpdateModal.selected.name
            "
          >
            Are you sure you would like to update the primary board of directors from
            <b>{{ primaryBoardCommitteeUpdateModal.actual.name }}</b> (id
            {{ primaryBoardCommitteeUpdateModal.actual.id }}) to
            <b>{{ primaryBoardCommitteeUpdateModal.selected.name }}</b> (id
            {{ primaryBoardCommitteeUpdateModal.selected.id }})?
          </p>
          <p v-else>
            Are you sure you would like to update the primary board of directors from
            <b>{{ primaryBoardCommitteeUpdateModal.actual.name }}</b> to
            <b>{{ primaryBoardCommitteeUpdateModal.selected.name }}</b
            >?
          </p>

          <br />

          <p>
            To update, please enter <b>{{ primaryBoardCommitteeUpdateModal.selected.name }}</b> in
            the field below, and press <b>Update</b>.
          </p>

          <b-input
            primary-board-modal-input
            v-model="primaryBoardCommitteeUpdateModal.inputValue"
          ></b-input>
        </div>
      </div>
      <b-loading
        :is-full-page="false"
        v-model="primaryBoardCommitteeUpdateModal.loading"
        :can-cancel="false"
      ></b-loading>

      <template v-slot:header>Update of Primary Board of Directors</template>

      <template v-slot:footer>
        <b-button @click="primaryBoardCommitteeUpdateModal.show = false">Close</b-button>

        <b-button
          primary-board-modal-updater
          :style="{ float: 'right' }"
          type="is-danger"
          :disabled="!primaryBoardCommitteeUpdateModal.buttonPressable"
          @click="updateBoardButtonPressed"
          >Update</b-button
        >
      </template>
    </Modal>
  </div>
</template>

<script>
/*
  plan:
    get list of all committees
    get committee settings
*/
import Modal from '@/components/Modal'
//
import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

export default {
  components: {
    Modal
  },

  data,
  watch,
  methods,

  beforeMount() {
    this.refresh()
    this.loading = false
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      const mType = mutation.type

      if (mType === 'committees/committeeList') {
        const committeeList = this.$store.getters['committees/committeeList']

        this.refresh({
          committeeList
        })
      }
    })
  },

  beforeDestroy() {
    this.unsubscribe()
  }
}
</script>
