<template>
  <div :style="{ display: 'flex' }">
    <Button text="Edit" :onClick="edit" />
    <Button text="Members" :onClick="members" :style="{ marginLeft: '8px' }" />
    <Button text="Delete" :onClick="erase" :style="{ marginLeft: '8px' }" delete-committee />
  </div>
</template>

<script>
import Button from '@/components/buttons/Button'

export default {
  components: {
    Button
  },
  props: {
    edit: Function,
    members: Function,
    erase: Function
  }
}
</script>
