/*
  import { Get as getUserDetails } from '@/services/AccountSettings/HoaAccountManagingUser/Get'

  const { details } = await getUserDetails ({
    hoaAccountManagingUserID
  });
*/

import kms from '@/services/kms'

export async function Get({ hoaAccountManagingUserID }) {
  try {
    let result = await kms.get(
      `/AccountSettings/HoaAccountManagingUser/${hoaAccountManagingUserID}`
    )

    return {
      successful: true,
      details: result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    details: null
  }
}
