/*
  import { committeeSettings } from '@/services/Committees/CommitteeSettings/store'

  this.unsubscribeCommitteeSettings = committeeSettings.subscribe(mutation => {
    const mType = mutation.type

    if (mType === 'updateList') {

    }
  })
*/

/*
  tests:
    import { prepareCommitteeSettings } from '@/services/Committees/CommitteeSettings/store'
    const committeeSettings = prepareCommitteeSettings ();
*/

import Vue from 'vue'
import Vuex from 'vuex'

import kms from '@/services/kms'
import _get from 'lodash/get'
//
import { notifyProblem } from '@/services/notify'

//
import { List } from './List'

const actions = {
  /* eslint-disable no-empty-pattern */

  /*
    sequence

      LIST ->
        [] ->
          Post [ architecturalCommitteeID ]

        One or more ->
          Entries after index 0 are ignored

          Put [
            committeeSettingID // <- of the entry at index 0

            architecturalCommitteeID
          ]
  */
  /*
    const { list } = await committeeSettings.dispatch ('setCommitteesAsPrimary', {
      hoaID,
      committeeSettingID,
      architecturalCommitteeID,
      boardCommitteeID
    })
  */
  async setCommitteesAsPrimary(
    { dispatch },
    { hoaID, committeeSettingID = null, architecturalCommitteeID = null, boardCommitteeID = null }
  ) {
    console.log('setArchCommitteeAsPrimary', {
      hoaID,
      architecturalCommitteeID,
      boardCommitteeID,
      committeeSettingID
    })

    const { list } = await dispatch('getList', {
      params: {
        hoaID
      }
    })

    if (Array.isArray(list)) {
      if (list.length === 0) {
        return await dispatch('post', {
          hoaID,
          //
          architecturalCommitteeID,
          boardCommitteeID
        })
      } else {
        return await dispatch('put', {
          hoaID,
          committeeSettingID,
          //
          architecturalCommitteeID,
          boardCommitteeID
        })
      }
    }
  },

  /*
    await committeeSettings.dispatch ('post', {
      hoaID: hoaId
    })
  */
  async post({}, { hoaID, architecturalCommitteeID = null, boardCommitteeID = null }) {
    try {
      await kms.post('/Committees/CommitteeSetting', {
        hoaID,
        ...(typeof architecturalCommitteeID === 'number' ? { architecturalCommitteeID } : {}),
        ...(typeof boardCommitteeID === 'number' ? { boardCommitteeID } : {})
      })
    } catch (exception) {
      notifyProblem(exception)
      return { successful: false }
    }

    return { successful: true }
  },

  /*
    Caution:
      if architecturalCommitteeID or boardCommitteeID isn't included,
      it will clear that committee id
  */
  async put(
    {},
    { hoaID, architecturalCommitteeID = null, boardCommitteeID = null, committeeSettingID = null }
  ) {
    try {
      await kms.put('/Committees/CommitteeSetting', {
        hoaID,
        committeeSettingID,
        ...(typeof architecturalCommitteeID === 'number' ? { architecturalCommitteeID } : {}),
        ...(typeof boardCommitteeID === 'number' ? { boardCommitteeID } : {})
      })
    } catch (exception) {
      notifyProblem(exception)
      return { successful: false }
    }

    return { successful: true }
  },

  /*
    const { list } = await committeeSettings.dispatch ('getList', {
      params: {
        hoaID: hoaId
      }
    })
  */
  async getList({ commit }, { params }) {
    let exception = null

    try {
      const { list } = await List({
        params
      })

      commit('updateList', {
        list,
        hoaId: params.hoaID
      })

      return {
        list,
        exception
      }
    } catch (exception) {
      notifyProblem(exception)
    }

    return {
      list: [],
      exception
    }
  }

  /* eslint-enable no-empty-pattern */
}

Vue.use(Vuex)

// exported for use in tests
export function prepareCommitteeSettings() {
  return new Vuex.Store({
    /*

    */
    state: {
      /*
        e.g. HOAs: {
          56: {
            architecturalCommitteeID
            boardCommitteeID
          }
        }
      */
      HOAs: {}
    },
    mutations: {
      updateList(state, { list, hoaId }) {
        const architecturalCommitteeID = _get(list, [0, 'architecturalCommitteeID'], null)
        const boardCommitteeID = _get(list, [0, 'boardCommitteeID'], null)

        state.HOAs[hoaId] = {
          architecturalCommitteeID,
          boardCommitteeID
        }
      }
    },
    getters: {},
    actions
  })
}

const store = prepareCommitteeSettings()

export const committeeSettings = store

////
