/*
  import { ResendInvitationUser } from '@/services/AccountSettings/InvitationUser/ResendInvitationUser'

  await ResendInvitationUser ({
    invitedUserId
  });
*/

import kms from '@/services/kms'
import _get from 'lodash/get'
//
import { notifyMessage, notifyProblem } from '@/services/notify'

export async function ResendInvitationUser({ invitationUserID }) {
  try {
    const results = await kms.post(
      `/AccountSettings/InvitationUser/ResendInvitationUser?invitationUserID=${invitationUserID}`
    )

    const resultsInvitationUserID = _get(results, 'invitationUserID', null)

    if (typeof resultsInvitationUserID === 'number' && resultsInvitationUserID > 0) {
      notifyMessage(`The invitation email was re-sent.`)
      return
    }
  } catch (exception) {
    console.error(exception)
  }

  notifyProblem('There was a problem re-sending the invitation email.')
}

////
