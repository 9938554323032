/*
  import { List as ListGroups } from '@/services/AccountSettings/Grouping/List'

  const { list } = await ListGroups ({
    hoaID
  });
*/

import kms from '@/services/kms'
import _get from 'lodash/get'

export async function List({ hoaID, accountManagingUserID }) {
  let params = {
    hoaID
  }

  if (typeof accountManagingUserID === 'number' && accountManagingUserID >= 1) {
    params.accountManagingUserID = accountManagingUserID
  }

  try {
    let result = await kms.get('/AccountSettings/Grouping/List', {
      params
    })

    return {
      successful: true,
      list: _get(result, 'results', [])
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    list: []
  }
}
