<template>
  <div class="section">
    <div class="box is-12">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
            <fieldset>
              <div class="form-grid">
                <div class="column is-12">&nbsp;</div>
                <div class="pl-4 pb-3 columns is-12">
                  <b-field vertical label="Term Start Date"
                    ><br />
                    <b-datepicker
                      placeholder="Select date..."
                      icon="calendar-today"
                      v-model="formData.termStartDate"
                      rules="required"
                      required
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="column is-12">&nbsp;</div>
                <div class="pl-4 pb-3 columns is-12">
                  <b-field vertical label="Term End Date"
                    ><br />
                    <b-datepicker
                      label="Term End Date"
                      placeholder="Select date..."
                      icon="calendar-today"
                      v-model="formData.termEndDate"
                      rules="required"
                      required
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="column is-12">
                  <valid-input
                    name="Name"
                    label="Name"
                    maxlength="450"
                    type="text"
                    vid="name"
                    placeholder="Name"
                    spellcheck="true"
                    aria-label="Name"
                    rules="required|max:450"
                    v-model="formData.name"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    name="Email"
                    label="Email"
                    maxlength="100"
                    type="email"
                    vid="email"
                    placeholder="Email"
                    spellcheck="true"
                    aria-label="Email"
                    rules="required|max:100"
                    v-model="formData.email"
                  />
                </div>
                <div
                  class="column is-12"
                  v-if="committeeTitleList && committeeTitleList != undefined"
                >
                  <valid-select
                    placeholder="Titles"
                    label="Titles"
                    vid="committeeTitleID"
                    v-model="formData.committeeTitleID"
                    title="Titles"
                    rules="required"
                  >
                    <option
                      v-for="option in committeeTitleList"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>

                <div
                  class="pt-5 column is-12"
                  v-if="hoaAccountManagingUserList && hoaAccountManagingUserList != undefined"
                >
                  <valid-select
                    placeholder="Account Users"
                    label="Account Users"
                    vid="hoaAccountManagingUserID"
                    v-model="formData.hoaAccountManagingUserID"
                    title="Account Users"
                    rules="required"
                  >
                    <option
                      v-for="option in hoaAccountManagingUserList.dropDown"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>

                <div class="pt-5 pb-5 column is-12">
                  Active:&nbsp;
                  <b-checkbox class="has-left-text" v-model="formData.isActive"></b-checkbox>
                </div>
                <div>&nbsp;</div>
                <div class="column is-12 float-right">
                  <button type="submit" class="button is-primary is-rounded" :disabled="loading">
                    Save
                  </button>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
//
import { ToastProgrammatic as Toast } from 'buefy'
import $ from 'jquery'
import _get from 'lodash/get'
import moment from 'moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters, mapState } from 'vuex'
//
import { ValidationError } from '@/services/errors'
//
// components
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

let DEFAULT_FORM_VALUES = {
  committeeID: 0,
  hoaAccountManagingUserID: 0,
  committeeTitleID: 0,
  isActive: true,
  emailAddress: '',
  termStartDate: null,
  termEndDate: null,
  name: '',
  email: ''
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'CommitteeMemberModal',

  props: {
    committeeID: Number,
    committee: Object
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('committees', ['committeeTitleList']),
      ...mapGetters('hoa', ['hoaAccountManagingUserList'])
    })
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    ValidSelect
  },

  mounted() {
    this.reload()
  },

  methods: {
    reload() {
      this.$store.dispatch('committees/getCommitteeTitleList', {
        committeeTypeID:
          this.committee && this.committee != undefined ? this.committee.committeeTypeID : 0
      })
      this.$store.dispatch('hoa/loadHoaAccountManagingUserList')
    },
    async onFormSubmit() {
      if (this.isDebug == true) console.debug(this.formData)
      if (this.formData && this.formData != undefined) {
        if (this.formData.termStartDate == null || this.formData.termEndDate == null) {
          notifyError('Please choose a start and end term date.')
          return
        }

        this.loading = true

        let emailAddress = _get(this, ['formData', 'email'], '')
        if (typeof emailAddress === 'string') {
          emailAddress = emailAddress.toLowerCase()
        }

        this.$store.dispatch('committees/addCommitteeMember', {
          payload: {
            hoaID: this.hoaId,
            hoaAccountManagingUserID: this.formData.hoaAccountManagingUserID,
            name: this.formData.name,
            emailAddress,
            committeeTitleID: this.formData.committeeTitleID,
            committeeID: this.committeeID,
            inactive: !this.formData.isActive,
            termStartDate: this.formData.termStartDate,
            termEndDate: this.formData.termEndDate
          },
          done: ex => {
            this.loading = false

            if (ex) {
              if (ex instanceof ValidationError) {
                this.$refs.form.setErrors(ex.fields)
              }

              notifyError(ex)

              return
            }

            // auto-close modal
            this.$emit('update:memberToggle', (this.memberToggle = false))
            $('#clickclose').click()
          }
        })
      } else {
        notifyError('Please check your selections prior to saving.')
      }
    }
  }
}
</script>

<style lang="scss">
.step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

.b-steps.is-small .steps {
  margin-top: 1rem;
}

.b-steps .steps + .step-content {
  padding-top: 0 !important;
}

.step-navigation {
  display: flex;
  justify-content: flex-end;
}

.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
