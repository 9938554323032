import { parkingItem } from '@/services/AccountSettings/ParkingSpaces/ParkingModal/store'
import { hoaUtility } from '@/services/Hoa/store'

import $ from 'jquery'
import { notifyError } from '@/services/notify'
import _get from 'lodash/get'
import debounce from 'lodash/debounce'
import parseAddress from '@/utilities/address/parse'

export const methods = {
  async reload() {
    this.loading = true

    this.getAddressDropDown()

    console.log('test=' + JSON.stringify(this.parking))

    if (this.parking && this.parking != undefined) {
      if (
        this.addressDropDownList &&
        this.addressDropDownList != undefined &&
        this.parking.unit &&
        this.parking.unit != undefined
      ) {
        this.addressFilterQuery = parseAddress({
          address: _get(this.parking, 'unit.address', '')
        })

        const address1 = this.parking.unit.address.addressOne
        const address2 = this.parking.unit.address.addressTwo
        const addressCity = this.parking.unit.address.city
        const addressState = this.parking.unit.address.state
        const addressPostal = this.parking.unit.address.postalCode

        const fullAddress = `${address1 ? address1 + ', ' : ''}${address2 ? address2 : ''}${
          addressCity ? addressCity : ''
        } ${addressState ? addressState : ''}  ${addressPostal ? addressPostal : ''}`

        this.addressFilterQuery = fullAddress ? fullAddress : ''
      }

      //Update
      this.buttonText = 'Save'

      this.formData.parkingSpaceID = this.parking.parkingSpaceID
      this.formData.spaceNumber = this.parking.spaceNumber
      this.formData.isCovered = this.parking.isCovered
      this.formData.note = this.parking.note
      this.formData.unitID = this.parking.unitID
      this.loading = false
    } else {
      this.buttonText = 'Add'
      this.loading = false
    }

    this.loading = false
  },

  async getAddressDropDown() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    await hoaUtility
      .dispatch('loadDropDownAddressList', {
        hoaID: hoaID
      })
      .then(({ list }) => {
        if (list) {
          if (this.isDebug == true) console.debug('addressDropDownList=' + JSON.stringify(list))

          this.addressData = list
          this.addressDropDownList = list
        }
      })
  },

  open() {
    this.formData = {
      spaceNumber: '',
      isCovered: false,
      note: '',
      unitID: 0
    }
    this.isOpen = true
    this.loading = false
  },

  async submitParking() {
    if (this.formData && this.formData != undefined) {
      if (this.formData.parkingSpaceID && this.formData.parkingSpaceID > 0) {
        await this.updateParking()
      } else {
        await this.addParking()
      }
    }
  },

  async addParking() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem processing this parking space.')
      return
    }

    this.loading = true
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const address = this.addressDropDownList.filter(i => i.label.includes(this.addressFilterQuery))
    if (address && address != undefined) {
      this.formData.unitID = address && address[0] && address[0].unitID ? address[0].unitID : 0
    }

    await parkingItem.dispatch('addParking', {
      hoaID,
      spaceNumber: this.formData.spaceNumber ? this.formData.spaceNumber : '',
      isCovered: this.formData.isCovered ? this.formData.isCovered : false,
      note: this.formData.note ? this.formData.note : '',
      unitID: this.formData.unitID && this.formData.unitID > 0 ? this.formData.unitID : null,
      done: () => {
        // auto-close modal
        this.$emit('update:toggle', (this.toggle = false))
        $('#clickclose').click()
        this.loading = false
      }
    })
  },

  async updateParking() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem updating this garage.')
      return
    }

    this.loading = true
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    const filterQuery = this.addressFilterQuery
    const address = this.addressDropDownList.filter(i => i.label.includes(filterQuery))
    if (address && address != undefined) {
      this.formData.unitID = address && address[0] && address[0].unitID ? address[0].unitID : 0
    }

    const payload = {
      parkingSpaceID: this.formData.parkingSpaceID,
      hoaID: hoaID,
      spaceNumber: this.formData.spaceNumber ? this.formData.spaceNumber : '',
      isCovered: this.formData.isCovered ? this.formData.isCovered : false,
      note: this.formData.note ? this.formData.note : '',
      unitID: this.formData.unitID && this.formData.unitID > 0 ? this.formData.unitID : null
    }

    await parkingItem.dispatch('updateParking', {
      payload: payload,
      done: () => {
        // auto-close modal
        this.$emit('update:toggle', (this.toggle = false))
        $('#clickclose').click()
        this.loading = false
      }
    })
  },

  getAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.addressData = []
    }

    // String cleared
    if (!name.length) {
      this.addressData = []
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.addressDropDownList && this.addressDropDownList) {
      this.addressData = this.addressDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching = false
  }, 500),
  getMoreAsyncData: debounce(function() {
    this.getAsyncData(this.name)
  }, 250)
}
