/*
  import { addGroup } from '@/services/AccountSettings/Grouping/Post'

  await addGroup ({
    json: {
    	hoaID,
    	name: "Group",
      description: "Group Description"
    }
  });
*/

import kms from '@/services/kms'
import { notifyProblem } from '@/services/notify'

export async function addGroup({ json }) {
  try {
    await kms.post('/AccountSettings/Grouping', json)

    return {
      successful: true
    }
  } catch (exception) {
    console.error(exception)
  }

  notifyProblem('A problem occurred and the group could not be added.')

  return {
    successful: false
  }
}
