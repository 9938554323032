import debounce from 'lodash/debounce'
import { ValidationError } from '@/services/errors'
import { mapActions } from 'vuex'
import { ToastProgrammatic as Toast } from 'buefy'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

let DEFAULT_FORM_VALUES = {
  minimumApprovers: 0,
  initialPendingDays: 0,
  continuingPendingDays: 0
}

export const methods = {
  ...mapActions('user', ['selectHoaId']),

  async reload() {
    this.loading = true

    this.formData = { ...DEFAULT_FORM_VALUES }

    this.formData.minimumApprovers = 0

    if (this.$refs.form) {
      this.$refs.form.reset()
    }

    this.getSettingById().then(() => {
      this.getGlExpenseDropDown()
    })

    this.getBankAccountDropDown()

    this.getAddressDropDown()

    this.loading = false
  },

  getGlExpenseDropDown() {
    this.$store.dispatch('accounting/getGlExpenseList').then(() => {
      if (this.glExpenseDropDownList && this.glExpenseDropDownList != undefined) {
        if (this.isDebug == true)
          console.debug('glExpenseDropDownList=' + JSON.stringify(this.glExpenseDropDownList))

        this.data = this.glExpenseDropDownList
        this.data3 = this.glExpenseDropDownList

        if (
          this.apSetting &&
          this.apSetting != undefined &&
          this.apSetting.glExpenseAccount &&
          this.apSetting.glExpenseAccount != undefined &&
          this.apSetting.glAccountsPayableAccount &&
          this.apSetting.glAccountsPayableAccount != undefined
        ) {
          this.glExpenseFilterQuery = this.apSetting.glAccountsPayableAccount
            ? this.apSetting.glAccountsPayableAccount.accountNumber +
              '-' +
              this.apSetting.glAccountsPayableAccount.subAccountNumber.toString().padStart(3, '0') +
              ' - ' +
              this.apSetting.glAccountsPayableAccount.description
            : ''

          this.subscriptionGlFilterQuery = this.apSetting.glExpenseAccount
            ? this.apSetting.glExpenseAccount.accountNumber +
              '-' +
              this.apSetting.glExpenseAccount.subAccountNumber.toString().padStart(3, '0') +
              ' - ' +
              this.apSetting.glExpenseAccount.description
            : ''
        } else {
          this.formData = { ...DEFAULT_FORM_VALUES }
          this.$refs.form.reset()
        }
      }
    })
  },

  getBankAccountDropDown() {
    this.$store.dispatch('bankaccount/loadBankAccountCheckingList').then(() => {
      if (this.checkingBankAccounts && this.checkingBankAccounts != undefined) {
        if (this.isDebug == true)
          console.debug('checkingBankAccounts=' + JSON.stringify(this.checkingBankAccounts))

        this.data2 = this.checkingBankAccounts

        if (
          this.apSetting &&
          this.apSetting != undefined &&
          this.apSetting.bankAccount &&
          this.apSetting.bankAccount != undefined
        ) {
          this.bankAccountFilterQuery = this.apSetting.bankAccount.name
            ? this.apSetting.bankAccount.name
            : ''
        }
      }
    })
  },

  getAddressDropDown() {
    this.$store.dispatch('hoa/loadDropDownAddressList').then(() => {
      if (
        this.addressDropDownList &&
        this.addressDropDownList != undefined &&
        this.addressDropDownList.dropDownAddresses &&
        this.addressDropDownList.dropDownAddresses != undefined
      ) {
        if (this.isDebug == true)
          console.debug(
            'addressDropDownList=' + JSON.stringify(this.addressDropDownList.dropDownAddresses)
          )

        if (
          this.apSetting &&
          this.apSetting != undefined &&
          this.apSetting.subscriptionBillingAddress &&
          this.apSetting.subscriptionBillingAddress != undefined
        ) {
          this.addressFilterQuery = this.apSetting.subscriptionBillingAddress
            ? this.apSetting.subscriptionBillingAddress.addressOne +
              ', ' +
              this.apSetting.subscriptionBillingAddress.city +
              ' ' +
              this.apSetting.subscriptionBillingAddress.state +
              '  ' +
              this.apSetting.subscriptionBillingAddress.postalCode
            : ''
        }

        this.data4 = this.addressDropDownList.dropDownAddresses
      }
    })
  },

  async getSettingById() {
    try {
      this.$store.dispatch('accounting/getApSettingById').then(() => {
        if (this.apSetting && this.apSetting != undefined && this.formData) {
          if (this.isDebug == true) console.debug('apSetting=' + JSON.stringify(this.apSetting))

          this.formData.minimumApprovers =
            this.apSetting && this.apSetting != undefined
              ? this.apSetting.minimumApproversForApproval
              : 0
          this.formData.initialPendingDays =
            this.apSetting && this.apSetting != undefined
              ? this.apSetting.initialOldInvoiceNotificationDays
              : 0
          this.formData.continuingPendingDays =
            this.apSetting && this.apSetting != undefined
              ? this.apSetting.continuingOldInvoiceNotificationDays
              : 0
          this.formData.AutoApproved = this.apSetting.autoApproved
          this.formData.thresholdAmount =
            this.apSetting && this.apSetting != undefined
              ? this.apSetting.subscriptionAutoPaymentThresholdAmount
              : 0
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug('debug=' + e)
    }
  },
  async onFormSettingSubmit() {
    try {
      if (!this.formData || this.formData == undefined) {
        notifyError('There was a problem saving your A/P settings.')
        return
      }

      this.loading = true

      const apGlAccountId = this.glExpenseDropDownList.filter(i =>
        i.label.includes(this.glExpenseFilterQuery)
      )
      const bankAccountId = this.checkingBankAccounts.filter(i =>
        i.label.includes(this.bankAccountFilterQuery)
      )
      const glExpenseAccountId = this.glExpenseDropDownList.filter(i =>
        i.label.includes(this.subscriptionGlFilterQuery)
      )
      const billingAddressId = this.addressDropDownList.dropDownAddresses.filter(i =>
        i.label.includes(this.addressFilterQuery)
      )

      const payload = {
        apSettingID:
          this.apSetting &&
          this.apSetting != undefined &&
          this.apSetting.apSettingID &&
          this.apSetting.apSettingID != undefined
            ? this.apSetting.apSettingID
            : 0,
        hoaID: this.hoaId,
        minimumApproversForApproval:
          this.formData.minimumApprovers && this.formData.minimumApprovers != undefined
            ? this.formData.minimumApprovers
            : 0,
        accountsPayableGlAccountID: apGlAccountId[0] ? apGlAccountId[0].value : 0,
        subscriptionPaymentBankAccountID: bankAccountId[0] ? bankAccountId[0].value : 0,
        subscriptionExpenseGLAccountID: glExpenseAccountId[0] ? glExpenseAccountId[0].value : 0,
        subscriptionBillingAddressID: billingAddressId[0] ? billingAddressId[0].value : 0,
        initialOldInvoiceNotificationDays: this.formData.initialPendingDays,
        continuingOldInvoiceNotificationDays: this.formData.continuingPendingDays,
        autoApproved: this.formData.AutoApproved,
        subscriptionAutoPaymentThresholdAmount: this.formData.thresholdAmount
      }

      if (this.isDebug == true) console.debug('formdata payload' + JSON.stringify(this.payload))

      try {
        if (payload.apSettingID > 0) {
          this.$store.dispatch('accounting/updateApSetting', {
            payload: payload,
            done: () => {
              this.reload()
              this.loading = false
            }
          })
        } else {
          this.$store.dispatch('accounting/addApSetting', {
            payload: payload,
            done: () => {
              this.reload()
              this.loading = false
            }
          })
        }

        this.loading = false
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    } catch (e) {
      //If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      } else {
        notifyError(e)
      }
    }

    this.loading = false
  },

  getGlAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.data = []
    }

    // String cleared
    if (!name.length) {
      this.data = []
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.glExpenseDropDownList) {
      this.data = this.glExpenseDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching = false
  }, 500),
  getMoreGlAsyncData: debounce(function() {
    this.getGlAsyncData(this.name)
  }, 250),
  getBankAccountAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.data2 = []
    }

    // String cleared
    if (!name.length) {
      this.data2 = []
      return
    }

    this.isFetching2 = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.checkingBankAccounts) {
      this.data2 = this.checkingBankAccounts.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching2 = false
  }, 500),
  getMoreBankAccountAsyncData: debounce(function() {
    this.getBankAccountAsyncData(this.name)
  }, 250),
  getSubscriptionGlAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.data3 = []
    }

    // String cleared
    if (!name.length) {
      this.data3 = []
      return
    }

    this.isFetching3 = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.glExpenseDropDownList) {
      this.data3 = this.glExpenseDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching3 = false
  }, 500),
  getMoreSubscriptionGlAsyncData: debounce(function() {
    this.getSubscriptionGlAsyncData(this.name)
  }, 250),
  getAddressAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.data4 = []
    }

    // String cleared
    if (!name.length) {
      this.data4 = []
      return
    }

    this.isFetching4 = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.addressDropDownList.dropDownAddresses) {
      this.data4 = this.addressDropDownList.dropDownAddresses.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching4 = false
  }, 500),
  getMoreAddressAsyncData: debounce(function() {
    this.getAddressAsyncData(this.name)
  }, 250)
}
