<template>
  <div garage-modal>
    <div class="is-size-4" v-if="formData">{{ buttonText }} Garage</div>
    <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(submitGarage)">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <valid-input
                    v-model="formData.garageNumber"
                    label="Garage Number"
                    name="garageNumber"
                    type="text"
                    vid="garageNumber"
                    placeholder="Garage Number"
                    rules="required|max:10"
                  />
                </div>
                <div class="column is-12">
                  <span class="column is-4" style="white-space: nowrap">
                    <b-field label="Vehicle Capacity">
                      <b-numberinput
                        name="VehicleCapacity"
                        vid="vehicleCapacity"
                        v-model="formData.vehicleCapacity"
                        required
                      />
                    </b-field>
                  </span>
                </div>
                <div class="column is-12">
                  <span class="column is-4" style="white-space: nowrap">
                    <b-field label="Square Feet">
                      <b-numberinput
                        name="squareFeet"
                        vid="squareFeet"
                        v-model="formData.squareFeet"
                        required
                      />
                    </b-field>
                  </span>
                </div>
                <div class="column is-6">
                  <label class="label">Select Location / Unit</label>
                  <b-autocomplete
                    size="is-small"
                    field="label"
                    icon="magnify"
                    :loading="isFetching"
                    placeholder="e.g. Location / Unit Search"
                    :data="addressData"
                    :open-on-focus="openOnFocus"
                    v-model="addressFilterQuery"
                    @typing="getAsyncData"
                    @select="option => (selected = option)"
                  >
                  </b-autocomplete>
                </div>
                <div class="pt-5">
                  <button
                    type="submit"
                    tests-id="budgetbuilder-modal-button"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                  >
                    {{ buttonText }}
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  props: {
    garage: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    }),
    addressFilterSuggestions() {
      if (
        this.addressFilterQuery &&
        this.addressDropDownList &&
        this.addressDropDownList != undefined
      ) {
        return this.addressDropDownList.filter(
          a => a.label.indexOf(this.addressFilterQuery.toLowerCase()) !== -1
        )
      } else {
        return this.addressDropDownList
      }
    }
  },

  data,
  methods
}
</script>
