import { mapState, mapGetters } from 'vuex'

export const computed = {
  ...mapState({
    hoaId: state => state.user.selectedHoaId
  }),
  ...mapGetters('subscription', [
    'subscription',
    'currentMonthlyBillAmount',
    'hoaFeatures',
    'hoaAddOnFeatures',
    'apSetting',
    'subscriptionList'
  ]),

  columns: function() {
    let columns = []
    let subFeatureCount = 0
    Array.from(this.hoaFeatures).forEach(element => {
      if (element && element != undefined) {
        if (
          element.feature &&
          element.feature != undefined &&
          element.feature.subFeatures &&
          element.feature.subFeatures != null
        ) {
          subFeatureCount += element.feature.subFeatures.length
        }
      }
    })

    let totalLength = Object.keys(this.hoaFeatures).length + subFeatureCount

    let mid = Math.ceil(totalLength / this.cols)

    for (let col = 0; col < this.cols; col++) {
      columns.push(
        Object.entries(this.hoaFeatures)
          .slice(col * mid, col * mid + mid)
          .map(entry => entry[1])
      )
    }
    return columns
  },
  addOnColumns: function() {
    let addOnColumns = []
    let subFeatureAddOnCount = 0

    try {
      if (
        this.hoaAddOnFeatures &&
        this.hoaAddOnFeatures != undefined &&
        this.hoaAddOnFeatures.results &&
        this.hoaAddOnFeatures.results != undefined
      ) {
        Array.from(this.hoaAddOnFeatures.results).forEach(element => {
          if (element && element != undefined) {
            if (
              element.feature &&
              element.feature != undefined &&
              element.feature.subFeatures &&
              element.feature.subFeatures != null
            ) {
              subFeatureAddOnCount += element.feature.subFeatures.length
            }
          }
        })
      }

      let totalAddOnLength = 0
      if (
        this.hoaAddOnFeatures &&
        this.hoaAddOnFeatures != undefined &&
        this.hoaAddOnFeatures.results &&
        this.hoaAddOnFeatures.results != undefined &&
        subFeatureAddOnCount &&
        subFeatureAddOnCount != undefined
      ) {
        totalAddOnLength = Object.keys(this.hoaAddOnFeatures.results).length + subFeatureAddOnCount
      }

      let midAddOn = Math.ceil(totalAddOnLength / this.cols)

      for (let col = 0; col < this.cols; col++) {
        if (addOnColumns && addOnColumns != undefined) {
          addOnColumns.push(
            Object.entries(this.hoaAddOnFeatures.results)
              .slice(col * midAddOn, col * midAddOn + midAddOn)
              .map(entry => entry[1])
          )
        }
      }
    } catch (e) {
      if (this.isDebug == true) console.debug('e=' + e)
    }
    return addOnColumns
  }
}
