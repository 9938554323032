export const watch = {
  primaryArchCommitteeUpdateModal: {
    deep: true,
    handler(current) {
      if (current.inputValue === current.selected.name) {
        this.primaryArchCommitteeUpdateModal.buttonPressable = true
      } else {
        this.primaryArchCommitteeUpdateModal.buttonPressable = false
      }
    }
  },

  primaryBoardCommitteeUpdateModal: {
    deep: true,
    handler(current) {
      if (current.inputValue === current.selected.name) {
        this.primaryBoardCommitteeUpdateModal.buttonPressable = true
      } else {
        this.primaryBoardCommitteeUpdateModal.buttonPressable = false
      }
    }
  }
}
