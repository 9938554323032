/*
  import { List as ListCommitteeSettings } from '@/services/Committees/CommitteeSettings/List'

  const { list } = await ListCommitteeSettings ({
    params: {
      hoaID: userStore.state.selectedHoaId
    }
  });
*/

import kms from '@/services/kms'

export async function List({ params }) {
  const { results } = await kms.get('/Committees/CommitteeSetting/List', {
    params
  })

  return {
    list: results
  }
}
