import moment from 'moment'

export function data() {
  return {
    refreshing: false,
    isDebug: true,
    confirmModal: false,
    formatDateFn: function(value) {
      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
    },
    formatFn: function(value) {
      return value != null ? value : ''
    },
    formatNumberFn: function(value) {
      return value != null ? value : 0
    },
    selectedRow: null,
    selectedID: null,
    selected: '',
    action: '',
    activeTab: 'overview',
    cols: 2,
    isManageSubscription: false,
    shouldRefresh: false,

    styles: {
      pageContentSection: {
        width: '100%'
      }
    }
  }
}
