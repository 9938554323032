import moment from 'moment'

let DEFAULT_FORM_VALUES_INIT = {
  minimumApprovers: 0,
  initialPendingDays: 0,
  continuingPendingDays: 0
}

export const data = () => ({
  formComplete: false,
  formData: {
    ...DEFAULT_FORM_VALUES_INIT
  },
  loading: true,
  isDebug: true,
  formatDateFn: function(value) {
    return value && value != undefined
      ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
          .format('MM-DD-YYYY')
          .toString()
      : ''
  },
  checkNumberFn: function(value) {
    return value && value != undefined && value > 0 ? value : 0
  },
  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    }
  },
  openOnFocus: true,
  isFetching: false,
  isFetching2: false,
  isFetching3: false,
  isFetching4: false,
  data: [],
  data2: [],
  data3: [],
  data4: [],
  glExpenseFilterQuery: null,
  bankAccountFilterQuery: null,
  subscriptionGlFilterQuery: null,
  addressFilterQuery: null,
  showWeekNumber: false,
  isAutoApproved: false
})
