export function data() {
  const PRESET_FORM_VALUES = {
    hoaAccountManagingUserID: null,

    termStartDate: null,
    termEndDate: null,

    active: true,

    name: '',
    email: ''
  }

  const PRESET_FORM_ERRORS = {
    termStartDate: null,
    termEndDate: null
  }

  return {
    isOpen: false,
    formData: {
      ...PRESET_FORM_VALUES
    },
    formErrors: {},

    PRESET_FORM_VALUES,
    PRESET_FORM_ERRORS,

    hoaAccountManagingUserID: null,

    hoaAccountManagingUserList: [],
    committeeTitleList: []
  }
}
