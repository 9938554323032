import _get from 'lodash/get'
//
import { groupingStore } from '@/services/AccountSettings/Grouping/store'

export const methods = {
  determineRows() {
    const { groupsList } = this

    this.rows = groupsList.map(entry => {
      return {
        _meta: {
          groupData: entry
        },
        name: _get(entry, 'name', ''),
        roles: _get(entry, 'roles', '')
      }
    })
  },
  async init() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const { list } = await groupingStore.dispatch('list', {
      hoaID
    })

    this.groupsList = list

    this.determineRows()
  },
  rowOnClick({ row }) {
    const groupData = _get(row, ['_meta', 'groupData'], {})

    this.showGroupEditModal({
      formData: {
        groupingID: _get(groupData, 'groupingID', null),
        //
        adminPermissions: _get(groupData, 'adminPermissions', null),
        readPermissions: _get(groupData, 'readPermissions', null),
        //
        name: _get(groupData, 'name', null),
        description: _get(groupData, 'description', null)
      }
    })
  }
}
