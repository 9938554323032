var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContent',[_c('div',{staticClass:"box tile is-vertical is-12",style:(Object.assign({}, {minHeight: '70vh'},
      (_vm.breakpoint === 'desktop'
        ? {}
        : {
            padding: '20px 0'
          })))},[_c('div',{staticClass:"level"},[_c('span',{staticClass:"title pt-2 pl-2"},[_vm._v("Account Settings")])]),_c('LinksMenu',{style:({
        paddingLeft: 0
      }),attrs:{"aria-label":"account settings tabs","tabs":_vm.tabs,"prefix":"accountSettings."}},[_c('section',{style:({ overflowX: 'auto', flexGrow: 2 })},[_c('SlideTransition',{attrs:{"name":_vm.transitionName}},[_c('keep-alive',[_c('router-view',{key:_vm.$route.name})],1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }