export function data() {
  return {
    formData: {
      name: '',
      description: ''
    },
    isOpen: false,
    loading: true
  }
}
