var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContentLayoutOnly',{attrs:{"role":"region","aria-label":_vm.$t('accessManagement.title')}},[_c('div',{ref:"tabsFoundation",staticClass:"box is-8"},[_c('div',{style:({
        borderBottom: '1px solid ' + _vm.palette[2],
        marginBottom: '20px'
      })},[_c('router-link',{style:(Object.assign({}, _vm.styles.tab, Object.assign({}, (_vm.showUsers === true
              ? {
                  background: _vm.palette[1],
                  borderRight: '1px solid ' + _vm.palette[2],
                  borderLeft: '1px solid ' + _vm.palette[2],
                  borderTop: '1px solid ' + _vm.palette[2]
                }
              : {})))),attrs:{"users-tab":"","to":{
          name: 'accountSettings.accessManagement.users'
        }}},[_vm._v("Users")]),_c('router-link',{style:(Object.assign({}, _vm.styles.tab, Object.assign({}, (_vm.showGroups === true
              ? {
                  background: _vm.palette[1],
                  borderRight: '1px solid ' + _vm.palette[2],
                  borderLeft: '1px solid ' + _vm.palette[2],
                  borderTop: '1px solid ' + _vm.palette[2]
                }
              : {})))),attrs:{"groups-tab":"","to":{
          name: 'accountSettings.accessManagement.groups'
        }}},[_vm._v("Groups ")])],1),_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[(_vm.showUsers)?_c('div',[_c('div',{staticClass:"nowrap levels"},[_c('div',{staticClass:"level-right"},[(_vm.isOwner === true)?_c('b-button',{staticClass:"is-primary",attrs:{"invite-team-member-button":"","label":"Invite Team Member"},on:{"click":function($event){$event.preventDefault();return _vm.showMemberInvitationModal.apply(null, arguments)}}}):_vm._e(),(_vm.isOwner !== true)?_c('b-tooltip',{attrs:{"label":"Account Owner permission is necessary to send invites","position":"is-left"}},[_c('b-button',{attrs:{"disabled":"","type":"is-primary","label":"Invite Team Member"}})],1):_vm._e()],1)]),_c('div',{staticClass:"column is-12 cursor-pointer"},[_c('UsersTable',{attrs:{"showEditModal":_vm.showEditModal,"isOwner":_vm.isOwner}})],1)]):_vm._e()]),_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[(_vm.showGroups)?_c('div',[_c('div',{staticClass:"nowrap levels"},[_c('div',{staticClass:"level-right"},[(_vm.isOwner === true)?_c('b-button',{attrs:{"type":"is-primary","label":"Add Group","add-group-button":""},on:{"click":function($event){$event.preventDefault();return _vm.openAddGroupModal.apply(null, arguments)}}}):_vm._e(),(_vm.isOwner !== true)?_c('b-tooltip',{attrs:{"label":"Account Owner permission is necessary to add groups","position":"is-left"}},[_c('b-button',{attrs:{"disabled":"","type":"is-primary","label":"Add Group"}})],1):_vm._e()],1)]),_c('div',{staticClass:"column is-12 cursor-pointer"},[_c('GroupsTable',{attrs:{"showGroupEditModal":_vm.showGroupEditModal}})],1)]):_vm._e()])],1),_c('MemberInvitationModal',{ref:"memberInvitationModal",attrs:{"inviteWasSent":_vm.memberInviteWasSent}}),_c('MemberEditorModal',{ref:"MemberEditorModal",attrs:{"formData":_vm.memberEditorFormData,"isOwner":_vm.isOwner}}),_c('GroupAdderModal',{ref:"groupAdderModal"}),_c('GroupEditorModal',{ref:"groupEditorModal",attrs:{"isOwner":_vm.isOwner}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }