<template>
  <div>
    <div class="container list-content">
      <ModernTable
        :loading="loading"
        :columns="columns"
        :rows="rows"
        :pagination="{
          perPage: 10
        }"
        :sort="{
          by: 'date',
          direction: 1,
          date: true
        }"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
//
import ModernTable from '@/components/tables/Modern/Table.vue'
//
import { methods } from './keys/methods'

export default {
  name: 'SubscriptionReceiptList',
  components: { ModernTable },
  data: () => ({
    loading: false,
    isDebug: true,
    selectedRow: null,
    formatDateFn: function(value) {
      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
    },
    columns: [
      {
        field: 'date',
        date: true,

        label: 'Date',
        width: '25%',
        sortable: true,
        searchable: true
      },
      {
        field: 'amount',
        prefix: '$',
        numeric: true,

        label: 'Amount',
        width: '20%',
        sortable: true,
        searchable: true
      },
      {
        field: 'status',
        label: 'Status',
        width: '20%',
        sortable: true,
        searchable: true
      },
      {
        field: 'receipt',
        label: 'Receipt',
        width: '15%'
      }
    ],
    rows: []
  }),

  mounted() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'subscription/subscriptionReceipts') {
        this.loading = true
        this.determineRows()
        this.loading = false
      }
    })

    this.$store.dispatch('subscription/getSubscriptionReceiptList')
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  methods
}
</script>

<style></style>
