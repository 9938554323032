import _get from 'lodash/get'
//
import { notifyProblem } from '@/services/notify'
import { accessManagementStore } from '@/pages/Management/accountSettings/AccessManagement/store'
//
//
export const methods = {
  async init() {
    const accountManagingUserID = _get(this, ['authUser', 'accountManagingUserID'], null)
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const { details } = await accessManagementStore.dispatch('getAccountDetails', {
      hoaID,
      accountManagingUserID
    })

    const isOwner = _get(details, 'isOwner', null)
    if (typeof isOwner === 'boolean') {
      this.isOwner = isOwner
    }

    if (isOwner === true) {
      this.canInvite = true
    }
  },

  showMemberInvitationModal() {
    this.$refs.memberInvitationModal.open()
  },

  showEditModal({ selection }) {
    const user = _get(selection, ['_meta', 'user'], null)

    const isOwner = _get(user, 'isOwner', null)

    let preventDemotion = false
    const authAccountManagingUserID = _get(
      this,
      ['$store', 'state', 'user', 'authUser', 'accountManagingUserID'],
      null
    )
    const accountManagingUserID = _get(user, 'accountManagingUserID', '')

    if (isOwner && authAccountManagingUserID === accountManagingUserID) {
      preventDemotion = true
    }

    try {
      this.$refs.MemberEditorModal.open({
        preventDemotion,
        accountManagingUserID,
        formData: {
          aspNetUserEmail: _get(user, 'aspNetUserEmail', ''),
          accountManagingUserID: _get(user, 'accountManagingUserID', ''),
          name: _get(user, 'name', ''),
          isOwner: _get(user, 'isOwner', null),
          adminPermissions: _get(user, 'adminPermissions', null),
          readPermissions: _get(user, 'readPermissions', null)
        }
      })
    } catch (exception) {
      console.error(exception)
      notifyProblem('A problem occurred and the member editor modal could not be opened.')
    }
  },

  showMemberEditorEditMode() {
    this.$refs.MemberEditor.showEditMode()
  },
  memberInviteWasSent() {
    this.memberInvitationToggle = false
  },

  openAddGroupModal() {
    this.$refs.groupAdderModal.open()
  },
  showGroupEditModal({ formData }) {
    this.$refs.groupEditorModal.open({
      formData
    })
  },

  determineActive() {
    const fullPath = _get(this, ['$route', 'fullPath'], '')

    if (
      typeof fullPath === 'string' &&
      fullPath.indexOf('/accountSettings/accessManagement/groups') === 0
    ) {
      this.showGroups = true
      this.showUsers = false
    } else {
      this.showGroups = false
      this.showUsers = true
    }
  }
}
