import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import parseAddress from '@/utilities/address/parse'
import { parkingList } from '@/services/AccountSettings/ParkingSpaces/List.js'

export const methods = {
  confirmDeleteParking(id, name) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Parking Space',
      message: `Are you sure you want to <b>delete</b> the Parking Space for "${name}"?`,
      confirmText: 'Delete Parking Space',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteParking(id)
    })
  },

  determineRows: function(list) {
    var rows = list.map(entry => {
      const spaceNumber = _get(entry, 'spaceNumber', '')
      const isCoveredTemp = _get(entry, 'isCovered', false)
      const note = _get(entry, 'note', '')
      const parkingSpaceID = _get(entry, 'parkingSpaceID', 0)

      return {
        spaceNumber: spaceNumber,
        isCovered: isCoveredTemp == true ? 'Yes' : 'No',
        note: note,
        unit: parseAddress({
          address: _get(entry, 'unit.address', '')
        }),
        edit: {
          component: Button,
          props: {
            onClick: () => {
              this.processParkingUpdate(entry)
            },
            text: 'Edit'
          }
        },
        erase: {
          component: Button,
          props: {
            onClick: () => {
              this.confirmDeleteParking(parkingSpaceID, spaceNumber)
            },
            text: 'Delete'
          }
        }
      }
    })

    this.rows = rows
  },
  async reload() {
    if (this.isDebug == true) console.debug('in reload...')
    this.loading = true

    await parkingList
      .dispatch('getParkingList', {
        hoaId: this.hoaId
      })
      .then(({ list }) => {
        this.determineRows(list)
        this.loading = false
      })
  },

  processParkingUpdate(parking) {
    this.toggle = true
    this.selectedParking = parking
  },

  createParkingModal() {
    this.toggle = true
    this.selectedParking = null
  },

  async deleteParking(id) {
    await parkingList.dispatch('deleteParking', {
      parkingSpaceID: id,
      done: () => {
        this.loading = false
        this.reload()
      }
    })
  }
}
