export function data() {
  return {
    isOpen: false,

    preventDemotion: false,
    accountManagingUserID: null,

    disableEdit: false,

    mode: 'summary', // 'edit'

    formData: {
      aspNetUserEmail: '',
      accountManagingUserID: '',
      name: '',
      isOwner: '',
      adminPermissions: '',
      readPermissions: ''
    },

    loading: false
  }
}
