import _get from 'lodash/get'
import { groupingStore } from '@/services/AccountSettings/Grouping/store'

export function mounted() {
  this.unsubscribeGroupingStore = groupingStore.subscribe((mutation, state) => {
    const mType = mutation.type
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    if (mType === 'groupsList') {
      this.groupsList = _get(state, ['HOAs', hoaID, 'groupsList'], [])
      this.determineRows()
    }
  })

  this.init()
}
