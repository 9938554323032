export function data() {
  return {
    mode: 'summary',
    disableEdit: false,
    formData: {
      groupingID: '',
      name: '',
      description: '',
      adminPermissions: '',
      readPermissions: ''
    },
    isOpen: false,
    loading: true,

    groupRoles: [],

    // first index is 1
    // e.g.
    // [ 1, 2, 3, 4, 5, 6 ]
    groupPermissions: [],

    possibleRoles: []
  }
}
