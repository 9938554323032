<template>
  <div v-if="isManageSubscription == false">
    <PageContentLayoutOnly
      class="subtitle"
      :sectionStyles="styles.pageContentSection"
      role="region"
      :aria-label="$t('subscription.title')"
    >
      <div class="subtitle" v-if="subscription && subscription != undefined">
        {{ subscription.name }}
      </div>
      <div class="has-text-right pb-5" v-show="false">
        <b-button
          class="is-primary"
          label="Manage Subscription"
          @click.prevent="isManageSubscription = true"
        />
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-vertical is-12">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              <article class="tile is-child notification rounded-tile">
                <div>Renews On</div>
                <div class="subtitle"></div>
                <div class="href-overflow-wrap">
                  <a href="#" @click.prevent="tabClicked('receipts')">View Receipts</a>
                </div>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child notification rounded-tile">
                <div>Current Monthly Bill</div>
                <div class="subtitle font-bold">{{ currentMonthlyBillAmount | currency }}</div>
                <div class="href-overflow-wrap">
                  <a href="#" @click.prevent="tabClicked('billing')">Update Billing Details</a>
                </div>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child notification rounded-tile">
                <div>Bank Account</div>
                <div
                  class="subtitle font-bold"
                  v-if="
                    apSetting &&
                      apSetting != undefined &&
                      apSetting.bankAccount &&
                      apSetting.bankAccount != undefined
                  "
                >
                  {{ apSetting.bankAccount.name }}
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div class="box is-12">
        <b-tabs v-model="activeTab" position="is-left" type="is-boxed" class="block whitecolor">
          <b-tab-item class="rounded-tile" key="overview" value="overview" label="Overview">
            <div
              v-if="
                subscription && subscription != undefined && hoaFeatures && hoaFeatures != undefined
              "
            >
              <div class="p-2">
                <div class="subtitle">{{ subscription.name }} Plan - Standard Features</div>
              </div>

              <div class="container" v-if="columns && columns != undefined" style="display: flex;">
                <div class="col" v-for="(column, index) in columns" :key="index">
                  <div
                    class="item-container"
                    v-for="(item, index) in Object.keys(column)"
                    :key="index"
                  >
                    <span
                      v-if="
                        column[item] &&
                          column[item] != undefined &&
                          column[item].feature &&
                          column[item].feature != undefined
                      "
                    >
                      {{ column[item].feature.name }}
                      <span
                        v-if="
                          column[item].feature.subFeatures &&
                            column[item].feature.subFeatures != undefined
                        "
                      >
                        <div
                          class="font-normal"
                          v-for="(item, index) in column[item].feature.subFeatures"
                          :key="index"
                        >
                          {{ item.name }}
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="whitecolor p-2 detail-border-middle"></div>
              <div class="p-2">
                <div class="subtitle">Basic Plan - Optional Features Purchased</div>
                <div class="container" v-if="addOnColumns && addOnColumns != undefined">
                  <div class="col" v-for="(column, index) in addOnColumns" :key="index">
                    <div
                      class="item-container"
                      v-for="(item, index) in Object.keys(column)"
                      :key="index"
                    >
                      <span
                        v-if="
                          column[item] &&
                            column[item] != undefined &&
                            column[item].feature &&
                            column[item].feature != undefined
                        "
                      >
                        {{ column[item].feature.name }}
                        <span
                          v-if="
                            column[item].feature.subFeatures &&
                              column[item].feature.subFeatures != undefined
                          "
                        >
                          <div
                            class="font-normal"
                            v-for="(item, index) in column[item].feature.subFeatures"
                            :key="index"
                          >
                            {{ item.name }}
                          </div>
                        </span>
                      </span>
                      <div
                        class="redcurrency font-normal"
                        v-if="
                          column[item].feature.monthlyCost &&
                            column[item].feature.monthlyCost != undefined
                        "
                      >
                        {{ column[item].feature.monthlyCost | currency }} per month
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item key="receipts" value="receipts" label="Receipts">
            <div>
              <Receipts />
            </div>
          </b-tab-item>
          <b-tab-item key="billing" value="billing" label="Billing Details">
            <div>
              <Billing :shouldRefresh.sync="shouldRefresh" />
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </PageContentLayoutOnly>
  </div>
  <div v-else-if="isManageSubscription == true">
    <ManageSubscription
      :isManageSubscription.sync="isManageSubscription"
      :subscription="subscription"
    />
  </div>
</template>

<script>
//
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
//
import Receipts from './../Receipts/Main'
import Billing from './../SubscriptionBillingDetails'
import ManageSubscription from './../SubscriptionManage'
//
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
//
export default {
  components: {
    Receipts,
    Billing,
    PageContentLayoutOnly,
    ManageSubscription
  },

  data,

  created() {
    setTimeout(() => this.refresh(), 1000)
  },

  computed,

  mounted() {
    setTimeout(() => this.refresh(), 1000)
  },

  watch: {
    hoaId() {
      this.refresh()
    },
    isManageSubscription() {
      if (this.isManageSubscription == false) {
        setTimeout(() => this.refresh(), 1000)
      }
    },
    shouldRefresh() {
      if (this.shouldRefresh == true) {
        setTimeout(() => this.refresh(), 1000)
        this.shouldRefresh = false
      }
    }
  },

  methods,

  i18n: {
    messages: {
      en: { subscription: { title: 'Subscription' } }
    }
  }
}
</script>

<style lang="scss" scoped>
tbody {
  min-height: 100px !important;
  overflow: auto !important;
}

.col {
  margin: 5px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.item-container {
  font-weight: bold;
  padding: 2px;
  margin: 2px;
}
</style>
