<template>
  <div ballot-modal id="ballotModal" class="section">
    <div class="container">
      <div class="is-size-4">{{ `${buttonText} Reserve Funding Target` }}</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="form-grid">
                  <div class="column is-12">
                    <span style="white-space: nowrap">
                      <valid-input
                        label="Target Year"
                        name="TargetYear"
                        type="text"
                        vid="targetYear"
                        placeholder="Target Year"
                        spellcheck="true"
                        maxlength="4"
                        rules="required|max:4"
                        v-model="formData.targetYear"
                      />
                    </span>
                  </div>
                  <div class="column is-12">
                    <valid-input
                      label="Reserve Target"
                      name="ReserveTarget"
                      maxlength="18"
                      type="reserveTarget"
                      vid="reserveTarget"
                      placeholder="Reserve Target"
                      spellcheck="true"
                      rules="max:18"
                      onkeypress="return (event.charCode >= 48 || event.charCode == 46) && event.charCode <= 57"
                      v-model="formData.reserveTarget"
                    />
                  </div>
                  <div class="column is-12">
                    <button type="submit" class="button is-primary is-rounded">
                      Save</button
                    ><span class="pl-3">&nbsp;</span>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  name: 'ReserveFundingModal',
  props: {
    reserveFundingTarget: Object,
    existingYears: Array
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    ValidInput
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['currentHoaId', 'authUser'])
    })
  },

  data,
  methods
}
</script>
<style lang="scss"></style>
