import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
//
import { List as listGroupRoles } from '@/services/AccountSettings/GroupingRole/List'
import { eraseGroup } from '@/services/AccountSettings/Grouping/Delete'

import { groupingStore } from '@/services/AccountSettings/Grouping/store'
import { List as ListRoles } from '@/services/AccountSettings/Role/List'

import { AssignRolesToGroup } from '@/services/AccountSettings/Role/AssignRolesToGroup'
//
import { notifyProblem, notifyMessage } from '@/services/notify'

export const methods = {
  open({ formData }) {
    this.formData = formData
    this.groupRoles = []
    this.groupPermissions = []
    this.possibleRoles = []

    this.mode = 'summary'

    this.isOpen = true
    this.loading = false
  },
  async eraseGroup() {
    const {
      name
      // groupingID
    } = this.formData

    // TODO:
    // Confirm Deletion via another slide
    // `Are you sure you would like to <b>delete</b> group "${ name }"?`

    this.loading = true

    const { successful } = await eraseGroup({
      groupID: this.formData.groupingID
    })
    if (!successful) {
      notifyProblem('A problem occurred and the group could not be deleted.')
      return
    }

    notifyMessage(`Group "${name}" was deleted successfully.`)

    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    await groupingStore.dispatch('list', {
      hoaID
    })

    this.isOpen = false
    this.loading = false
  },
  async openEditMode() {
    this.loading = true

    // roles of the group
    const { groupRoles } = await listGroupRoles({
      groupID: this.formData.groupingID
    })
    this.groupRoles = groupRoles

    // all possible roles
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    const { list: possibleRoles } = await ListRoles({
      hoaID
    })
    this.possibleRoles = possibleRoles

    this.groupPermissions = []

    const groupRolesClone = _cloneDeep(groupRoles)
    for (let a = 0; a < groupRolesClone.length; a++) {
      const groupRoleID = _get(groupRolesClone, [a, 'roleID'], null)

      for (let b = 0; b < possibleRoles.length; b++) {
        const possibilityRoleID = _get(possibleRoles, [b, 'roleID'], null)

        if (possibilityRoleID === groupRoleID) {
          this.groupPermissions.push(b + 1)
          break
        }
      }
    }
    this.mode = 'edit'
    this.loading = false
  },
  async update() {
    this.loading = true

    const groupingID = this.formData.groupingID
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    const roleIds = this.groupPermissions

    const { successful, errorMessage } = await AssignRolesToGroup({
      json: {
        groupingID,
        hoaID,
        roleIds
      }
    })
    if (!successful) {
      notifyProblem(errorMessage)
    }

    await groupingStore.dispatch('list', {
      hoaID
    })

    if (!successful) {
      this.loading = false
      return
    }

    this.isOpen = false
    this.loading = false
  },
  async back() {
    this.mode = 'summary'
  }
}
