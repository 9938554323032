export function data() {
  return {
    formData: {
      parkingSpaceID: 0,
      spaceNumber: '',
      isCovered: false,
      note: '',
      unitID: 0
    },
    isDebug: true,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    addressData: [],
    addressDropDownList: [],
    addressFilterQuery: null,
    buttonText: 'Add'
  }
}
