/*
  import { accessManagementStore } from '@/pages/Management/accountSettings/AccessManagement/store'

  const isOwner =
  this.unsubAccessManagementStore = accessManagementStore.subscribe (( ))


  const unsubscribe = invitationStore.subscribe((mutation, state) => {
    console.log(mutation.type)
    console.log(mutation.payload)
  })
*/

import _get from 'lodash/get'
import Vue from 'vue'
import Vuex from 'vuex'
//
import { notifyProblem } from '@/services/notify'
//
import { Get as getUserDetails } from '@/services/AccountSettings/HoaAccountManagingUser/Get'
import { List as ListHoaAccountManagingUsers } from '@/services/AccountSettings/HoaAccountManagingUser/List'
//

Vue.use(Vuex)
export const accessManagementStore = new Vuex.Store({
  state: {
    /*
      e.g.
        accountDetails: {
          // hoaID
          0: {
            // accountManagingUserID
            0: {

            }
          }
        }

    */
    accountDetails: {}
  },

  mutations: {},

  /* eslint-disable no-empty-pattern */
  actions: {
    /*
      const { details } = await accessManagementStore.dispatch ('getAccountDetails', {
        hoaID,
        accountManagingUserID
      });
    */
    async getAccountDetails({}, { hoaID, accountManagingUserID }) {
      if (typeof accountManagingUserID === 'number' && accountManagingUserID >= 1) {
        const { list } = await ListHoaAccountManagingUsers({
          hoaID
        })

        let hoaAccountManagingUserID = null
        for (let a = 0; a < list.length; a++) {
          const entry = list[a]

          if (_get(entry, 'accountManagingUserID', null) === accountManagingUserID) {
            hoaAccountManagingUserID = _get(entry, 'hoaAccountManagingUserID', null)
            break
          }
        }

        if (typeof hoaAccountManagingUserID === 'number' && hoaAccountManagingUserID >= 1) {
          const { details } = await getUserDetails({
            hoaAccountManagingUserID
          })

          return {
            details
          }
        } else {
          console.error('hoaAccountManagingUserID', hoaAccountManagingUserID)
        }
      } else {
        notifyProblem('')
      }

      return {
        details: null
      }
    }
  },
  /* eslint-enable no-empty-pattern */

  getters: {}
})
