//
import _get from 'lodash/get'
//
import { invitationStore } from '@/services/AccountSettings/InvitationUser/store'
import { groupingStore } from '@/services/AccountSettings/Grouping/store'

export function mounted() {
  this.init()

  this.unsubscribeInvitationStore = invitationStore.subscribe((mutation, state) => {
    const mType = mutation.type
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    if (mType === 'invitationList') {
      const list = _get(state, ['HOAs', hoaID, 'invitationList'], [])
      if (Array.isArray(list)) {
        this.invitationUsersList = list
      }

      this.determineRows()
    }
  })

  this.unsubscribeGroupingStore = groupingStore.subscribe((mutation, state) => {
    const mType = mutation.type
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    if (mType === 'accountsList') {
      const list = _get(state, ['HOAs', hoaID, 'accountsList'], [])
      if (Array.isArray(list)) {
        this.groupAccountManagingUsersList = list
      }

      this.determineRows()
    }
  })
}
