import _get from 'lodash/get'
import { notifyProblem } from '@/services/notify'

//
import { AssignRolesToAccountManagingUser } from '@/services/AccountSettings/Role/AssignRolesToAccountManagingUser'
import { AssignGroupsToUser } from '@/services/AccountSettings/Grouping/AssignGroupsToUser'
//
import { groupingStore } from '@/services/AccountSettings/Grouping/store'
//
export const methods = {
  edit() {
    this.showSummaryPanel = false
    this.showEditPanel = true
  },
  open({ formData, preventDemotion, accountManagingUserID }) {
    this.mode = 'summary'

    this.preventDemotion = preventDemotion
    this.accountManagingUserID = accountManagingUserID
    this.formData = formData
    this.isOpen = true

    this.loading = false
  },
  close() {
    this.mode = 'summary'
    this.isOpen = false
  },
  openEditMode() {
    this.mode = 'edit'
  },
  cancel() {
    this.mode = 'summary'
  },
  async update() {
    this.loading = true

    const {
      inviteKind,
      inviteRoleKind,
      checkboxInviteGroup,
      checkboxInviteRole
    } = this.$refs.permissionsChooser.getData()

    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    const accountManagingUserID = _get(this, ['formData', 'accountManagingUserID'], null)
    if (typeof accountManagingUserID !== 'number' || typeof hoaID !== 'number') {
      notifyProblem('A problem occurred and the permissions could not be updated.')
      return
    }

    if (inviteKind === 'groups') {
      await AssignGroupsToUser({
        json: {
          accountManagingUserID,
          groupIds: checkboxInviteGroup
        }
      })

      await groupingStore.dispatch('listAccounts', {
        hoaID
      })

      this.loading = false
      this.close()
    } else if (inviteKind === 'roles') {
      if (inviteRoleKind === 'owner') {
        /*
          AssignRolesToAccountManagingUser {
            hoaID: authorization.details.HOAs [ 0 ],
            accountManagingUserID: 0,

            isOwner: true,
            roleIds: []
          }
        */
        const { successful } = await AssignRolesToAccountManagingUser({
          json: {
            hoaID,
            accountManagingUserID,

            isOwner: true,
            roleIds: []
          }
        })
        if (!successful) {
          notifyProblem('A problem occurred while attempting to update the roles.')
        }

        await groupingStore.dispatch('listAccounts', {
          hoaID
        })

        this.loading = false
        this.close()
      } else {
        /*
          AssignRolesToAccountManagingUser {
            hoaID: authorization.details.HOAs [ 0 ],
            accountManagingUserID: 0,

            isOwner: false,
            roleIds: [ 1,2,3,4,5,6 ]
          }
        */
        const { successful } = await AssignRolesToAccountManagingUser({
          json: {
            hoaID,
            accountManagingUserID,

            isOwner: false,
            roleIds: checkboxInviteRole
          }
        })
        if (!successful) {
          notifyProblem('A problem occurred while attempting to update the roles.')
        }

        await groupingStore.dispatch('listAccounts', {
          hoaID
        })

        this.loading = false
        this.close()
      }
    }
  }
}
