import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
//
import { notifyProblem, notifyMessage } from '@/services/notify'
import { committeeSettings } from '@/services/Committees/CommitteeSettings/store'

export const methods = {
  async refresh({ committeeList: prefetchedCommitteeList = null } = {}) {
    try {
      const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

      let committeeList = null
      if (Array.isArray(prefetchedCommitteeList)) {
        committeeList = prefetchedCommitteeList
      } else {
        const { list } = await this.$store.dispatch('committees/getCommitteeList')
        committeeList = list
      }

      const { list: committeeSettingsList } = await committeeSettings.dispatch('getList', {
        params: {
          hoaID
        }
      })

      const architecturalCommitteeID = _get(
        committeeSettingsList,
        [0, 'architecturalCommitteeID'],
        null
      )
      const boardCommitteeID = _get(committeeSettingsList, [0, 'boardCommitteeID'], null)

      const committeeSettingID = (this.committeeSettingID = _get(
        committeeSettingsList,
        [0, 'committeeSettingID'],
        null
      ))

      /*
        is the arch committee set in the list?
        is the board committee set in the list?
      */
      let archCommitteeExists = false
      let boardCommitteeExists = false
      for (let a = 0; a < committeeList.length; a++) {
        const committee = committeeList[a]

        if (committee.committeeID === architecturalCommitteeID) {
          archCommitteeExists = true
        }

        if (committee.committeeID === boardCommitteeID) {
          boardCommitteeExists = true
        }
      }

      // initialize primary architectural committee
      if (typeof architecturalCommitteeID === 'number' && archCommitteeExists) {
        this.selectedArchCommitteeID = architecturalCommitteeID
        this.actualArchCommitteeID = architecturalCommitteeID
      } else {
        this.selectedArchCommitteePlaceholder = 'Please select a primary architectural committee'
      }

      // initialize primary board committee
      if (typeof boardCommitteeID === 'number' && boardCommitteeExists) {
        this.selectedBoardCommitteeID = boardCommitteeID
        this.actualBoardCommitteeID = boardCommitteeID
      } else {
        this.selectedBoardCommitteePlaceholder = 'Please select a primary board committee'
      }

      this.setCommittees({
        committeeList,
        committeeSettings: {}
      })

      return {
        committeeSettingID,
        //
        architecturalCommitteeID,
        boardCommitteeID
      }
    } catch (exception) {
      console.error(exception)
    }

    return {
      committeeSettingID: null,
      architecturalCommitteeID: null,
      boardCommitteeID: null
    }
  },

  setCommittees({ committeeList }) {
    const archCommittees = []
    const boardCommittees = []
    for (let a = 0; a < committeeList.length; a++) {
      const committee = committeeList[a]
      const committeeTypeID = _get(committee, 'committeeTypeID', null)

      if (committeeTypeID === 2) {
        archCommittees.push(committee)
      } else if (committeeTypeID === 1) {
        boardCommittees.push(committee)
      }
    }

    this.archCommittees = archCommittees.map(entry => {
      return {
        id: _get(entry, 'committeeID', ''),
        name: _get(entry, 'name', '')
      }
    })

    this.boardCommittees = boardCommittees.map(entry => {
      return {
        id: _get(entry, 'committeeID', ''),
        name: _get(entry, 'name', '')
      }
    })
  },

  getCommitteeName({
    id,
    sort // boardCommittees || archCommittees
  }) {
    return _get(
      this[sort].filter(committee => {
        return committee.id === id
      }),
      [0, 'name'],
      ''
    )
  },

  async checkRefresh({ architecturalCommitteeID, boardCommitteeID }) {
    try {
      /*
        try up to 6 times to make sure the change is
        reflected in the cloud
      */
      let successful = false
      for (let a = 1; a <= 6; a++) {
        const {
          architecturalCommitteeID: newArchitecturalCommitteeID,
          boardCommitteeID: newBoardCommitteeID
        } = await this.refresh()

        if (
          newArchitecturalCommitteeID === architecturalCommitteeID &&
          newBoardCommitteeID === boardCommitteeID
        ) {
          successful = true
          break
        }

        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 1000)
        })
      }

      return { successful }
    } catch (exception) {
      console.error(exception)
    }

    return {
      successful: false
    }
  },

  //
  //
  // arch committee methods
  //
  selectedArchCommitteeChanged() {
    const selectedId = this.selectedArchCommitteeID
    const actualId = this.actualArchCommitteeID

    if (selectedId === actualId) {
      return
    }

    /*
      resets the select box to the actual value
      in the background
    */
    if (actualId !== null) {
      setTimeout(() => {
        this.selectedArchCommitteeID = actualId
      }, 200)
    }

    const actualName = this.getCommitteeName({
      id: actualId,
      sort: 'archCommittees'
    })
    const selectedName = this.getCommitteeName({
      id: selectedId,
      sort: 'archCommittees'
    })

    // if a primary arch committee hasn't been set
    // skip the confirmation modal
    if (actualId === null) {
      this.updatePrimaryArchCommittee({
        architecturalCommitteeID: selectedId
      })
      return
    }

    this.primaryArchCommitteeUpdateModal = {
      show: true,

      actual: {
        name: actualName,
        id: actualId
      },

      selected: {
        name: selectedName,
        id: selectedId
      },

      inputValue: '',
      buttonPressable: false
    }
  },
  async updateArchButtonPressed() {
    Vue.set(this.primaryArchCommitteeUpdateModal, 'loading', true)

    await this.updatePrimaryArchCommittee({
      architecturalCommitteeID: this.primaryArchCommitteeUpdateModal.selected.id
    })

    Vue.set(this.primaryArchCommitteeUpdateModal, 'loading', false)
  },
  async updatePrimaryArchCommittee({ architecturalCommitteeID }) {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const boardCommitteeID = this.actualBoardCommitteeID

    const { successful } = await committeeSettings.dispatch('setCommitteesAsPrimary', {
      hoaID,
      committeeSettingID: this.committeeSettingID,
      //
      architecturalCommitteeID,
      boardCommitteeID
    })
    if (!successful) {
      notifyProblem('Updating the primary architectural committee was unsuccessful.')
      return
    }

    const { successful: refreshSuccessful } = await this.checkRefresh({
      architecturalCommitteeID,
      boardCommitteeID
    })
    if (!refreshSuccessful) {
      notifyProblem('Updating the primary architectural committee was unsuccessful.')
      return
    }

    const name = _get(
      this.archCommittees.filter(c => {
        return c.id === architecturalCommitteeID
      }),
      [0, 'name'],
      ''
    )

    if (typeof name === 'string' && name.length >= 1) {
      notifyMessage(`${name} is now the primary architectural committee.`)
    } else {
      notifyMessage(`The primary architectural committee was updated.`)
    }

    // reset the modal
    this.primaryArchCommitteeUpdateModal = _cloneDeep(this.archModalPresets)

    this.loading = false
  },
  //
  //
  // board committee methods
  //
  selectedBoardCommitteeChanged() {
    this.loading = true

    const selectedId = this.selectedBoardCommitteeID
    const actualId = this.actualBoardCommitteeID

    if (selectedId === actualId) {
      return
    }

    /*
      resets the select box to the actual value
      in the background
    */
    if (actualId !== null) {
      setTimeout(() => {
        this.selectedBoardCommitteeID = actualId
      }, 500)
    }

    const actualName = this.getCommitteeName({
      id: actualId,
      sort: 'boardCommittees'
    })
    const selectedName = this.getCommitteeName({
      id: selectedId,
      sort: 'boardCommittees'
    })

    // if a primary arch committee hasn't been set
    // skip the confirmation modal
    if (actualId === null) {
      this.updatePrimaryBoardCommittee({
        boardCommitteeID: selectedId
      })
      return
    }

    this.loading = false

    this.primaryBoardCommitteeUpdateModal = {
      show: true,

      actual: {
        name: actualName,
        id: actualId
      },

      selected: {
        name: selectedName,
        id: selectedId
      },

      inputValue: '',
      buttonPressable: false
    }
  },
  async updateBoardButtonPressed() {
    Vue.set(this.primaryBoardCommitteeUpdateModal, 'loading', true)

    await this.updatePrimaryBoardCommittee({
      boardCommitteeID: this.primaryBoardCommitteeUpdateModal.selected.id
    })

    Vue.set(this.primaryBoardCommitteeUpdateModal, 'loading', false)
  },
  async updatePrimaryBoardCommittee({ boardCommitteeID }) {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const architecturalCommitteeID = this.actualArchCommitteeID

    const { successful } = await committeeSettings.dispatch('setCommitteesAsPrimary', {
      hoaID,
      committeeSettingID: this.committeeSettingID,
      //
      boardCommitteeID,
      architecturalCommitteeID
    })
    if (!successful) {
      notifyProblem('Updating the primary board of directors was unsuccessful.')
      return
    }

    const { successful: refreshSuccessful } = await this.checkRefresh({
      architecturalCommitteeID,
      boardCommitteeID
    })
    if (!refreshSuccessful) {
      notifyProblem('Updating the primary board of directors was unsuccessful.')
      return
    }

    const name = _get(
      this.boardCommittees.filter(c => {
        return c.id === boardCommitteeID
      }),
      [0, 'name'],
      ''
    )

    if (typeof name === 'string' && name.length >= 1) {
      notifyMessage(`${name} is now the primary board of directors.`)
    } else {
      notifyMessage(`The primary board of directors was updated.`)
    }

    // reset the modal
    this.primaryBoardCommitteeUpdateModal = _cloneDeep(this.boardModalPresets)

    this.loading = false
  }
}
