<template>
  <ModernTable
    users-table
    :columns="columns"
    :rows="rows"
    :filters="{
      show: false
    }"
    :focusable="true"
    :selected.sync="selected"
    condensed
    :rowOnClick="rowOnClick"
  />
</template>

<script>
//
import ModernTable from '@/components/tables/Modern/Table'
//
import { beforeDestroy } from './keys/beforeDestroy'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'
//
export default {
  props: {
    showEditModal: Function,
    isOwner: {
      type: Boolean,
      default: false
    }
  },

  components: {
    ModernTable
  },

  beforeDestroy,
  data,
  methods,
  mounted,
  watch
}
</script>
