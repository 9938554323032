import _get from 'lodash/get'

export function filterAccounts({ accounts }) {
  return accounts.filter(account => {
    const isLinked = _get(account, 'isLinked', null)
    if (isLinked === true) {
      return true
    }

    return false
  })
}
