<template>
  <PageContentLayoutOnly :aria-label="$t('reserveFunding.title')">
    <div class="level">
      <div class="level-left">
        <b-button
          tests-id="add-work-order-button"
          @click.prevent="createReserveFundingTargetModal()"
          type="is-primary"
          rounded
          :style="{
            ...(breakpoint === 'desktop'
              ? {}
              : {
                  width: '100%'
                })
          }"
        >
          Add Reserve Funding Target
        </b-button>
      </div>
    </div>
    <div>
      <ModernTable :filters="filters" :rows="rows" :columns="columns" :pagination="pagination" />
      <Modal :toggle.sync="toggle">
        <ReserveFundingTargetModal
          :reserveFundingTarget="selectedReserveFundingTarget"
          :existingYears="existingYears"
        />
      </Modal>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import { mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import ReserveFundingTargetModal from './components/reserveFundingModal'
import Modal from '@/components/Modal'

import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'

export default {
  components: {
    PageContentLayoutOnly,
    ModernTable,
    ReserveFundingTargetModal,
    Modal
  },

  data,
  methods,
  watch,
  computed: {
    ...mapGetters('user', ['currentHoaId', 'currentUnitId', 'currentOwnerId']),
    breakpoint: state => (state && state.win ? state.win.breakpoint : null)
  },

  mounted,

  i18n: {
    messages: {
      en: { reserveFunding: { title: 'Reserve Funding' } }
    }
  }
}
</script>
