/*
  import { groupingStore } from '@/services/AccountSettings/Grouping/store'

  this.unsubscribeGroupingStore = groupingStore.subscribe((mutation, state) => {
    const mType = mutation.type;
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null);


  })

  this.unsubscribeGroupingStore();
*/

import Vue from 'vue'
import Vuex from 'vuex'
//
import { List as ListGroupingAccountManagingUsers } from './AccountManagingUserList'
import { List } from './List'
//
/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await groupingStore.dispatch ('listAccounts', {
      hoaID
    });
  */
  async listAccounts({ commit }, { hoaID }) {
    const { list, successful } = await ListGroupingAccountManagingUsers({
      hoaID
    })
    if (successful) {
      commit('accountsList', {
        hoaID,
        list
      })
    }

    return { list }
  },

  /*
    const { list } = await groupingStore.dispatch ('list', {
      hoaID
    });
  */
  async list({ commit }, { hoaID }) {
    const { list, successful } = await List({
      hoaID
    })
    if (successful) {
      commit('groupsList', {
        hoaID,
        list
      })
    }

    return { list }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const groupingStore = new Vuex.Store({
  /*
    e.g. HOAs: {
      0: {

      }
    }
  */
  state: {
    HOAs: {}
  },
  mutations: {
    accountsList(state, { list, hoaID }) {
      if (state.HOAs[hoaID] === undefined) {
        state.HOAs[hoaID] = {}
      }

      state.HOAs[hoaID].accountsList = list
    },
    groupsList(state, { list, hoaID }) {
      if (state.HOAs[hoaID] === undefined) {
        state.HOAs[hoaID] = {}
      }

      state.HOAs[hoaID].groupsList = list
    }
  },
  getters: {},
  actions
})

//////
