/*
  import { RevokeInvitationUser } from '@/services/AccountSettings/InvitationUser/RevokeInvitationUser'

  const { successful } = await RevokeInvitationUser ({
    invitedUserId
  });
*/

import kms from '@/services/kms'
//
import { notifyProblem } from '@/services/notify'
//
export async function RevokeInvitationUser({ invitationUserID }) {
  console.log('RevokeInvitationUser')

  try {
    const results = await kms.post(
      `/AccountSettings/InvitationUser/RevokeInvitationUser?invitationUserID=${invitationUserID}`
    )

    if (results.invitationUserID > 0) {
      return {
        successful: true,
        results
      }
    }
  } catch (exception) {
    console.error(exception)
  }

  notifyProblem('There was a problem revoking the invite.')

  return {
    successful: false,
    results: null
  }
}
