<template>
  <PageContent :title="$t('garagesList.title')">
    <div class="level">
      <div class="level-left">
        <b-button
          tests-id="add-work-order-button"
          @click.prevent="createGarageModal()"
          size="is-small"
          type="is-primary"
          rounded
        >
          Add Garage
        </b-button>
      </div>
    </div>

    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
    />
    <Modal :toggle.sync="toggle">
      <GarageModal :garage="selectedGarage" />
    </Modal>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import GarageModal from './components/GarageModal'
import Modal from '@/components/Modal'
import { mapState, mapGetters } from 'vuex'

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'GarageList',
  components: {
    PageContent,
    ModernTable,
    GarageModal,
    Modal
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,

  methods,

  mounted: function() {
    data, (this.loading = true)
    this.reload()

    this.loading = false
  },

  beforeDestroy: function() {},

  watch: {
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  i18n: {
    messages: {
      en: {
        garagesList: {
          title: 'Garages'
        }
      }
    }
  }
}
</script>

<style></style>
