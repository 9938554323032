/*
  import { List as listGroupRoles } from '@/services/AccountSettings/GroupingRoles/List'

  const { groupRoles } = await listGroupRoles ({
    groupID
  });
*/

import kms from '@/services/kms'
import _get from 'lodash/get'

export async function List({ groupID }) {
  console.log({ groupID })

  try {
    let result = await kms.get(`/AccountSettings/GroupingRole/List?groupID=${groupID}`)

    return {
      successful: true,
      groupRoles: _get(result, 'results', [])
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    groupRoles: []
  }
}
