<template>
  <Modal :toggle.sync="isOpen" member-editor-modal>
    <div>
      <article v-if="mode === 'summary'">
        <div class="content is-size-3 text-center">
          {{ `${formData.name}` }}
        </div>
        <div class="text-center">{{ formData.aspNetUserEmail }}</div>
        <div class="text-center underline font-bold">Permissions</div>
        <div class="columns text-center is-12">
          <div class="column is-one-half font-bold">Admin Access</div>
          <div class="column is-one-half font-bold">Read-only Access</div>
        </div>
        <div
          v-if="formData.isOwner && formData.isOwner != undefined && formData.isOwner == true"
          class="columns text-center is-12"
        >
          <div class="column is-one-half">Account Owner</div>
          <div class="column is-one-half"></div>
        </div>
        <div v-else class="columns text-center is-12">
          <div class="column is-one-half">{{ formData.adminPermissions }}</div>
          <div class="column is-one-half">{{ formData.readPermissions }}</div>
        </div>
      </article>

      <article v-if="mode === 'edit'">
        <PermissionsChooser
          ref="permissionsChooser"
          :preventDemotion="preventDemotion"
          :accountManagingUserID="accountManagingUserID"
        />
      </article>
    </div>

    <b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>

    <template v-slot:footer>
      <div :style="{ textAlign: 'right' }" v-if="mode === 'summary' && isOwner === true">
        <b-button @click="openEditMode" edit-button>Edit</b-button>
      </div>

      <div :style="{ textAlign: 'right' }" v-if="mode === 'summary' && isOwner === false">
        <b-tooltip label="Account Owner permission is necessary to make edits" position="is-left">
          <b-button disabled>Edit</b-button>
        </b-tooltip>
      </div>

      <div :style="{ overflow: 'visible' }" v-if="mode === 'edit'">
        <b-button :style="{ float: 'left' }" @click="cancel">Cancel</b-button>
        <b-button :style="{ float: 'right' }" type="is-primary" @click="update">Update</b-button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import PermissionsChooser from './../PermissionsChooser'

import { data } from './keys/data'
import { methods } from './keys/methods'
import { props } from './keys/props'

export default {
  components: {
    Modal,
    PermissionsChooser
  },
  props,
  data,
  methods
}
</script>
