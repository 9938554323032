export const props = {
  preventDemotion: {
    type: Boolean,
    default: false
  },
  accountManagingUserID: {
    type: Number,
    default: null
  }
}
