<template>
  <PageContentLayoutOnly role="region" :aria-label="$t('apSettings.title')">
    <div class="notification headerColor box is-4" v-if="true">
      <div class="tile is-ancestor">
        <div class="tile is-vertical is-12">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }" v-if="authUser">
            <transition name="fade" mode="out-in" appear>
              <form @submit.prevent="handleSubmit(onFormSettingSubmit)" class="form">
                <fieldset>
                  <div class="form-grid">
                    <div class="column is-12" :style="styles.formRow">
                      <span class="column is-3" style="white-space: nowrap">
                        <b-field>
                          <valid-input
                            readonly="true"
                            disabled="true"
                            type="number"
                            label="Minimum Approvers for Approval"
                            name="MinimumApprovers"
                            vid="minimumApprovers"
                            placeholder="0"
                            v-model="formData.minimumApprovers"
                          />
                        </b-field>
                      </span>
                    </div>
                    <div class="pl-3 pt-3 column is-12">
                      <b-field label="A/P General Ledger Account">
                        <b-autocomplete
                          size="is-small"
                          field="label"
                          icon="magnify"
                          :loading="isFetching"
                          placeholder="e.g. G/L Account Search"
                          :data="data"
                          :open-on-focus="openOnFocus"
                          v-model="glExpenseFilterQuery"
                          required="true"
                          readonly="true"
                          disabled="true"
                          @typing="getGlAsyncData"
                          @select="option => (selected = option)"
                        >
                        </b-autocomplete>
                      </b-field>
                    </div>
                    <div class="pl-3 pt-3 column is-12">
                      <b-field label="Subscription Payment Bank Account">
                        <b-autocomplete
                          size="is-small"
                          field="label"
                          icon="magnify"
                          :loading="isFetching2"
                          placeholder="e.g. Bank Account Search"
                          :data="data2"
                          :open-on-focus="openOnFocus"
                          v-model="bankAccountFilterQuery"
                          required="true"
                          readonly="true"
                          disabled="true"
                          @typing="getBankAccountAsyncData"
                          @select="option => (selected = option)"
                        >
                        </b-autocomplete>
                      </b-field>
                    </div>
                    <div class="pl-3 pt-3 column is-12">
                      <b-field label="Subscription Expense G/L Account">
                        <b-autocomplete
                          size="is-small"
                          field="label"
                          icon="magnify"
                          :loading="isFetching3"
                          placeholder="e.g. G/L Account Search"
                          :data="data3"
                          :open-on-focus="openOnFocus"
                          v-model="subscriptionGlFilterQuery"
                          required="true"
                          readonly="true"
                          disabled="true"
                          @typing="getSubscriptionGlAsyncData"
                          @select="option => (selected = option)"
                        >
                        </b-autocomplete>
                      </b-field>
                    </div>
                    <div class="pl-3 pt-3 column is-12">
                      <b-field label="Subscription Billing Address">
                        <b-autocomplete
                          size="is-small"
                          field="label"
                          icon="magnify"
                          :loading="isFetching4"
                          placeholder="e.g. Address Search"
                          :data="data4"
                          :open-on-focus="openOnFocus"
                          v-model="addressFilterQuery"
                          required="true"
                          readonly="true"
                          disabled="true"
                          @typing="getAddressAsyncData"
                          @select="option => (selected = option)"
                        >
                        </b-autocomplete>
                      </b-field>
                    </div>
                    <div class="column is-12" :style="styles.formRow">
                      <span :style="{ whiteSpace: 'nowrap' }">
                        <valid-input
                          label="Subscription Auto Payment Threshold Amount"
                          name="ThresholdAmount"
                          readonly="true"
                          disabled="true"
                          type="numeric"
                          vid="thresholdAmount"
                          placeholder="Subscription Auto Payment Threshold Amount"
                          spellcheck="true"
                          v-model="formData.thresholdAmount"
                        />
                      </span>
                    </div>
                    <div class="column is-12" :style="styles.formRow">
                      <span class="column is-3" style="white-space: nowrap">
                        <b-field label="Initial Pending Invoice Notification Days">
                          <b-numberinput
                            style="width:150px;"
                            name="InitialPendingDays"
                            vid="initialPendingDays"
                            v-model="formData.initialPendingDays"
                          />
                        </b-field>
                      </span>
                    </div>
                    <div class="column is-12" :style="styles.formRow">
                      <span class="column is-3" style="white-space: nowrap">
                        <b-field label="Continuing Pending Invoice Notification Days">
                          <b-numberinput
                            style="width:150px;"
                            name="ContinuingPendingDays"
                            vid="continuingPendingDays"
                            v-model="formData.continuingPendingDays"
                          />
                        </b-field>
                      </span>
                    </div>
                    <div class="column is-12 pl-5">
                      <b-checkbox
                        class="has-left-text"
                        readonly="true"
                        disabled="true"
                        v-model="formData.AutoApproved"
                        @click.native="isAutoApproved = !isAutoApproved"
                        >Auto Approved</b-checkbox
                      >
                    </div>
                    <div class="pt-6 column is-12" :style="styles.formRow">
                      <button
                        type="submit"
                        class="button is-primary is-rounded"
                        :disabled="loading"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                    <div class="field">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </fieldset>
              </form>
            </transition>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { mapState, mapGetters } from 'vuex'
import ValidInput from '@/components/inputs/ValidInput'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    PageContentLayoutOnly,
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    }),
    ...mapGetters('accounting', ['glExpenseDropDownList']),
    ...mapGetters('accounting', ['apSetting']),
    ...mapGetters('bankaccount', ['checkingBankAccounts']),
    ...mapGetters('hoa', ['addressDropDownList']),
    glExpenseFilterSuggestions() {
      if (
        this.glExpenseFilterQuery &&
        this.glExpenseDropDownList &&
        this.glExpenseDropDownList != undefined
      ) {
        return this.glExpenseDropDownList.filter(
          a => a.label.indexOf(this.glExpenseFilterQuery.toLowerCase()) !== -1
        )
      } else {
        return this.glExpenseDropDownList
      }
    },
    bankAccountFilterSuggestions() {
      if (
        this.bankAccountFilterQuery &&
        this.checkingBankAccounts &&
        this.checkingBankAccounts != undefined
      ) {
        return this.checkingBankAccounts.filter(
          a => a.label.indexOf(this.bankAccountFilterQuery.toLowerCase()) !== -1
        )
      } else {
        return this.checkingBankAccounts
      }
    },
    subscriptionGlFilterSuggestions() {
      if (
        this.subscriptionGlFilterQuery &&
        this.glExpenseDropDownList &&
        this.glExpenseDropDownList != undefined
      ) {
        return this.glExpenseDropDownList.filter(
          a => a.label.indexOf(this.subscriptionGlFilterQuery.toLowerCase()) !== -1
        )
      } else {
        return this.glExpenseDropDownList
      }
    },
    addressFilterSuggestions() {
      if (
        this.addressFilterQuery &&
        this.addressDropDownList &&
        this.addressDropDownList != undefined &&
        this.addressDropDownList.dropDownAddresses &&
        this.addressDropDownList.dropDownAddresses != undefined
      ) {
        return this.addressDropDownList.dropDownAddresses.filter(
          a => a.label.indexOf(this.addressFilterQuery.toLowerCase()) !== -1
        )
      } else {
        return this.addressDropDownList.dropDownAddresses
      }
    }
  },

  mounted() {
    this.loading = true

    if (this.isDebug == true) console.debug('gen settings authUser=' + this.authUser)

    this.reload()

    this.loading = false
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  methods,

  i18n: {
    messages: {
      en: { apSettings: { title: 'A/P Settings' } }
    }
  }
}
</script>
