export function data() {
  return {
    palette: {
      1: '#fff',
      2: '#ccc',
      3: 'purple'
    },

    styles: {
      tab: {
        position: 'relative',
        bottom: '-1px',
        left: 0,

        display: 'inline-block',
        padding: '14px 24px',

        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        cursor: 'pointer',

        outline: 0,
        borderBottom: 0
      }
    },

    selectedTab: 0,

    isOwner: null,
    canInvite: false,

    showUsers: true,
    showGroups: false,
    //
    memberInvitationToggle: false,
    //
    memberEditorToggle: false,
    memberEditorFormData: {}
  }
}
