/*
  import { Post as addCommitteeMember } from '@/services/Committees/CommitteeMember/Post'

  await addCommitteeMember ({
    json: {}
  });
*/

import kms from '@/services/kms'
//
export async function Post({ json }) {
  let exception = null

  try {
    const results = await kms.post(`/Committees/CommitteeMember`, json)

    console.log(`post /Committees/CommitteeMember`, { results })

    return {
      results,
      exception
    }
  } catch (exception_) {
    exception = exception_
    console.error(exception)
  }

  return {
    results: null,
    exception
  }
}

////
