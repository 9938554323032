//
import _get from 'lodash/get'
import { mapActions } from 'vuex'
//
import { parseDate } from '@/utilities/Date/parse'
//
import Button from '@/components/buttons/Button'
import Buttons from '../components/Buttons'

export const methods = {
  ...mapActions('user', ['selectHoaId']),

  determineCommitteeRows({ active }) {
    var rows = this.$store.getters['committees/committeeList']
      .filter(entry => {
        if (active) {
          return entry.isActive
        } else {
          return !entry.isActive
        }
      })
      .map(entry => {
        const committeeID = _get(entry, 'committeeID', '')
        const name = _get(entry, 'name', '')

        return {
          name,
          committeeType: _get(entry, 'committeeType.name', ''),
          membersCount: _get(entry, 'memberCount', ''),
          options: {
            component: Buttons,
            props: {
              edit: () => {
                this.editToggle = true
                this.selectedCommitteeID = committeeID
              },
              members: () => {
                if (this.isDebug == true) console.debug('id=' + committeeID)
                this.selectedCommitteeID = committeeID
                this.reloadCommitteeMembers()
                this.showMembers = true
                this.selectedCommitteeTitle = entry.name
                this.selectedCommittee = entry
              },
              erase: () => {
                this.openDeletionDialog({ committeeID, name })
              }
            }
          }
        }
      })

    return rows
  },
  generateCommitteeLists() {
    this.activeTable.rows = this.determineCommitteeRows({
      active: true
    })
    this.activeTable.loading = false

    this.inactiveTable.rows = this.determineCommitteeRows({
      active: false
    })
    this.inactiveTable.loading = false
  },

  determineMemberRows({ active }) {
    const dateFormat = 'LL'

    var rows = this.$store.getters['committees/committeeMemberList']
      .filter(entry => {
        if (active) {
          return !entry.inactive
        } else {
          return entry.inactive
        }
      })
      .map(entry => {
        const termStartDate = _get(entry, 'termStartDate', '?')
        const termEndDate = _get(entry, 'termEndDate', '?')

        const committeeMemberID = _get(entry, 'committeeMemberID', null)
        const name = _get(entry, 'name', '')

        const termDates =
          parseDate(termStartDate, dateFormat) + ' to ' + parseDate(termEndDate, dateFormat)

        return {
          name,
          title: _get(entry, 'title', ''),
          termDates,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                if (this.isDebug == true)
                  console.debug(
                    '...in editMemberClicked id=' +
                      committeeMemberID +
                      ' member=' +
                      JSON.stringify(entry)
                  )
                this.memberUpdateToggle = true
                this.selectedCommitteeMemberID = committeeMemberID
                this.selectedCommitteeMember = entry
              },
              text: 'Edit'
            }
          },
          members: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmCommitteeMemberDelete(committeeMemberID, name)
              },
              text: 'Delete'
            }
          }
        }
      })

    return rows
  },
  generateMemberLists() {
    this.activeMembers.loading = true
    this.inactiveMembers.loading = true

    this.activeMembers.rows = this.determineMemberRows({
      active: true
    })
    this.activeMembers.loading = false

    this.inactiveMembers.rows = this.determineMemberRows({
      active: false
    })
    this.inactiveMembers.loading = false
  },

  confirmCommitteeMemberDelete(id, member) {
    if (this.isDebug == true) console.debug('...in confirmCommitteeMemberDelete id=' + id)
    this.$buefy.dialog.confirm({
      title: 'Deleting Committee Member',
      message: `Are you sure you want to <b>delete</b> this committee member, '${member}'?`,
      confirmText: 'Delete Committee Member',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteMemberClicked(id)
    })
  },
  async deleteMemberClicked(id) {
    if (this.isDebug == true) console.debug('...in deleteMemberClicked id=' + id)

    this.$store.dispatch('committees/deleteCommitteeMember', {
      committeeMemberID: id,
      committeeID: this.selectedCommitteeID,
      done: () => {
        this.loading = false
      }
    })
  },

  openDeletionDialog({ committeeID, name }) {
    if (this.primaryArchitecturalCommitteeID === committeeID) {
      this.showingPrimaryArchDeletionPrevetion = true
      this.$buefy.dialog.alert({
        title: 'Deletion Not Possible',
        message:
          'The primary architectural committee cannot be deleted. To delete this committee, please set another committee as the primary architectural committee.',
        type: 'is-danger',
        hasIcon: true,
        ariaRole: 'alertdialog',
        ariaModal: true,
        onConfirm: () => {
          this.showingPrimaryArchDeletionPrevetion = false
        }
      })
      return
    }

    if (this.primaryBoardCommitteeID === committeeID) {
      this.$buefy.dialog.alert({
        title: 'Deletion Not Possible',
        message:
          'The primary board of directors cannot be deleted. To delete this committee, please set another committee as the primary board of directors.',
        type: 'is-danger',
        hasIcon: true,
        ariaRole: 'alertdialog',
        ariaModal: true
      })
      return
    }

    this.$buefy.dialog.confirm({
      title: 'Deleting Committee',
      message: `Are you sure you want to <b>delete</b> this committee, '${name}'?`,
      confirmText: 'Delete Committee',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteCommittee({ committeeID })
    })
  },
  async deleteCommittee({ committeeID }) {
    this.$store.dispatch('committees/deleteCommittee', {
      committeeID,
      done: () => {
        this.loading = false
      }
    })
  },

  async reload() {
    this.$store.dispatch('subscription/getHoaSubscription')
  },

  async reloadCommitteeMembers() {
    if (this.isDebug == true) console.debug('...reloadCommitteeMembers')

    this.committeeMemberList = null

    if (this.selectedCommitteeID > 0) {
      this.$store.dispatch('committees/getCommitteeMemberList', {
        committeeID: this.selectedCommitteeID
      })
    }
  },
  addCommitteeClicked() {
    this.toggle = true
  },
  addMemberClicked() {
    this.$refs.AddCommitteeMemberModal.open()
  },
  cancelClicked() {
    this.showMembers = false
    this.selectedCommitteeTitle = ''

    this.activeMembers.loading = true
    this.inactiveMembers.loading = true
    this.activeMembers.rows = []
    this.inactiveMembers.rows = []
  }
}
