/*
  import { eraseGroup } from '@/services/AccountSettings/Grouping/Delete'

  const { successful } = await eraseGroup ({
    groupID
  });
  if (!successful) {
    notifyProblem('A problem occurred and the group could not be deleted.')
    return;
  }
*/

import kms from '@/services/kms'

export async function eraseGroup({ groupID }) {
  try {
    await kms.delete(`/AccountSettings/Grouping/${groupID}?forceDelete=true`)

    return {
      successful: true
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false
  }
}
