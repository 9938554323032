import { notifyMessage, notifyError } from '@/services/notify'
import kms from '@/services/kms'
import { listBankAccounts } from '@/services/BankAccounts/BankAccount/List'
import { getGLAccountsList } from '@/services/GeneralLedger/Account/List'
//
import { logJSON } from '@/utilities/log/JSON'
//
import $ from 'jquery'
import _get from 'lodash/get'
//
export const methods = {
  async getBankAccounts() {
    const { bankAccounts, successful, message } = await listBankAccounts({
      params: {
        hoaID: this.hoaId,
        hasCheckingOnly: true
      }
    })
    if (!successful) {
      notifyError(message)
      throw new Error(message)
    }
    const checkingBankAccounts = bankAccounts.map(({ bankAccountID: value, name: label }) => ({
      value,
      label
    }))

    this.checkingBankAccounts = checkingBankAccounts
  },

  async getGLExpenseAccounts() {
    //
    //  With account parsing
    //
    const { successful, message, parsedAccounts } = await getGLAccountsList({
      parseTypes: true,
      params: {
        hoaID: this.hoaId,
        hasExpenseOnly: true
      }
    })
    if (!successful) {
      notifyError(message)
      throw new Error(message)
    }

    if (Array.isArray(_get(parsedAccounts, 'Expense', []))) {
      this.glExpenseAccounts = parsedAccounts.Expense
    }
  },

  async refresh() {
    this.refreshing = true

    await this.getBankAccounts()
    await this.getGLExpenseAccounts()

    console.log({ apsetting: this.apsetting })

    console.log({ glExpenseAccounts: this.glExpenseAccounts })

    // this.$store.dispatch('bankaccount/loadBankAccountCheckingList')
    // this.$store.dispatch('subscription/loadGlExpenseAccountList')

    this.setCurrentDropDownSelections()

    this.refreshing = false
  },

  setCurrentDropDownSelections() {
    if (
      this.formData &&
      this.formData != undefined &&
      this.apsetting &&
      this.apsetting != undefined
    ) {
      this.formData.bankAccountID = this.apsetting.subscriptionPaymentBankAccountID
      this.formData.accountID = this.apsetting.subscriptionExpenseGLAccountID
    }
  },
  closeModal() {
    this.$emit('update:toggle', (this.billingModalToggle = false))
    if (this.isDebug == true) console.debug('billingModal=' + this.billingModalToggle)
  },

  async onFormSubmit() {
    console.log('onFormSubmit')

    if (this.isDebug == true) console.debug('in onFormSubmit... ')

    if (
      !this.formData ||
      this.formData == undefined ||
      !this.formData.accountID ||
      this.formData.accountID == undefined ||
      !this.formData.bankAccountID ||
      this.formData.bankAccountID == undefined
    ) {
      notifyError('Please validate that you have selected a bank account and G/L expense account.')
    }

    if (this.formData.bankAccountID == 0 || this.formData.accountID == 0) {
      notifyError(
        'Please select a bank account and a G/L expense account that will be used for subscription billing.'
      )
    }

    if (this.isDebug == true) console.debug('formData=' + JSON.stringify(this.formData))

    try {
      this.refreshing = true

      const payload = {
        apSettingID: this.apsetting.apSettingID,
        hoaID: this.apsetting.hoaID,
        minimumApproversForApproval: this.apsetting.minimumApproversForApproval,
        accountsPayableGlAccountID: this.apsetting.accountsPayableGlAccountID,
        subscriptionPaymentBankAccountID: this.formData.bankAccountID,
        subscriptionExpenseGLAccountID: this.formData.accountID,
        subscriptionBillingAddressID: this.apsetting.subscriptionBillingAddressID,
        autoApproved: this.apsetting.autoApproved,
        subscriptionAutoPaymentThresholdAmount: this.apsetting
          .subscriptionAutoPaymentThresholdAmount
      }

      if (this.isDebug == true) {
        logJSON('payload=', payload)
      }

      const results = await kms.put(`/AccountsPayable/APSetting`, payload)

      if (this.isDebug == true) logJSON('results=', results)

      if (results.apSettingID > 0) {
        this.$emit('update:toggle', (this.toggle = !this.toggle))

        // auto-close modal
        $('#clickclose').click()
        notifyMessage(`Successfully saved your subscription accounts.`)
      } else {
        notifyError('There was a problem selecting your subscription accounts.')
      }
    } catch (e) {
      notifyError(e)
    }

    this.refreshing = false
  }
}
