var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isManageSubscription == false)?_c('div',[_c('PageContentLayoutOnly',{staticClass:"subtitle",attrs:{"sectionStyles":_vm.styles.pageContentSection,"role":"region","aria-label":_vm.$t('subscription.title')}},[(_vm.subscription && _vm.subscription != undefined)?_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.subscription.name)+" ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"has-text-right pb-5"},[_c('b-button',{staticClass:"is-primary",attrs:{"label":"Manage Subscription"},on:{"click":function($event){$event.preventDefault();_vm.isManageSubscription = true}}})],1),_c('div',{staticClass:"tile is-ancestor"},[_c('div',{staticClass:"tile is-vertical is-12"},[_c('div',{staticClass:"tile"},[_c('div',{staticClass:"tile is-parent is-vertical"},[_c('article',{staticClass:"tile is-child notification rounded-tile"},[_c('div',[_vm._v("Renews On")]),_c('div',{staticClass:"subtitle"}),_c('div',{staticClass:"href-overflow-wrap"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.tabClicked('receipts')}}},[_vm._v("View Receipts")])])])]),_c('div',{staticClass:"tile is-parent"},[_c('article',{staticClass:"tile is-child notification rounded-tile"},[_c('div',[_vm._v("Current Monthly Bill")]),_c('div',{staticClass:"subtitle font-bold"},[_vm._v(_vm._s(_vm._f("currency")(_vm.currentMonthlyBillAmount)))]),_c('div',{staticClass:"href-overflow-wrap"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.tabClicked('billing')}}},[_vm._v("Update Billing Details")])])])]),_c('div',{staticClass:"tile is-parent"},[_c('article',{staticClass:"tile is-child notification rounded-tile"},[_c('div',[_vm._v("Bank Account")]),(
                  _vm.apSetting &&
                    _vm.apSetting != undefined &&
                    _vm.apSetting.bankAccount &&
                    _vm.apSetting.bankAccount != undefined
                )?_c('div',{staticClass:"subtitle font-bold"},[_vm._v(" "+_vm._s(_vm.apSetting.bankAccount.name)+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"box is-12"},[_c('b-tabs',{staticClass:"block whitecolor",attrs:{"position":"is-left","type":"is-boxed"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{key:"overview",staticClass:"rounded-tile",attrs:{"value":"overview","label":"Overview"}},[(
              _vm.subscription && _vm.subscription != undefined && _vm.hoaFeatures && _vm.hoaFeatures != undefined
            )?_c('div',[_c('div',{staticClass:"p-2"},[_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.subscription.name)+" Plan - Standard Features")])]),(_vm.columns && _vm.columns != undefined)?_c('div',{staticClass:"container",staticStyle:{"display":"flex"}},_vm._l((_vm.columns),function(column,index){return _c('div',{key:index,staticClass:"col"},_vm._l((Object.keys(column)),function(item,index){return _c('div',{key:index,staticClass:"item-container"},[(
                      column[item] &&
                        column[item] != undefined &&
                        column[item].feature &&
                        column[item].feature != undefined
                    )?_c('span',[_vm._v(" "+_vm._s(column[item].feature.name)+" "),(
                        column[item].feature.subFeatures &&
                          column[item].feature.subFeatures != undefined
                      )?_c('span',_vm._l((column[item].feature.subFeatures),function(item,index){return _c('div',{key:index,staticClass:"font-normal"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e()]):_vm._e()])}),0)}),0):_vm._e(),_c('div',{staticClass:"whitecolor p-2 detail-border-middle"}),_c('div',{staticClass:"p-2"},[_c('div',{staticClass:"subtitle"},[_vm._v("Basic Plan - Optional Features Purchased")]),(_vm.addOnColumns && _vm.addOnColumns != undefined)?_c('div',{staticClass:"container"},_vm._l((_vm.addOnColumns),function(column,index){return _c('div',{key:index,staticClass:"col"},_vm._l((Object.keys(column)),function(item,index){return _c('div',{key:index,staticClass:"item-container"},[(
                        column[item] &&
                          column[item] != undefined &&
                          column[item].feature &&
                          column[item].feature != undefined
                      )?_c('span',[_vm._v(" "+_vm._s(column[item].feature.name)+" "),(
                          column[item].feature.subFeatures &&
                            column[item].feature.subFeatures != undefined
                        )?_c('span',_vm._l((column[item].feature.subFeatures),function(item,index){return _c('div',{key:index,staticClass:"font-normal"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e()]):_vm._e(),(
                        column[item].feature.monthlyCost &&
                          column[item].feature.monthlyCost != undefined
                      )?_c('div',{staticClass:"redcurrency font-normal"},[_vm._v(" "+_vm._s(_vm._f("currency")(column[item].feature.monthlyCost))+" per month ")]):_vm._e()])}),0)}),0):_vm._e()])]):_vm._e()]),_c('b-tab-item',{key:"receipts",attrs:{"value":"receipts","label":"Receipts"}},[_c('div',[_c('Receipts')],1)]),_c('b-tab-item',{key:"billing",attrs:{"value":"billing","label":"Billing Details"}},[_c('div',[_c('Billing',{attrs:{"shouldRefresh":_vm.shouldRefresh},on:{"update:shouldRefresh":function($event){_vm.shouldRefresh=$event},"update:should-refresh":function($event){_vm.shouldRefresh=$event}}})],1)])],1)],1)])],1):(_vm.isManageSubscription == true)?_c('div',[_c('ManageSubscription',{attrs:{"isManageSubscription":_vm.isManageSubscription,"subscription":_vm.subscription},on:{"update:isManageSubscription":function($event){_vm.isManageSubscription=$event},"update:is-manage-subscription":function($event){_vm.isManageSubscription=$event}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }