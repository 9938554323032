import { addGroup } from '@/services/AccountSettings/Grouping/Post'
import { groupingStore } from '@/services/AccountSettings/Grouping/store'
//
import _get from 'lodash/get'
//
export const methods = {
  open() {
    this.formData = {
      name: '',
      description: ''
    }
    this.isOpen = true
    this.loading = false
  },
  async addGroup() {
    this.loading = true
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const { successful } = await addGroup({
      json: {
        hoaID,
        name: this.formData.name,
        description: this.formData.description
      }
    })

    await groupingStore.dispatch('list', {
      hoaID
    })

    if (successful) {
      this.isOpen = false
      this.loading = false
    }
  }
}
