<template>
  <div class="pt-5" permission-chooser>
    <label class="label">Assign Access</label>

    <div class="columns pt-4 pl-3" :style="{ margin: '0 0 12px', padding: '0 !important' }">
      <div class="box column is-one-half" :style="{ margin: '0 12px 0 0 !important' }">
        <b-radio
          add-member-to-groups
          v-model="inviteKind"
          tabindex="0"
          name="invite"
          native-value="groups"
        >
          <div>Add member to groups.</div>
          <div>
            Inherit roles from user groups.
          </div>
        </b-radio>
      </div>

      <div class="box column is-one-half" :style="{ overflow: 'visible' }">
        <b-radio
          v-if="preventDemotion !== true"
          add-roles
          v-model="inviteKind"
          tabindex="0"
          name="invite"
          native-value="roles"
        >
          <div>Add individual roles.</div>
          <div>Assign individual roles to invites.</div>
        </b-radio>

        <b-tooltip
          v-if="preventDemotion === true"
          label='An "Account Owner" may not demote their own account.'
          position="is-top"
        >
          <b-radio
            add-roles
            v-model="inviteKind"
            disabled
            tabindex="0"
            name="invite"
            native-value="roles"
          >
            <div>Add individual roles.</div>
            <div>Assign individual roles to invites.</div>
          </b-radio>
        </b-tooltip>
      </div>
    </div>

    <div class="box" v-if="inviteKind === 'groups'">
      <div class="is-1 p-2" v-for="(c, index) in groupList" :key="index">
        <b-checkbox v-model="checkboxInviteGroup" :native-value="c.groupingID">
          <div class="font-bold">{{ c.name }}</div>
          <div>
            {{ c.description }}
          </div>
        </b-checkbox>
      </div>
      <p
        v-if="groupList.length === 0"
        class="has-text-grey"
        :style="{
          textAlign: 'center',
          padding: '2em'
        }"
      >
        There currently are not any groups setup.
      </p>
    </div>

    <div v-if="inviteKind == 'roles'">
      <b-field>
        <article class="box p-5">
          <b-radio
            v-model="inviteRoleKind"
            name="name"
            native-value="owner"
            @click.prevent="loadAddInviteOwner()"
            account-owner-button
          >
            <div class="font-bold">Account Owner</div>
            <div>
              Owners have full read and edit access to everything in the workspace, including
              Sources, Destinations, add-on products, and settings. Owners have full edit access to
              all Team Permissions.
            </div>
          </b-radio>
        </article>
      </b-field>

      <b-field>
        <article class="box p-5">
          <b-radio
            v-model="inviteRoleKind"
            name="name"
            native-value="member"
            @click.prevent="loadAddInviteMemberRoles()"
            account-member-button
          >
            <div class="font-bold">Account Member</div>
            <div class="is-8">
              By default, Workspace Members can view the workspace without access to any
              sub-resources. Additional permissions can be customized below. Workspace Members will
              also adopt any permissions of a User Group they belong to.
            </div>
          </b-radio>
        </article>
      </b-field>

      <div v-if="inviteRoleKind === 'member'">
        <b-field>
          <article class="box p-2">
            <div class="font-bold">Customize Account Member Permissions</div>
          </article>
        </b-field>

        <div
          member-role
          v-for="(c, index) in rolesList"
          :key="index"
          class="card is-1 p-5"
          :style="{ margin: '4px 0' }"
        >
          <b-field>
            <b-checkbox v-model="checkboxInviteRole" :native-value="c.roleID">
              <div class="font-bold">{{ c.name }}</div>
              <div>
                {{ c.description }}
              </div>
            </b-checkbox>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
    import PermissionsChooser from './PermissionsChooser'

    <PermissionsChooser
      ref="permissionsChooser"
    />

    const {
      inviteKind,
      inviteRoleKind,
      checkboxInviteGroup,
      checkboxInviteRole
    } = this.$refs.permissionsChooser.getData ();
  */

import { data } from './keys/data'
import { methods } from './keys/methods'
import { props } from './keys/props'

export default {
  data,
  methods,
  props,
  mounted() {
    this.init()
  }
}
</script>
